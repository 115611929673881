import { useState, useEffect } from 'react';
import { Plus, Pencil, Trash2, Save, Lock, Unlock, Rocket } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import { format } from 'date-fns';
import type { Course } from '@/data/types';

interface CourseManagerProps {
  selectedSubject: string | null;
  onCourseSelect: (course: string) => void;
}

interface CourseWithStats extends Course {
  moduleCount: number;
  sectionCount: number;
}

interface EditingCourseData {
  name: string;
  isLocked: boolean;
  releaseDate: string;
}

export function CourseManager({ selectedSubject, onCourseSelect }: CourseManagerProps) {
  const [courses, setCourses] = useState<CourseWithStats[]>([]);
  const [editingCourse, setEditingCourse] = useState<string | null>(null);
  const [editingData, setEditingData] = useState<EditingCourseData | null>(null);
  const [newCourse, setNewCourse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    if (selectedSubject) {
      loadCourses();
    }
  }, [selectedSubject]);

  const loadCourses = async () => {
    if (!selectedSubject) return;

    try {
      setIsLoading(true);

      // First get the subject ID
      const { data: subject, error: subjectError } = await supabase
        .from('subjects')
        .select('id')
        .eq('name', selectedSubject)
        .single();

      if (subjectError) throw subjectError;
      if (!subject) throw new Error('Subject not found');

      // Then get courses for this subject
      const { data: courses, error: coursesError } = await supabase
        .from('courses')
        .select('*')
        .eq('subject_id', subject.id)
        .order('name');

      if (coursesError) throw coursesError;

      // Get module counts for each course
      const coursesWithStats = await Promise.all((courses || []).map(async (course) => {
        const { data: modules, error: modulesError } = await supabase
          .from('modules')
          .select('content')
          .eq('course_id', course.id);

        if (modulesError) throw modulesError;

        const moduleCount = modules?.length || 0;
        const sectionCount = modules?.reduce((total, module) => total + (module.content?.length || 0), 0) || 0;

        return {
          ...course,
          moduleCount,
          sectionCount,
        };
      }));

      setCourses(coursesWithStats);
    } catch (error) {
      console.error('Error loading courses:', error);
      toast({
        title: 'Error',
        description: 'Failed to load courses',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCourse = async () => {
    if (!newCourse.trim() || !selectedSubject) return;

    try {
      setIsLoading(true);

      // First get the subject ID
      const { data: subject, error: subjectError } = await supabase
        .from('subjects')
        .select('id')
        .eq('name', selectedSubject)
        .single();

      if (subjectError) throw subjectError;
      if (!subject) throw new Error('Subject not found');

      // Create the new course
      const { data: newCourseData, error: courseError } = await supabase
        .from('courses')
        .insert([
          {
            name: newCourse,
            subject_id: subject.id,
            is_locked: false,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          }
        ])
        .select()
        .single();

      if (courseError) throw courseError;

      toast({
        title: 'Course added',
        description: `${newCourse} has been added to ${selectedSubject}.`,
      });

      setNewCourse('');
      await loadCourses();
    } catch (error) {
      console.error('Error adding course:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to add course',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateCourse = async (course: Course) => {
    if (!editingData || !selectedSubject) return;

    try {
      setIsLoading(true);

      const updateData: any = {
        name: editingData.name,
        is_locked: editingData.isLocked,
        updated_at: new Date().toISOString(),
      };

      if (editingData.isLocked && editingData.releaseDate) {
        updateData.release_date = new Date(editingData.releaseDate).toISOString();
      } else {
        updateData.release_date = null;
      }

      const { error } = await supabase
        .from('courses')
        .update(updateData)
        .eq('id', course.id);

      if (error) throw error;

      toast({
        title: 'Course updated',
        description: `${course.name} has been updated successfully.`,
      });

      setEditingCourse(null);
      setEditingData(null);
      loadCourses();
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to update course',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCourse = async (course: Course) => {
    if (!selectedSubject) return;

    try {
      setIsLoading(true);

      // First delete all modules associated with this course
      const { error: modulesError } = await supabase
        .from('modules')
        .delete()
        .eq('course_id', course.id);

      if (modulesError) throw modulesError;

      // Then delete the course
      const { error: courseError } = await supabase
        .from('courses')
        .delete()
        .eq('id', course.id);

      if (courseError) throw courseError;

      toast({
        title: 'Course deleted',
        description: `${course.name} has been deleted from ${selectedSubject}.`,
      });

      loadCourses();
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to delete course',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const startEditing = (course: Course) => {
    setEditingCourse(course.name);
    setEditingData({
      name: course.name,
      isLocked: course.is_locked || false,
      releaseDate: course.release_date ? format(new Date(course.release_date), "yyyy-MM-dd'T'HH:mm") : '',
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex gap-4">
        <Input
          value={newCourse}
          onChange={(e) => setNewCourse(e.target.value)}
          placeholder="New course name..."
          className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500 rounded-xl"
        />
        <Button
          onClick={handleAddCourse}
          disabled={isLoading || !newCourse.trim()}
          className="bg-white hover:bg-zinc-100 text-zinc-900 rounded-xl px-6"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Course
        </Button>
      </div>

      <div className="space-y-4">
        {courses.map((course) => (
          <div
            key={course.id}
            className="flex flex-col p-6 bg-zinc-800/50 rounded-xl border border-zinc-700/50 hover:border-zinc-600/50 transition-colors cursor-pointer group"
            onClick={() => !editingCourse && onCourseSelect(course.name)}
          >
            {editingCourse === course.name ? (
              <div className="space-y-4" onClick={(e) => e.stopPropagation()}>
                <Input
                  value={editingData?.name}
                  onChange={(e) => setEditingData({ ...editingData!, name: e.target.value })}
                  className="bg-zinc-700 border-zinc-600 text-white rounded-xl w-full"
                  placeholder="Course name"
                  autoFocus
                />

                <div className="flex items-center gap-4 p-4 bg-zinc-900/50 rounded-xl border border-zinc-700">
                  <div className="flex items-center gap-2 flex-1">
                    <div className="p-2 rounded-lg bg-zinc-800">
                      {editingData?.isLocked ? (
                        <Lock className="w-5 h-5 text-blue-400" />
                      ) : (
                        <Unlock className="w-5 h-5 text-zinc-400" />
                      )}
                    </div>
                    <div>
                      <Label className="text-white font-medium">Lock Course</Label>
                      <p className="text-sm text-zinc-400">
                        {editingData?.isLocked ? 'Course is locked' : 'Course is unlocked'}
                      </p>
                    </div>
                  </div>
                  <Switch
                    checked={editingData?.isLocked}
                    onCheckedChange={(checked) => 
                      setEditingData({ ...editingData!, isLocked: checked })
                    }
                    className="data-[state=checked]:bg-blue-600"
                  />
                </div>

                {editingData?.isLocked && (
                  <div className="space-y-2">
                    <Label htmlFor="releaseDate" className="text-white">Release Date</Label>
                    <Input
                      id="releaseDate"
                      type="datetime-local"
                      value={editingData.releaseDate}
                      onChange={(e) => setEditingData({ ...editingData, releaseDate: e.target.value })}
                      className="bg-zinc-700 border-zinc-600 text-white rounded-xl w-full"
                    />
                  </div>
                )}

                <div className="flex gap-2 justify-end">
                  <Button
                    onClick={() => {
                      setEditingCourse(null);
                      setEditingData(null);
                    }}
                    size="sm"
                    variant="outline"
                    className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleUpdateCourse(course)}
                    disabled={isLoading}
                    size="sm"
                    className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
                  >
                    <Save className="w-4 h-4 mr-2" />
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <h3 
                      className="text-lg font-medium text-white hover:text-blue-400 transition-colors cursor-pointer"
                      onClick={() => onCourseSelect(course.name)}
                    >
                      {course.name}
                    </h3>
                    {course.is_locked && (
                      <div className="flex items-center gap-1 px-2 py-1 rounded-lg bg-zinc-900/50 border border-zinc-700">
                        {course.release_date ? (
                          <>
                            <Rocket className="w-4 h-4 text-blue-400" />
                            <span className="text-sm text-blue-400">Scheduled</span>
                          </>
                        ) : (
                          <>
                            <Lock className="w-4 h-4 text-zinc-400" />
                            <span className="text-sm text-zinc-400">Locked</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="flex gap-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        startEditing(course);
                      }}
                      disabled={isLoading}
                      size="sm"
                      className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
                    >
                      <Pencil className="w-4 h-4" />
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteCourse(course);
                      }}
                      disabled={isLoading}
                      size="sm"
                      className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
                <div className="flex items-center gap-2 text-sm text-zinc-400">
                  <span>{course.moduleCount} Modules</span>
                  <span className="text-zinc-600">|</span>
                  <span>{course.sectionCount} Lessons</span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}