import { useState, useEffect, useRef } from 'react';
import { BookOpen, MessageSquare, LifeBuoy, Settings, UserCircle2, Menu, X, LogOut, FileText, Search, Brain, Shield, Award } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { SearchDialog } from './SearchDialog';
import { Skeleton } from './ui/skeleton';
import { Logo } from './Logo';
import { RankBadge } from './RankBadge';
import { useRank } from '@/hooks/useRank';
import { useRankPermissions } from '@/hooks/useRankPermissions';
import { useToast } from '@/hooks/use-toast';
import { Button } from './ui/button';
import { forceLogout } from '@/lib/auth';

interface SidebarProps {
  onNavigateHome: () => void;
  onSettingsClick: () => void;
  onRequestCourseClick: () => void;
  onSupportClick: () => void;
  onAITutorClick: () => void;
  onAdminClick: () => void;
  onCertificatesClick: () => void;
  onRanksClick: () => void;
  onCourseSelect: (subject: string, course: string) => void;
  onModuleSelect: (subject: string, course: string, moduleIndex: number, audioIndex: number) => void;
  isAdmin?: boolean;
}

export function Sidebar({ 
  onNavigateHome, 
  onSettingsClick, 
  onRequestCourseClick, 
  onSupportClick,
  onAITutorClick,
  onAdminClick,
  onCertificatesClick,
  onRanksClick,
  onCourseSelect,
  onModuleSelect,
  isAdmin = false,
}: SidebarProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const { profile, isLoading: authLoading } = useAuth();
  const { rank, isLoading: isRankLoading } = useRank();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const { canRequestCourse } = useRankPermissions();
  const { toast } = useToast();

  const toggleSidebar = () => setIsOpen(!isOpen);

  const handleSignOut = async () => {
    try {
      setIsSigningOut(true);
      await forceLogout();
    } catch (error) {
      console.error('Error during logout:', error);
      // Force reload as fallback
      window.location.reload();
    }
  };

  const handleRequestCourseClick = () => {
    if (!canRequestCourse()) {
      toast({
        title: 'Rank Required',
        description: 'Rank up to Explorer to unlock course requests!',
        action: (
          <Button 
            onClick={() => {
              onRanksClick();
              setIsOpen(false);
            }} 
            variant="outline" 
            className="bg-white text-zinc-900 hover:bg-zinc-100"
          >
            View Ranks
          </Button>
        ),
      });
      return;
    }
    onRequestCourseClick();
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const renderProfileSection = () => {
    if (authLoading || isRankLoading) {
      return (
        <div className="space-y-2 w-full">
          <Skeleton className="h-4 w-3/4 mx-auto bg-zinc-800" />
          <Skeleton className="h-3 w-1/2 mx-auto bg-zinc-800" />
        </div>
      );
    }

    if (!profile) {
      return (
        <div className="text-center">
          <p className="text-zinc-400 text-sm">Session expired</p>
        </div>
      );
    }

    return (
      <>
        <p className="text-white font-medium text-center mb-1">
          {profile.full_name}
        </p>
        <p className="text-zinc-400 text-sm mb-2">
          {profile.email}
        </p>
        <div className="flex justify-center">
          <RankBadge 
            rank={rank} 
            onClick={() => {
              onRanksClick();
              setIsOpen(false);
            }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="lg:hidden fixed top-0 left-0 right-0 h-14 bg-zinc-950/80 backdrop-blur-sm z-40 flex items-center justify-between px-4">
        <button 
          onClick={toggleSidebar}
          className="p-2 text-white hover:bg-zinc-800/50 rounded-lg"
        >
          {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
        </button>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <Logo size="sm" className="h-6" />
        </div>
      </div>

      {isOpen && (
        <div 
          className="lg:hidden fixed inset-0 bg-black/50 z-30"
          onClick={() => setIsOpen(false)}
        />
      )}

      <div
        ref={sidebarRef}
        className={`
          fixed lg:sticky top-0 left-0 z-40
          w-64 h-screen bg-gradient-to-b from-[#2D2D2D] to-[#080809] p-6 flex-shrink-0
          transform transition-transform duration-200 ease-in-out
          lg:transform-none overflow-y-auto
          ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
        `}
      >
        <div className="flex justify-center mb-8 mt-12 lg:mt-0">
          <Logo className="w-40" size="sm" />
        </div>
        <div className="flex flex-col items-center mb-6">
          <div className="w-20 h-20 bg-[#09090B] rounded-full mb-4 flex items-center justify-center">
            <UserCircle2 className="w-12 h-12 text-white" />
          </div>
          {renderProfileSection()}
        </div>
        <nav className="space-y-2">
          <button
            onClick={() => {
              onNavigateHome();
              setIsOpen(false);
            }}
            className="flex items-center gap-2 text-zinc-400 hover:text-white transition-colors w-full px-4 py-2 rounded-xl hover:bg-zinc-800/50 text-left"
          >
            <BookOpen className="w-5 h-5" />
            Programs
          </button>
          <button 
            onClick={() => setShowSearch(true)}
            className="flex items-center gap-2 text-zinc-400 hover:text-white transition-colors w-full px-4 py-2 rounded-xl hover:bg-zinc-800/50 text-left"
          >
            <Search className="w-5 h-5" />
            Search
          </button>
          <button 
            onClick={() => {
              onAITutorClick();
              setIsOpen(false);
            }}
            className="flex items-center gap-2 text-zinc-400 hover:text-white transition-colors w-full px-4 py-2 rounded-xl hover:bg-zinc-800/50 text-left"
          >
            <Brain className="w-5 h-5" />
            AI Tutor
          </button>
          <button 
            onClick={() => {
              onCertificatesClick();
              setIsOpen(false);
            }}
            className="flex items-center gap-2 text-zinc-400 hover:text-white transition-colors w-full px-4 py-2 rounded-xl hover:bg-zinc-800/50 text-left"
          >
            <Award className="w-5 h-5" />
            Certificates
          </button>
          <button 
            onClick={() => {
              onRanksClick();
              setIsOpen(false);
            }}
            className="flex items-center gap-2 text-zinc-400 hover:text-white transition-colors w-full px-4 py-2 rounded-xl hover:bg-zinc-800/50 text-left"
          >
            <Shield className="w-5 h-5" />
            Unlock Rewards
          </button>
          <button 
            onClick={handleRequestCourseClick}
            className={`flex items-center gap-2 w-full px-4 py-2 rounded-xl text-left transition-colors ${
              canRequestCourse()
                ? 'text-zinc-400 hover:text-white hover:bg-zinc-800/50'
                : 'text-zinc-600 cursor-not-allowed'
            }`}
          >
            <FileText className="w-5 h-5" />
            Request A Course
          </button>
          <button 
            onClick={() => {
              onSupportClick();
              setIsOpen(false);
            }}
            className="flex items-center gap-2 text-zinc-400 hover:text-white transition-colors w-full px-4 py-2 rounded-xl hover:bg-zinc-800/50 text-left"
          >
            <LifeBuoy className="w-5 h-5" />
            Support
          </button>
          <button 
            onClick={() => {
              onSettingsClick();
              setIsOpen(false);
            }}
            className="flex items-center gap-2 text-zinc-400 hover:text-white transition-colors w-full px-4 py-2 rounded-xl hover:bg-zinc-800/50 text-left"
          >
            <Settings className="w-5 h-5" />
            Settings
          </button>
          {isAdmin && (
            <button 
              onClick={() => {
                onAdminClick();
                setIsOpen(false);
              }}
              className="flex items-center gap-2 text-purple-400 hover:text-purple-300 transition-colors w-full px-4 py-2 rounded-xl hover:bg-purple-950/30 text-left"
            >
              <Shield className="w-5 h-5" />
              Admin Panel
            </button>
          )}
          <button 
            onClick={handleSignOut}
            disabled={isSigningOut}
            className="flex items-center gap-2 text-red-400 hover:text-red-300 transition-colors w-full px-4 py-2 rounded-xl hover:bg-red-950/30 text-left mt-4 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <LogOut className="w-5 h-5" />
            {isSigningOut ? 'Signing out...' : 'Sign Out'}
          </button>
        </nav>
      </div>

      <SearchDialog
        open={showSearch}
        onOpenChange={setShowSearch}
        onCourseSelect={(subject, course) => {
          onCourseSelect(subject, course);
          setIsOpen(false);
        }}
        onModuleSelect={(subject, course, moduleIndex, audioIndex) => {
          onModuleSelect(subject, course, moduleIndex, audioIndex);
          setIsOpen(false);
        }}
      />
    </>
  );
}