import { ArrowLeft, Lock, Unlock } from 'lucide-react';
import { useRank } from '@/hooks/useRank';

interface RanksPageProps {
  onBack: () => void;
}

const standardRanks = [
  {
    name: 'Listener',
    description: 'Start your learning journey with access to core content.',
    weeksRequired: 0,
    unlocks: [
      'Access to Core Programs.',
      'Access to Quizzes.',
      'Basic AI Tutor Credits.'
    ]
  },
  {
    name: 'Learner',
    description: 'Expand your knowledge with additional programs and features.',
    weeksRequired: 2,
    unlocks: [
      'Access to Lifestyle Programs.',
      'Double AI Tutor Credits.'
    ]
  },
  {
    name: 'Explorer',
    description: 'Discover more content and shape the platform.',
    weeksRequired: 4,
    unlocks: [
      'Access to Entertainment Programs.',
      'Request a New Course.'
    ]
  },
  {
    name: 'Master',
    description: 'Join our elite community of dedicated learners.',
    weeksRequired: 8,
    unlocks: [
      "Invitation to our Master's Community.",
      'Early Access to New Features.'
    ]
  }
];

const scholarshipRank = {
  name: 'Scholarship',
  description: 'Full access to all platform features and content.',
  weeksRequired: 0,
  unlocks: [
    'Access to All Programs and Categories',
    'Maximum AI Tutor Credits',
    'Course Request Privileges',
    'Early Access Features'
  ]
};

const rankColors = {
  Listener: 'text-blue-400',
  Learner: 'text-green-400',
  Explorer: 'text-purple-400',
  Master: 'text-amber-400',
  Scholarship: 'text-pink-400',
};

const rankBorderColors = {
  Listener: 'border-blue-500',
  Learner: 'border-green-500',
  Explorer: 'border-purple-500',
  Master: 'border-amber-500',
  Scholarship: 'border-pink-500',
};

const rankBadgeColors = {
  Listener: 'from-blue-400/80 via-blue-500/70 to-blue-600/80',
  Learner: 'from-green-400/80 via-green-500/70 to-green-600/80',
  Explorer: 'from-purple-400/80 via-purple-500/70 to-purple-600/80',
  Master: 'from-amber-400/80 via-amber-500/70 to-amber-600/80',
  Scholarship: 'from-pink-400/80 via-pink-500/70 to-pink-600/80',
};

const rankBgColors = {
  Listener: 'bg-blue-500',
  Learner: 'bg-green-500',
  Explorer: 'bg-purple-500',
  Master: 'bg-amber-500',
  Scholarship: 'bg-pink-500',
};

const rankBulletColors = {
  Listener: 'text-blue-400',
  Learner: 'text-green-400',
  Explorer: 'text-purple-400',
  Master: 'text-amber-400',
  Scholarship: 'text-pink-400',
};

export function RanksPage({ onBack }: RanksPageProps) {
  const { rank: currentRank, count, nextRank, requirementForNext } = useRank();

  const weeksUntilRankUp = nextRank ? requirementForNext! - count : 0;

  // Calculate overall progress (0-8 weeks)
  const calculateOverallProgress = () => {
    if (currentRank === 'Scholarship') return 100;
    return Math.min(100, (count / 8) * 100);
  };

  return (
    <div className="max-w-6xl mx-auto py-8 px-4">
      <div className="flex items-center justify-between mb-8">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-zinc-200 hover:text-white transition-colors text-base"
        >
          <ArrowLeft className="w-5 h-5" />
          Back to Dashboard
        </button>

        {nextRank && currentRank !== 'Scholarship' && (
          <div className="text-zinc-200 font-medium">
            Only {weeksUntilRankUp} week{weeksUntilRankUp !== 1 ? 's' : ''} until rank up
          </div>
        )}
      </div>

      <div className="bg-zinc-900 rounded-xl p-8 border border-zinc-800">
        <h1 className="text-3xl font-bold text-white mb-2">Audium Ranking System</h1>
        <p className="text-zinc-400 mb-8">Track your progress and unlock new features.</p>

        {currentRank !== 'Scholarship' && (
          <div className="mb-36">
            <div className="relative mb-24">
              <div className="h-2 bg-zinc-800 rounded-full">
                <div
                  className="absolute h-2 bg-blue-500 rounded-full transition-all duration-500"
                  style={{ width: `${calculateOverallProgress()}%` }}
                />
              </div>
              
              {/* Rank markers */}
              <div className="absolute top-6 left-0 right-0 flex justify-between">
                <div className="flex flex-col items-center">
                  <div className={`w-1 h-3 ${count >= 0 ? 'bg-blue-500' : 'bg-zinc-700'}`} />
                  <div className={`p-2 rounded-full bg-zinc-800 mt-2 ${count >= 0 ? 'text-blue-400' : 'text-zinc-600'}`}>
                    <Unlock className="w-4 h-4" />
                  </div>
                  <span className="text-sm text-zinc-400 mt-2">0 Weeks</span>
                  <span className={`text-sm ${rankColors.Listener} mt-1`}>Listener</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className={`w-1 h-3 ${count >= 2 ? 'bg-blue-500' : 'bg-zinc-700'}`} />
                  <div className={`p-2 rounded-full bg-zinc-800 mt-2 ${count >= 2 ? 'text-blue-400' : 'text-zinc-600'}`}>
                    {count >= 2 ? <Unlock className="w-4 h-4" /> : <Lock className="w-4 h-4" />}
                  </div>
                  <span className="text-sm text-zinc-400 mt-2">2 Weeks</span>
                  <span className={`text-sm ${rankColors.Learner} mt-1`}>Learner</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className={`w-1 h-3 ${count >= 4 ? 'bg-blue-500' : 'bg-zinc-700'}`} />
                  <div className={`p-2 rounded-full bg-zinc-800 mt-2 ${count >= 4 ? 'text-blue-400' : 'text-zinc-600'}`}>
                    {count >= 4 ? <Unlock className="w-4 h-4" /> : <Lock className="w-4 h-4" />}
                  </div>
                  <span className="text-sm text-zinc-400 mt-2">4 Weeks</span>
                  <span className={`text-sm ${rankColors.Explorer} mt-1`}>Explorer</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className={`w-1 h-3 ${count >= 8 ? 'bg-blue-500' : 'bg-zinc-700'}`} />
                  <div className={`p-2 rounded-full bg-zinc-800 mt-2 ${count >= 8 ? 'text-blue-400' : 'text-zinc-600'}`}>
                    {count >= 8 ? <Unlock className="w-4 h-4" /> : <Lock className="w-4 h-4" />}
                  </div>
                  <span className="text-sm text-zinc-400 mt-2">8 Weeks</span>
                  <span className={`text-sm ${rankColors.Master} mt-1`}>Master</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {currentRank === 'Scholarship' ? (
            <div
              className={`
                relative p-6 rounded-xl border-2
                ${rankBorderColors.Scholarship}
                bg-zinc-800/50
              `}
            >
              <h3 className={`text-xl font-semibold mb-2 ${rankColors.Scholarship}`}>
                {scholarshipRank.name}
              </h3>
              <p className="text-zinc-300 mb-4">{scholarshipRank.description}</p>

              <div className="space-y-2 mb-4">
                {scholarshipRank.unlocks.map((unlock, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <span className={`text-lg ${rankBulletColors.Scholarship}`}>•</span>
                    <span className="text-zinc-300">{unlock}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            standardRanks.map((r) => (
              <div
                key={r.name}
                className={`
                  relative p-6 rounded-xl border-2
                  ${r.name === currentRank ? rankBorderColors[r.name as keyof typeof rankBorderColors] : 'border-zinc-800'}
                  bg-zinc-800/50
                `}
              >
                <h3 className={`text-xl font-semibold mb-2 ${rankColors[r.name as keyof typeof rankColors]}`}>
                  {r.name}
                </h3>
                <p className="text-zinc-300 mb-4">{r.description}</p>

                <div className="space-y-2 mb-4">
                  {r.unlocks.map((unlock, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <span className={`text-lg ${rankBulletColors[r.name as keyof typeof rankBulletColors]}`}>•</span>
                      <span className="text-zinc-300">{unlock}</span>
                    </div>
                  ))}
                </div>

                <div className="text-sm text-zinc-400">
                  {r.weeksRequired === 0 
                    ? "Available for all new members." 
                    : `Remain a member for ${r.weeksRequired} weeks.`}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}