import { Pause, Play, SkipForward } from 'lucide-react';

interface AudioControlsProps {
  isPlaying: boolean;
  currentTime: number;
  duration: number;
  onPlayPause: () => void;
  onSeek: (time: number) => void;
  onNext: () => void;
  title: string;
  hasNextTrack: boolean;
}

export function AudioControls({
  isPlaying,
  currentTime,
  duration,
  onPlayPause,
  onSeek,
  onNext,
  title,
  hasNextTrack,
}: AudioControlsProps) {
  const formatTime = (timeInSeconds: number) => {
    if (!timeInSeconds || isNaN(timeInSeconds)) return '0:00';
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-zinc-900/95 border-t border-zinc-800 p-4 z-50">
      <div className="max-w-[1400px] mx-auto flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <button
            onClick={onPlayPause}
            className="p-2 rounded-full hover:bg-zinc-800 transition-colors"
          >
            {isPlaying ? (
              <Pause className="w-6 h-6 text-white" />
            ) : (
              <Play className="w-6 h-6 text-white" />
            )}
          </button>

          <p className="text-sm text-white font-medium truncate flex-1 mx-4 text-center">
            {title}
          </p>

          <button
            disabled={!hasNextTrack}
            className={`p-2 rounded-full transition-colors ${
              hasNextTrack 
                ? 'hover:bg-zinc-800 text-white' 
                : 'text-zinc-600 cursor-not-allowed'
            }`}
            onClick={onNext}
          >
            <SkipForward className="w-6 h-6" />
          </button>
        </div>

        <div className="flex items-center gap-2">
          <span className="text-xs text-zinc-400 whitespace-nowrap min-w-[40px]">
            {formatTime(currentTime)}
          </span>
          <input
            type="range"
            min={0}
            max={duration || 100}
            value={currentTime || 0}
            onChange={(e) => onSeek(Number(e.target.value))}
            className="flex-1 h-1 bg-zinc-700 rounded-full appearance-none cursor-pointer
              [&::-webkit-slider-thumb]:appearance-none 
              [&::-webkit-slider-thumb]:w-3 
              [&::-webkit-slider-thumb]:h-3 
              [&::-webkit-slider-thumb]:rounded-full 
              [&::-webkit-slider-thumb]:bg-white
              [&::-webkit-slider-thumb]:hover:w-4
              [&::-webkit-slider-thumb]:hover:h-4
              [&::-webkit-slider-thumb]:transition-all"
            style={{
              background: `linear-gradient(to right, #3b82f6 ${(currentTime / duration) * 100}%, rgb(63 63 70) ${(currentTime / duration) * 100}%)`,
            }}
          />
          <span className="text-xs text-zinc-400 whitespace-nowrap min-w-[40px]">
            {formatTime(duration)}
          </span>
        </div>
      </div>
    </div>
  );
}