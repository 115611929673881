import { useState, useEffect } from 'react';
import { ArrowLeft } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { useToast } from '@/hooks/use-toast';
import { Button } from './ui/button';

interface Question {
  id: string;
  question: string;
  correct_answer: string;
  wrong_answer_1: string;
  wrong_answer_2: string;
  wrong_answer_3: string;
}

interface QuizPageProps {
  onBack: () => void;
  subject: string;
  course: string;
}

interface ShuffledQuestion extends Question {
  shuffledAnswers: string[];
}

export function QuizPage({ onBack, subject, course }: QuizPageProps) {
  const [questions, setQuestions] = useState<ShuffledQuestion[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [previousScore, setPreviousScore] = useState<number | null>(null);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const { user } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    loadQuiz();
  }, [subject, course]);

  const shuffleAnswers = (question: Question): ShuffledQuestion => {
    const answers = [
      question.correct_answer,
      question.wrong_answer_1,
      question.wrong_answer_2,
      question.wrong_answer_3,
    ];
    
    // Fisher-Yates shuffle
    for (let i = answers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [answers[i], answers[j]] = [answers[j], answers[i]];
    }
    
    return {
      ...question,
      shuffledAnswers: answers,
    };
  };

  const loadQuiz = async () => {
    try {
      setIsLoading(true);

      // Get the subject ID
      const { data: subjectData } = await supabase
        .from('subjects')
        .select('id')
        .eq('name', subject)
        .single();

      if (!subjectData) throw new Error('Subject not found');

      // Get the course ID
      const { data: courseData } = await supabase
        .from('courses')
        .select('id')
        .eq('name', course)
        .eq('subject_id', subjectData.id)
        .single();

      if (!courseData) throw new Error('Course not found');

      // Get the quiz for this course
      const { data: quizData, error: quizError } = await supabase
        .from('quizzes')
        .select('*')
        .eq('course_id', courseData.id)
        .single();

      if (quizError) throw quizError;

      if (!quizData) {
        throw new Error('Quiz not found');
      }

      // Get the questions for this quiz
      const { data: questionData, error: questionError } = await supabase
        .from('quiz_questions')
        .select('*')
        .eq('quiz_id', quizData.id)
        .order('display_order');

      if (questionError) throw questionError;

      // Get user's previous score if any
      const { data: resultData, error: resultError } = await supabase
        .from('quiz_results')
        .select('score')
        .eq('quiz_id', quizData.id)
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (resultError && resultError.code !== 'PGRST116') {
        throw resultError;
      }

      // Shuffle answers for each question once when loading
      const shuffledQuestions = questionData?.map(shuffleAnswers) || [];
      setQuestions(shuffledQuestions);
      setTotalQuestions(shuffledQuestions?.length || 0);
      setPreviousScore(resultData?.score || null);
    } catch (error) {
      console.error('Error loading quiz:', error);
      toast({
        title: 'Error',
        description: 'Failed to load quiz',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnswer = async (answer: string) => {
    const currentQuestion = questions[currentQuestionIndex];
    const isCorrect = answer === currentQuestion.correct_answer;
    
    setSelectedAnswer(answer);
    setIsAnswerCorrect(isCorrect);
    setShowFeedback(true);

    if (isCorrect) {
      setScore(score + 1);
    }

    // Show feedback for 1 second before moving to next question
    setTimeout(() => {
      setShowFeedback(false);
      setSelectedAnswer(null);
      
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        handleQuizComplete(score + (isCorrect ? 1 : 0));
      }
    }, 1000);
  };

  const handleQuizComplete = async (finalScore: number) => {
    try {
      // Get subject ID
      const { data: subjectData } = await supabase
        .from('subjects')
        .select('id')
        .eq('name', subject)
        .single();

      if (!subjectData) throw new Error('Subject not found');

      // Get course ID
      const { data: courseData } = await supabase
        .from('courses')
        .select('id')
        .eq('name', course)
        .eq('subject_id', subjectData.id)
        .single();

      if (!courseData) throw new Error('Course not found');

      // Get quiz ID
      const { data: quizData } = await supabase
        .from('quizzes')
        .select('id')
        .eq('course_id', courseData.id)
        .single();

      if (!quizData) throw new Error('Quiz not found');

      // Save result
      const { error: resultError } = await supabase
        .from('quiz_results')
        .insert([{
          user_id: user?.id,
          quiz_id: quizData.id,
          score: finalScore,
          total_questions: questions.length,
        }]);

      if (resultError) throw resultError;

      setShowResults(true);
    } catch (error) {
      console.error('Error saving quiz result:', error);
      toast({
        title: 'Error',
        description: 'Failed to save quiz result',
        variant: 'destructive',
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (showResults) {
    return (
      <div className="max-w-2xl mx-auto py-8 px-4">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-zinc-200 hover:text-white transition-colors mb-8 text-base"
        >
          <ArrowLeft className="w-5 h-5" />
          Back to Course
        </button>

        <div className="bg-zinc-900 rounded-xl p-8 border border-zinc-800 text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Quiz Complete!</h2>
          <p className="text-xl text-zinc-300 mb-8">
            You scored {score} out of {questions.length}
          </p>
          {previousScore !== null && previousScore < score && (
            <p className="text-green-400 mb-8">
              New high score! Previous best: {previousScore}
            </p>
          )}
          <Button
            onClick={onBack}
            className="bg-blue-600 hover:bg-blue-700 text-white"
          >
            Return to Course
          </Button>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="max-w-2xl mx-auto py-8 px-4">
      <button
        onClick={onBack}
        className="flex items-center gap-2 text-zinc-200 hover:text-white transition-colors mb-8 text-base"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Course
      </button>

      <div className="bg-zinc-900 rounded-xl p-8 border border-zinc-800">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-xl font-bold text-white">
            Question {currentQuestionIndex + 1} of {questions.length}
          </h2>
          <span className="text-zinc-400">Score: {score}</span>
        </div>

        <p className="text-lg text-white mb-8">{currentQuestion?.question}</p>

        <div className="space-y-4">
          {currentQuestion?.shuffledAnswers.map((answer, index) => {
            let buttonClass = "w-full p-4 rounded-xl text-left transition-all duration-200 ";
            
            if (showFeedback && selectedAnswer === answer) {
              if (answer === currentQuestion.correct_answer) {
                buttonClass += "bg-green-500/20 border-2 border-green-500 text-white";
              } else {
                buttonClass += "bg-red-500/20 border-2 border-red-500 text-white";
              }
            } else if (showFeedback && answer === currentQuestion.correct_answer) {
              buttonClass += "bg-green-500/20 border-2 border-green-500 text-white";
            } else {
              buttonClass += "bg-zinc-800 hover:bg-zinc-700 text-zinc-300 hover:text-white border border-zinc-700";
            }

            return (
              <button
                key={index}
                onClick={() => !showFeedback && handleAnswer(answer)}
                disabled={showFeedback}
                className={buttonClass}
              >
                {answer}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}