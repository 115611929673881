import type { Message } from '@/types/chat';
import { supabase } from './supabase';

const API_KEY = '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';
const API_URL = 'https://api.openai.com/v1/chat/completions';
const WEEKLY_TOKEN_LIMIT = 9000;

function getSystemPrompt(topic: string, courseName: string) {
  return `You are an AI tutor helping students learn about "${courseName}". The student wants to discuss "${topic}".

Follow these rules:
1. Be conversational and friendly
2. Keep responses focused on the course subject matter
3. If the student asks about something slightly outside the specific topic but still related to the course, you can discuss it
4. If they stray too far from the course subject, gently guide them back
5. If they want to be tested, ask one question at a time and provide feedback on their answers
6. Keep responses concise and focused
7. Maintain a professional but encouraging tone
8. Format your responses with proper spacing between paragraphs
9. Use HTML for formatting:
   - Use <b>text</b> for emphasis
   - Use bullet points where appropriate
   - Add line breaks between sections
   - Ensure text is easily readable

Start by asking how you can help them learn about the topic and mention you can quiz them if they'd like.`;
}

export async function getTokenUsage(userId: string): Promise<number> {
  try {
    const startOfWeek = new Date();
    startOfWeek.setHours(0, 0, 0, 0);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

    const { data: usageData, error } = await supabase
      .from('token_usage')
      .select('tokens_used')
      .eq('user_id', userId)
      .gte('created_at', startOfWeek.toISOString())
      .single();

    if (error && error.code !== 'PGRST116') throw error;
    return usageData?.tokens_used || 0;
  } catch (error) {
    console.error('Error getting token usage:', error);
    return 0;
  }
}

async function checkTokenLimit(userId: string): Promise<boolean> {
  try {
    const currentUsage = await getTokenUsage(userId);
    return currentUsage < WEEKLY_TOKEN_LIMIT;
  } catch (error) {
    console.error('Error checking token limit:', error);
    return false;
  }
}

async function updateTokenUsage(userId: string, tokens: number) {
  try {
    const startOfWeek = new Date();
    startOfWeek.setHours(0, 0, 0, 0);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

    // Get existing usage for this week
    const { data: existingUsage } = await supabase
      .from('token_usage')
      .select('*')
      .eq('user_id', userId)
      .gte('created_at', startOfWeek.toISOString())
      .single();

    if (existingUsage) {
      // Update existing record
      await supabase
        .from('token_usage')
        .update({ 
          tokens_used: existingUsage.tokens_used + tokens,
          updated_at: new Date().toISOString()
        })
        .eq('id', existingUsage.id);
    } else {
      // Create new record
      await supabase
        .from('token_usage')
        .insert([{
          user_id: userId,
          tokens_used: tokens,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }]);
    }
  } catch (error) {
    console.error('Error updating token usage:', error);
  }
}

export async function sendMessage(messages: Message[], topic: string, courseName: string, userId?: string, userName?: string) {
  try {
    // Check token limit if userId is provided
    if (userId) {
      const hasTokensAvailable = await checkTokenLimit(userId);
      if (!hasTokensAvailable) {
        return "It looks like you've used up your time with me for this week - I look forward to our next chat!";
      }
    }

    // For first message, create a personalized greeting
    if (messages.length === 0) {
      const firstName = userName?.split(' ')[0] || '';
      const greeting = firstName
        ? `How can I assist you on the topic of <b>${topic}</b>, ${firstName}?\n\nAsk me a question or if you'd like, I can quiz you!`
        : `How can I assist you on the topic of <b>${topic}</b>?\n\nAsk me a question or if you'd like, I can quiz you!`;
      
      return greeting;
    }

    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        model: 'gpt-4',
        messages: [
          { role: 'system', content: getSystemPrompt(topic, courseName) },
          ...messages.map(msg => ({
            role: msg.role,
            content: msg.content,
          })),
        ],
        temperature: 0.7,
        max_tokens: 500,
      }),
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    const data = await response.json();
    const tokensUsed = data.usage.total_tokens;

    // Update token usage if userId is provided
    if (userId) {
      await updateTokenUsage(userId, tokensUsed);
    }

    return data.choices[0].message.content;
  } catch (error) {
    console.error('Error calling OpenAI API:', error);
    throw new Error('Failed to get response from AI tutor. Please try again.');
  }
}