import { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Command, CommandInput, CommandList, CommandGroup, CommandItem, CommandEmpty } from '@/components/ui/command';
import type { Subject, Course, Module } from '@/data/types';
import { useToast } from '@/hooks/use-toast';
import { cn } from '@/lib/utils';

interface SearchResult {
  type: 'course' | 'module';
  subject: string;
  course?: string;
  moduleIndex?: number;
  audioIndex?: number;
  title: string;
  content?: string;
}

interface SearchDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCourseSelect: (subject: string, course: string) => void;
  onModuleSelect: (subject: string, course: string, moduleIndex: number, audioIndex: number) => void;
}

export function SearchDialog({ open, onOpenChange, onCourseSelect, onModuleSelect }: SearchDialogProps) {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [allCourses, setAllCourses] = useState<Course[]>([]);
  const [allModules, setAllModules] = useState<{ [key: string]: Module[] }>({});
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    if (open) {
      loadData();
    }
  }, [open]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      
      const { data: subjectsData, error: subjectsError } = await supabase
        .from('subjects')
        .select('*');

      if (subjectsError) throw subjectsError;
      setSubjects(subjectsData || []);

      const coursesPromises = subjectsData?.map(async (subject) => {
        const { data: coursesData } = await supabase
          .from('courses')
          .select('*')
          .eq('subject_id', subject.id);
        return coursesData || [];
      });

      const coursesData = await Promise.all(coursesPromises || []);
      const allCoursesData = coursesData.flat();
      setAllCourses(allCoursesData);

      const modulesData: { [key: string]: Module[] } = {};
      for (const course of allCoursesData) {
        const { data: modulesList } = await supabase
          .from('modules')
          .select('*')
          .eq('course_id', course.id)
          .order('display_order');
        
        if (modulesList) {
          modulesData[course.id] = modulesList;
        }
      }
      setAllModules(modulesData);

    } catch (error) {
      console.error('Error loading search data:', error);
      toast({
        title: 'Error',
        description: 'Failed to load search data. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      return;
    }

    const query = searchQuery.toLowerCase();
    const results: SearchResult[] = [];

    allCourses.forEach(course => {
      const subject = subjects.find(s => s.id === course.subject_id);
      if (!subject) return;

      if (course.name.toLowerCase().includes(query)) {
        results.push({
          type: 'course',
          subject: subject.name,
          course: course.name,
          title: course.name,
        });
      }

      const modules = allModules[course.id] || [];
      modules.forEach((module, moduleIndex) => {
        if (module.title.toLowerCase().includes(query)) {
          results.push({
            type: 'module',
            subject: subject.name,
            course: course.name,
            moduleIndex,
            title: module.title,
          });
        }

        module.content.forEach((content, audioIndex) => {
          if (content.toLowerCase().includes(query)) {
            results.push({
              type: 'module',
              subject: subject.name,
              course: course.name,
              moduleIndex,
              audioIndex,
              title: module.title,
              content,
            });
          }
        });
      });
    });

    setSearchResults(results);
  }, [searchQuery, subjects, allCourses, allModules]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent 
        className="p-0 bg-zinc-900 border-zinc-800 max-w-3xl overflow-hidden"
        aria-describedby="search-dialog-description"
      >
        <DialogHeader className="px-4 pt-4">
          <DialogTitle className="text-lg font-semibold text-white">Search Courses & Modules</DialogTitle>
        </DialogHeader>
        <div id="search-dialog-description" className="sr-only">
          Search through all courses and modules
        </div>
        <Command shouldFilter={false} className="border-0">
          <div className="border-b border-zinc-800">
            <div className="flex items-center px-4 py-3">
              <Search className="h-5 w-5 shrink-0 text-zinc-400" />
              <CommandInput
                value={searchQuery}
                onValueChange={setSearchQuery}
                placeholder={isLoading ? "Loading..." : "Search courses and modules..."}
                className="flex-1 h-11 bg-transparent py-3 text-sm outline-none text-white placeholder:text-zinc-500 disabled:cursor-not-allowed disabled:opacity-50 border-0 focus:ring-0 ml-2"
                disabled={isLoading}
              />
            </div>
          </div>
          <CommandList className="max-h-[60vh] overflow-y-auto p-4">
            {isLoading ? (
              <div className="py-6 text-center text-sm text-zinc-400">
                Loading...
              </div>
            ) : searchResults.length === 0 && searchQuery.trim() !== '' ? (
              <div className="py-6 text-center text-sm text-zinc-400">
                No results found
              </div>
            ) : (
              <div className="space-y-6">
                {searchResults.some(result => result.type === 'course') && (
                  <CommandGroup heading="Courses" className="text-xs font-semibold text-zinc-400">
                    {searchResults
                      .filter(result => result.type === 'course')
                      .map((result, index) => (
                        <div
                          key={`course-${index}`}
                          className={cn(
                            "flex items-center justify-between p-3 rounded-xl",
                            "hover:bg-zinc-800/50 cursor-pointer group"
                          )}
                          onClick={() => {
                            onCourseSelect(result.subject, result.course!);
                            onOpenChange(false);
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="flex-1 min-w-0 mr-4">
                            <p className="font-medium text-white truncate group-hover:text-blue-400 transition-colors">
                              {result.title}
                            </p>
                            <p className="text-sm text-zinc-400 truncate">
                              {result.subject}
                            </p>
                          </div>
                          <button 
                            onClick={(e) => {
                              e.stopPropagation();
                              onCourseSelect(result.subject, result.course!);
                              onOpenChange(false);
                            }}
                            className="px-4 py-2 text-sm font-medium rounded-xl bg-blue-600 hover:bg-blue-700 text-white transition-colors"
                          >
                            Go to Course
                          </button>
                        </div>
                      ))}
                  </CommandGroup>
                )}
                {searchResults.some(result => result.type === 'module') && (
                  <CommandGroup heading="Modules" className="text-xs font-semibold text-zinc-400">
                    {searchResults
                      .filter(result => result.type === 'module' && result.audioIndex !== undefined)
                      .map((result, index) => (
                        <div
                          key={`module-${index}`}
                          className={cn(
                            "flex items-center justify-between p-3 rounded-xl",
                            "hover:bg-zinc-800/50 cursor-pointer group"
                          )}
                          onClick={() => {
                            onModuleSelect(result.subject, result.course!, result.moduleIndex!, result.audioIndex!);
                            onOpenChange(false);
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="flex-1 min-w-0 mr-4">
                            <p className="font-medium text-white truncate group-hover:text-blue-400 transition-colors">
                              {result.content || result.title}
                            </p>
                            <p className="text-sm text-zinc-400 truncate">
                              {result.course} • {result.title}
                            </p>
                          </div>
                          <button 
                            onClick={(e) => {
                              e.stopPropagation();
                              onModuleSelect(result.subject, result.course!, result.moduleIndex!, result.audioIndex!);
                              onOpenChange(false);
                            }}
                            className="px-4 py-2 text-sm font-medium rounded-xl bg-blue-600 hover:bg-blue-700 text-white transition-colors"
                          >
                            Listen
                          </button>
                        </div>
                      ))}
                  </CommandGroup>
                )}
              </div>
            )}
          </CommandList>
        </Command>
      </DialogContent>
    </Dialog>
  );
}