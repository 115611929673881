import { createClient } from '@supabase/supabase-js';
import type { Profile } from './types';

const SUPABASE_URL = 'https://eshsexdkemqjkcipeccy.supabase.co';
const SUPABASE_ANON_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVzaHNleGRrZW1xamtjaXBlY2N5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjk4Njg3NDAsImV4cCI6MjA0NTQ0NDc0MH0.dX4nKDbEMsHw_zvOuHkch7QWVI2obH26tFuAtkKUIBU';

export const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
  },
  global: {
    headers: {
      'Content-Type': 'application/json',
    },
  },
  db: {
    schema: 'public',
  },
});

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function retryOperation<T>(operation: () => Promise<T>, retries = MAX_RETRIES): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0) {
      console.log(`🔄 Retrying operation (${MAX_RETRIES - retries + 1}/${MAX_RETRIES})...`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return retryOperation(operation, retries - 1);
    }
    throw error;
  }
}

export async function fetchProfile(userId: string): Promise<Profile | null> {
  try {
    const { data, error } = await retryOperation(() =>
      supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single()
    );

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    return null;
  }
}

export async function fetchSubjects() {
  try {
    const { data, error } = await retryOperation(() =>
      supabase
        .from('subjects')
        .select('*')
        .order('display_order')
    );

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching subjects:', error);
    throw error;
  }
}

export async function fetchCourses(subjectName: string) {
  try {
    // First get subject ID
    const { data: subject, error: subjectError } = await retryOperation(() =>
      supabase
        .from('subjects')
        .select('id')
        .eq('name', subjectName)
        .single()
    );

    if (subjectError) throw subjectError;
    if (!subject) throw new Error('Subject not found');

    // Then get courses
    const { data, error } = await retryOperation(() =>
      supabase
        .from('courses')
        .select('*')
        .eq('subject_id', subject.id)
        .order('name')
    );

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching courses:', error);
    throw error;
  }
}

export async function fetchModules(subjectName: string, courseName: string) {
  try {
    // Get subject ID
    const { data: subject, error: subjectError } = await retryOperation(() =>
      supabase
        .from('subjects')
        .select('id')
        .eq('name', subjectName)
        .single()
    );

    if (subjectError) throw subjectError;
    if (!subject) throw new Error('Subject not found');

    // Get course ID
    const { data: course, error: courseError } = await retryOperation(() =>
      supabase
        .from('courses')
        .select('id')
        .eq('name', courseName)
        .eq('subject_id', subject.id)
        .single()
    );

    if (courseError) throw courseError;
    if (!course) throw new Error('Course not found');

    // Get modules
    const { data, error } = await retryOperation(() =>
      supabase
        .from('modules')
        .select('*')
        .eq('course_id', course.id)
        .order('display_order')
    );

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching modules:', error);
    throw error;
  }
}

export async function fetchQuiz(subjectName: string, courseName: string) {
  try {
    // Get subject ID
    const { data: subject, error: subjectError } = await retryOperation(() =>
      supabase
        .from('subjects')
        .select('id')
        .eq('name', subjectName)
        .single()
    );

    if (subjectError) throw subjectError;
    if (!subject) throw new Error('Subject not found');

    // Get course ID
    const { data: course, error: courseError } = await retryOperation(() =>
      supabase
        .from('courses')
        .select('id')
        .eq('name', courseName)
        .eq('subject_id', subject.id)
        .single()
    );

    if (courseError) throw courseError;
    if (!course) throw new Error('Course not found');

    // Get quiz with questions
    const { data, error } = await retryOperation(() =>
      supabase
        .from('quizzes')
        .select(`
          *,
          quiz_questions (*)
        `)
        .eq('course_id', course.id)
        .single()
    );

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching quiz:', error);
    throw error;
  }
}

export async function saveQuiz(subjectName: string, courseName: string, questions: any[]) {
  try {
    // Get subject ID
    const { data: subject, error: subjectError } = await retryOperation(() =>
      supabase
        .from('subjects')
        .select('id')
        .eq('name', subjectName)
        .single()
    );

    if (subjectError) throw subjectError;
    if (!subject) throw new Error('Subject not found');

    // Get course ID
    const { data: course, error: courseError } = await retryOperation(() =>
      supabase
        .from('courses')
        .select('id')
        .eq('name', courseName)
        .eq('subject_id', subject.id)
        .single()
    );

    if (courseError) throw courseError;
    if (!course) throw new Error('Course not found');

    // Create or update quiz
    const { data: quiz, error: quizError } = await retryOperation(() =>
      supabase
        .from('quizzes')
        .upsert({ course_id: course.id })
        .select()
        .single()
    );

    if (quizError) throw quizError;

    // Delete existing questions
    await retryOperation(() =>
      supabase
        .from('quiz_questions')
        .delete()
        .eq('quiz_id', quiz.id)
    );

    // Insert new questions
    const { error: questionsError } = await retryOperation(() =>
      supabase
        .from('quiz_questions')
        .insert(
          questions.map((q, index) => ({
            quiz_id: quiz.id,
            question: q.question,
            correct_answer: q.correctAnswer,
            wrong_answer_1: q.wrongAnswer1,
            wrong_answer_2: q.wrongAnswer2,
            wrong_answer_3: q.wrongAnswer3,
            display_order: index,
          }))
        )
    );

    if (questionsError) throw questionsError;

    return quiz;
  } catch (error) {
    console.error('Error saving quiz:', error);
    throw error;
  }
}