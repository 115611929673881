import type { Subject } from '@/data/types';

const SUBJECT_CACHE_KEY = 'cached_subjects';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

interface CachedData<T> {
  data: T;
  timestamp: number;
}

export function getCachedSubjects(): Subject[] | null {
  try {
    const cached = localStorage.getItem(SUBJECT_CACHE_KEY);
    if (!cached) return null;

    const { data, timestamp }: CachedData<Subject[]> = JSON.parse(cached);
    const now = Date.now();

    if (now - timestamp <= CACHE_DURATION) {
      console.log('📦 Using cached subjects');
      return data;
    }

    console.log('🕒 Subject cache expired');
    localStorage.removeItem(SUBJECT_CACHE_KEY);
    return null;
  } catch (error) {
    console.warn('⚠️ Error reading cached subjects:', error);
    localStorage.removeItem(SUBJECT_CACHE_KEY);
    return null;
  }
}

export function setCachedSubjects(subjects: Subject[]): void {
  try {
    if (!subjects?.length) {
      console.warn('⚠️ Attempted to cache empty subjects array');
      return;
    }

    const cacheData: CachedData<Subject[]> = {
      data: subjects,
      timestamp: Date.now(),
    };
    localStorage.setItem(SUBJECT_CACHE_KEY, JSON.stringify(cacheData));
    console.log('💾 Subjects cached successfully');
  } catch (error) {
    console.warn('⚠️ Error caching subjects:', error);
  }
}

export function clearSubjectsCache(): void {
  try {
    localStorage.removeItem(SUBJECT_CACHE_KEY);
    console.log('🧹 Subjects cache cleared');
  } catch (error) {
    console.warn('⚠️ Error clearing subjects cache:', error);
  }
}