import { useState, useEffect, useRef } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { getRankCache, setRankCache } from '@/lib/rank-cache';
import { supabase } from '@/lib/supabase';

export type Rank = 'Listener' | 'Learner' | 'Explorer' | 'Master' | 'Scholarship';

interface RankInfo {
  rank: Rank;
  count: number;
  nextRank?: Rank;
  progress: number;
  requirementForNext?: number;
}

const RANK_THRESHOLDS = {
  LEARNER: 2,
  EXPLORER: 4,
  MASTER: 8,
};

const DEFAULT_RANK_INFO: RankInfo = {
  rank: 'Listener',
  count: 0,
  nextRank: 'Learner',
  progress: 0,
  requirementForNext: RANK_THRESHOLDS.LEARNER
};

export function useRank() {
  const [rankInfo, setRankInfo] = useState<RankInfo>(DEFAULT_RANK_INFO);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { profile } = useAuth();
  const hasChecked = useRef(false);

  const checkRank = async () => {
    if (!profile?.email) {
      setRankInfo(DEFAULT_RANK_INFO);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // Check cache first
      const cached = getRankCache();
      if (cached && cached.email === profile.email) {
        setRankInfo(cached.rankInfo);
        setIsLoading(false);
        return;
      }

      // First check if user is on whitelist
      const { data: whitelistData } = await supabase
        .from('whitelist_emails')
        .select('*')
        .eq('email', profile.email.toLowerCase())
        .single();

      if (whitelistData) {
        const scholarshipRank = {
          rank: 'Scholarship' as Rank,
          count: RANK_THRESHOLDS.MASTER,
          nextRank: undefined,
          progress: 100,
          requirementForNext: undefined
        };
        setRankInfo(scholarshipRank);
        setRankCache({ email: profile.email, rankInfo: scholarshipRank });
        return;
      }

      // If user is admin, automatically assign Master rank
      if (profile.role === 'admin') {
        const adminRank = {
          rank: 'Master' as Rank,
          count: RANK_THRESHOLDS.MASTER,
          nextRank: undefined,
          progress: 100,
          requirementForNext: undefined
        };
        setRankInfo(adminRank);
        setRankCache({ email: profile.email, rankInfo: adminRank });
        return;
      }
      
      const url = `https://audiumpay.atrus.uk/successful-payments?email=${encodeURIComponent(profile.email)}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Origin': window.location.origin
        },
        mode: 'cors'
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch rank: ${response.statusText}`);
      }

      const data = await response.json();
      const count = data.count || 0;

      // Calculate rank and progress
      let rank: Rank = 'Listener';
      let nextRank: Rank | undefined = 'Learner';
      let requirementForNext = RANK_THRESHOLDS.LEARNER;
      let progress = 0;

      if (count >= RANK_THRESHOLDS.MASTER) {
        rank = 'Master';
        nextRank = undefined;
        progress = 100;
      } else if (count >= RANK_THRESHOLDS.EXPLORER) {
        rank = 'Explorer';
        nextRank = 'Master';
        requirementForNext = RANK_THRESHOLDS.MASTER;
        progress = ((count - RANK_THRESHOLDS.EXPLORER) / 
                  (RANK_THRESHOLDS.MASTER - RANK_THRESHOLDS.EXPLORER)) * 100;
      } else if (count >= RANK_THRESHOLDS.LEARNER) {
        rank = 'Learner';
        nextRank = 'Explorer';
        requirementForNext = RANK_THRESHOLDS.EXPLORER;
        progress = ((count - RANK_THRESHOLDS.LEARNER) / 
                  (RANK_THRESHOLDS.EXPLORER - RANK_THRESHOLDS.LEARNER)) * 100;
      } else {
        progress = (count / RANK_THRESHOLDS.LEARNER) * 100;
      }

      const newRankInfo = {
        rank,
        count,
        nextRank,
        progress: Math.min(Math.max(progress, 0), 100),
        requirementForNext,
      };

      setRankInfo(newRankInfo);
      setRankCache({ email: profile.email, rankInfo: newRankInfo });
    } catch (error) {
      console.error('Error fetching rank:', error);
      setError(error instanceof Error ? error : new Error('Failed to fetch rank'));
      setRankInfo(DEFAULT_RANK_INFO);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Reset state when profile changes
    setRankInfo(DEFAULT_RANK_INFO);
    setIsLoading(true);
    setError(null);
    
    checkRank();
  }, [profile?.email, profile?.role]);

  return { 
    ...rankInfo, 
    isLoading,
    error,
    // Helper method to check if user can access certain features
    canAccess: (requiredRank: Rank) => {
      if (rankInfo.rank === 'Scholarship') return true;
      const ranks: Rank[] = ['Listener', 'Learner', 'Explorer', 'Master'];
      const currentRankIndex = ranks.indexOf(rankInfo.rank);
      const requiredRankIndex = ranks.indexOf(requiredRank);
      return currentRankIndex >= requiredRankIndex;
    }
  };
}