import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initializeFacebookPixel, trackPageView, trackViewContent } from '@/lib/facebook-pixel';

export function FacebookPixel() {
  const location = useLocation();

  useEffect(() => {
    // Initialize Facebook Pixel on mount
    initializeFacebookPixel();
  }, []);

  useEffect(() => {
    // Track page view and view content on route change
    trackPageView();
    trackViewContent(location.pathname);
  }, [location]);

  return null;
}