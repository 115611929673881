import { Pencil, Trash2, Save, Lock, Unlock, Rocket } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { IconPicker } from './IconPicker';
import { icons } from './icons';
import type { Subject } from '@/data/types';

interface SubjectItemProps {
  subject: Subject;
  editingSubject: string | null;
  editingData: any;
  isLoading: boolean;
  onSelect: (subject: string) => void;
  onStartEditing: (subject: Subject) => void;
  onDelete: (subject: Subject) => void;
  onUpdate: (subject: Subject) => void;
  onCancelEdit: () => void;
  onEditingDataChange: (data: any) => void;
}

export function SubjectItem({
  subject,
  editingSubject,
  editingData,
  isLoading,
  onSelect,
  onStartEditing,
  onDelete,
  onUpdate,
  onCancelEdit,
  onEditingDataChange,
}: SubjectItemProps) {
  const IconComponent = ({ iconName }: { iconName: string }) => {
    const IconFound = icons.find(i => i.name === iconName)?.icon;
    const DefaultIcon = icons[0].icon;
    return IconFound ? <IconFound className="w-5 h-5 text-white" /> : <DefaultIcon className="w-5 h-5 text-white" />;
  };

  if (editingSubject === subject.name) {
    return (
      <div className="space-y-4" onClick={(e) => e.stopPropagation()}>
        <Input
          value={editingData?.name}
          onChange={(e) => onEditingDataChange({ ...editingData, name: e.target.value })}
          className="bg-zinc-700 border-zinc-600 text-white rounded-xl w-full"
          placeholder="Subject name"
          autoFocus
        />

        <Input
          value={editingData?.bgImage}
          onChange={(e) => onEditingDataChange({ ...editingData, bgImage: e.target.value })}
          className="bg-zinc-700 border-zinc-600 text-white rounded-xl w-full"
          placeholder="Background image URL"
        />

        <div className="grid grid-cols-3 gap-4">
          <div className="space-y-2">
            <Label className="text-white">Color Theme</Label>
            <Input
              type="color"
              value={editingData?.color}
              onChange={(e) => onEditingDataChange({ ...editingData, color: e.target.value })}
              className="w-full h-10 bg-zinc-700 border-zinc-600 rounded-xl cursor-pointer"
            />
          </div>

          <div className="space-y-2">
            <Label className="text-white">Icon</Label>
            <IconPicker
              value={editingData?.icon || 'Book'}
              onChange={(value) => onEditingDataChange({ ...editingData, icon: value })}
            />
          </div>

          <div className="space-y-2">
            <Label className="text-white">Category</Label>
            <Select
              value={editingData?.category}
              onValueChange={(value) => onEditingDataChange({ ...editingData, category: value })}
            >
              <SelectTrigger className="bg-zinc-700 border-zinc-600 text-white">
                <SelectValue placeholder="Select category" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Academic">Academic</SelectItem>
                <SelectItem value="Lifestyle">Lifestyle</SelectItem>
                <SelectItem value="Specialised">Specialised</SelectItem>
                <SelectItem value="Entertainment">Entertainment</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="flex items-center gap-4 p-4 bg-zinc-900 rounded-xl border border-zinc-700">
          <div className="flex items-center gap-3 flex-1">
            <div className="p-2 rounded-lg bg-zinc-800">
              {editingData?.isLocked ? (
                <Lock className="w-5 h-5 text-blue-400" />
              ) : (
                <Unlock className="w-5 h-5 text-zinc-400" />
              )}
            </div>
            <div>
              <Label className="text-white font-medium">Lock Subject</Label>
              <p className="text-sm text-zinc-400">
                {editingData?.isLocked ? 'Subject is locked' : 'Subject is unlocked'}
              </p>
            </div>
          </div>
          <Switch
            checked={editingData?.isLocked}
            onCheckedChange={(checked) => 
              onEditingDataChange({ ...editingData, isLocked: checked })
            }
            className="data-[state=checked]:bg-blue-600 h-6 w-11"
          />
        </div>

        {editingData?.isLocked && (
          <div className="space-y-2">
            <Label htmlFor="releaseDate" className="text-white">Release Date</Label>
            <Input
              id="releaseDate"
              type="datetime-local"
              value={editingData.releaseDate}
              onChange={(e) => onEditingDataChange({ ...editingData, releaseDate: e.target.value })}
              className="bg-zinc-700 border-zinc-600 text-white rounded-xl w-full"
            />
          </div>
        )}

        <div className="flex gap-2 justify-end">
          <Button
            onClick={onCancelEdit}
            size="sm"
            variant="outline"
            className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
          >
            Cancel
          </Button>
          <Button
            onClick={() => onUpdate(subject)}
            disabled={isLoading}
            size="sm"
            className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
          >
            <Save className="w-4 h-4 mr-2" />
            Save
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3">
        <div className="p-2 rounded-lg bg-zinc-900">
          <IconComponent iconName={subject.icon} />
        </div>
        <div className="flex flex-col">
          <h3 
            className="text-lg font-medium text-white group-hover:text-blue-400 transition-colors cursor-pointer"
            onClick={() => onSelect(subject.name)}
          >
            {subject.name}
          </h3>
          <span className="text-sm text-zinc-400">{subject.category}</span>
        </div>
        {subject.is_locked && (
          <div className="flex items-center gap-2 px-3 py-1.5 rounded-lg bg-zinc-900 border border-zinc-700">
            {subject.release_date ? (
              <>
                <Rocket className="w-4 h-4 text-blue-400" />
                <span className="text-sm text-blue-400 font-medium">Scheduled</span>
              </>
            ) : (
              <>
                <Lock className="w-4 h-4 text-zinc-400" />
                <span className="text-sm text-zinc-400 font-medium">Locked</span>
              </>
            )}
          </div>
        )}
      </div>
      <div className="flex gap-2">
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onStartEditing(subject);
          }}
          disabled={isLoading}
          size="sm"
          className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
        >
          <Pencil className="w-4 h-4" />
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onDelete(subject);
          }}
          disabled={isLoading}
          size="sm"
          className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
        >
          <Trash2 className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
}