import { ExternalLink } from 'lucide-react';
import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';

interface NewsMessage {
  id: string;
  message: string;
  link?: string | null;
  link_text?: string | null;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

export function NewsBanner() {
  const [message, setMessage] = useState<NewsMessage | null>(null);

  useEffect(() => {
    const fetchActiveMessage = async () => {
      const { data, error } = await supabase
        .from('news_messages')
        .select('*')
        .eq('is_active', true)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (!error && data) {
        setMessage(data);
      }
    };

    fetchActiveMessage();

    // Subscribe to changes
    const channel = supabase
      .channel('news_messages_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'news_messages'
        },
        () => {
          fetchActiveMessage();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  if (!message) return null;

  const renderMessage = () => {
    if (!message.link || !message.link_text) {
      return message.message;
    }

    const parts = message.message.split(message.link_text);
    if (parts.length === 1) return message.message;

    return (
      <>
        {parts[0]}
        <a
          href={message.link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-1 hover:underline"
        >
          {message.link_text}
          <ExternalLink className="w-3 h-3" />
        </a>
        {parts[1]}
      </>
    );
  };

  return (
    <div className="bg-gradient-to-r from-blue-600 to-blue-700 text-white lg:relative lg:top-0 fixed top-14 left-0 right-0 z-30">
      <div className="max-w-7xl mx-auto flex items-center justify-between py-2 px-4">
        <p className="text-sm font-medium">
          {renderMessage()}
        </p>
      </div>
    </div>
  );
}