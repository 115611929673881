import type { User } from '@supabase/supabase-js';
import type { Profile } from '@/lib/types';

const SESSION_CACHE_KEY = 'auth_session_v2';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

interface CachedSession {
  user: User;
  profile: Profile;
  timestamp: number;
  expiresAt: string;
}

export function getCachedSession(): CachedSession | null {
  try {
    const cached = localStorage.getItem(SESSION_CACHE_KEY);
    if (!cached) {
      console.log('🔍 No cached session found');
      return null;
    }

    const session: CachedSession = JSON.parse(cached);
    const now = Date.now();
    const expiresAt = new Date(session.expiresAt).getTime();

    if (now < expiresAt) {
      console.log('✅ Valid session found:', {
        userId: session.user.id,
        email: session.user.email,
        expiresIn: Math.round((expiresAt - now) / 1000 / 60),
        unit: 'minutes'
      });
      return session;
    }

    console.log('❌ Cached session expired');
    localStorage.removeItem(SESSION_CACHE_KEY);
    return null;
  } catch (error) {
    console.warn('❌ Error reading cached session:', error);
    localStorage.removeItem(SESSION_CACHE_KEY);
    return null;
  }
}

export function setCachedSession(user: User, profile: Profile): void {
  try {
    const expiresAt = new Date(Date.now() + CACHE_DURATION);
    
    const session: CachedSession = {
      user,
      profile,
      timestamp: Date.now(),
      expiresAt: expiresAt.toUTCString(),
    };

    localStorage.setItem(SESSION_CACHE_KEY, JSON.stringify(session));
    console.log('💾 Setting new session cache...', {
      userId: user.id,
      email: user.email,
      expiresAt: expiresAt.toUTCString()
    });
  } catch (error) {
    console.warn('❌ Error caching session:', error);
  }
}

export function clearCachedSession(): void {
  try {
    localStorage.removeItem(SESSION_CACHE_KEY);
    console.log('🧹 Session cache cleared');
  } catch (error) {
    console.warn('❌ Error clearing cached session:', error);
  }
}