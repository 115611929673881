import { X } from 'lucide-react';
import { fetchSubjects } from '@/lib/supabase';
import { useState, useEffect } from 'react';
import type { Subject } from '@/data/types';

interface ResumeBannerProps {
  subject: string;
  course: string;
  moduleIndex: number;
  audioIndex: number;
  onResume: () => void;
  onDismiss: () => void;
}

export function ResumeBanner({
  subject,
  course,
  moduleIndex,
  audioIndex,
  onResume,
  onDismiss,
}: ResumeBannerProps) {
  const [subjectData, setSubjectData] = useState<Subject | null>(null);

  useEffect(() => {
    const loadSubjectData = async () => {
      try {
        const subjects = await fetchSubjects();
        const found = subjects.find(s => s.name === subject);
        if (found) {
          setSubjectData(found);
        }
      } catch (error) {
        console.error('Error loading subject data:', error);
      }
    };

    loadSubjectData();
  }, [subject]);

  if (!subjectData) return null;

  return (
    <div className="relative rounded-xl overflow-hidden mb-8">
      {/* Background Image */}
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${subjectData.bg_image})` }}
      />

      {/* Color Overlay */}
      <div 
        className="absolute inset-0"
        style={{
          background: `linear-gradient(to top, ${subjectData.color}dd, ${subjectData.color}aa, ${subjectData.color}22)`
        }}
      />

      <div className="relative p-6">
        <button
          onClick={onDismiss}
          className="absolute top-4 right-4 text-white/70 hover:text-white transition-colors"
        >
          <X className="w-5 h-5" />
        </button>
        <h3 className="text-lg font-medium text-white mb-1">Continue listening</h3>
        <p className="text-sm text-white/70 mb-4">
          {course} • Module {moduleIndex + 1}, Part {audioIndex + 1}
        </p>
        <button
          onClick={onResume}
          className="text-sm bg-white hover:bg-white/90 text-zinc-900 px-4 py-2 rounded-xl transition-colors"
        >
          Resume
        </button>
      </div>
    </div>
  );
}