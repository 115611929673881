import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/hooks/use-toast';
import { ArrowLeft } from 'lucide-react';

const requestSchema = z.object({
  description: z.string().min(20, 'Please provide at least 20 characters describing the course you would like'),
});

type RequestFormData = z.infer<typeof requestSchema>;

interface RequestCoursePageProps {
  onBack: () => void;
}

export function RequestCoursePage({ onBack }: RequestCoursePageProps) {
  const { profile } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<RequestFormData>({
    resolver: zodResolver(requestSchema),
  });

  const description = watch('description', '');
  const characterCount = description?.length || 0;

  const onSubmit = async (data: RequestFormData) => {
    try {
      setIsLoading(true);

      const { error } = await supabase
        .from('member_course_requests')
        .insert([
          {
            email: profile?.email,
            description: data.description,
            created_at: new Date().toISOString(),
          },
        ]);

      if (error) throw error;

      toast({
        title: "Request submitted",
        description: "Thank you for your course suggestion! We'll review it and get back to you.",
      });

      reset();
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto py-8 px-4">
      <button
        onClick={onBack}
        className="flex items-center gap-2 text-zinc-200 hover:text-white transition-colors mb-8 text-base"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Dashboard
      </button>

      <div className="bg-zinc-900 rounded-xl p-8 border border-zinc-800">
        <h1 className="text-3xl font-bold text-white mb-4">Request A Course</h1>
        <p className="text-zinc-400 mb-8">
          We're always looking to expand our course offerings. Tell us what you'd like to learn!
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="space-y-2">
            <label className="block text-zinc-200 text-base mb-2">Your Email</label>
            <p className="text-zinc-400">{profile?.email}</p>
          </div>

          <div className="space-y-2">
            <label htmlFor="description" className="block text-zinc-200 text-base">
              Course Description
            </label>
            <Textarea
              {...register('description')}
              id="description"
              placeholder="Please describe the course you'd like us to add to Audium..."
              className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500 min-h-[150px]"
            />
            <div className="flex justify-between items-center">
              <div>
                {errors.description && (
                  <p className="text-sm text-red-400">{errors.description.message}</p>
                )}
              </div>
              <div className="text-sm text-zinc-400">
                {characterCount}/20 characters
              </div>
            </div>
          </div>

          <Button
            type="submit"
            disabled={isLoading || characterCount < 20}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 text-base"
          >
            {isLoading ? 'Submitting...' : 'Submit Request'}
          </Button>
        </form>
      </div>
    </div>
  );
}