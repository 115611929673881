import { supabase } from './supabase';
import { clearSubjectsCache } from './cache';
import { clearCachedSession } from './auth/cache';
import { clearSubscriptionCache } from './subscription-cache';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const AUTH_TIMEOUT = 8000;

async function retryOperation<T>(operation: () => Promise<T>, retries = MAX_RETRIES): Promise<T> {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), AUTH_TIMEOUT);

    const result = await operation();
    clearTimeout(timeoutId);
    return result;
  } catch (error) {
    if (retries > 0) {
      console.log(`🔄 Retrying auth operation (${MAX_RETRIES - retries + 1}/${MAX_RETRIES})`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return retryOperation(operation, retries - 1);
    }
    throw error;
  }
}

export async function forceLogout() {
  console.log('🔄 Starting force logout process...');
  
  // Start immediate redirect timer
  const immediateRedirect = setTimeout(() => {
    window.location.replace(`${window.location.origin}?forceLogout=true`);
  }, 500);
  
  try {
    // 1. Clear Supabase Auth with retry
    try {
      await retryOperation(() => 
        supabase.auth.signOut({ scope: 'global' })
      );
    } catch (err) {
      console.warn('Auth signout warning:', err);
    }

    // 2. Clear all caches aggressively
    try {
      clearSubjectsCache();
      clearCachedSession();
      clearSubscriptionCache();
      localStorage.clear();
      sessionStorage.clear();
    } catch (e) {
      console.warn('Storage clear warning:', e);
    }
    
    // 3. Clear all cookies aggressively
    try {
      const cookies = document.cookie.split(';');
      cookies.forEach(cookie => {
        const name = cookie.split('=')[0].trim();
        if (!name) return;
        
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname};`;
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${window.location.hostname};`;
      });
    } catch (e) {
      console.warn('Cookie clear warning:', e);
    }

    // 4. Clear IndexedDB
    try {
      const databases = await window.indexedDB.databases();
      await Promise.all(
        databases.map(db => {
          return new Promise((resolve) => {
            if (!db.name) return resolve(null);
            const request = window.indexedDB.deleteDatabase(db.name);
            request.onsuccess = () => resolve(null);
            request.onerror = () => resolve(null);
          });
        })
      );
    } catch (e) {
      console.warn('IndexedDB clear warning:', e);
    }

    // 5. Clear Cache Storage
    try {
      if ('caches' in window) {
        const keys = await caches.keys();
        await Promise.all(keys.map(key => caches.delete(key)));
      }
    } catch (e) {
      console.warn('Cache clear warning:', e);
    }

    // Clear the immediate redirect timer if we made it this far
    clearTimeout(immediateRedirect);

    // 6. Force redirect using multiple methods
    try {
      window.location.replace(`${window.location.origin}?forceLogout=true`);
    } catch (e) {
      console.warn('Primary redirect failed:', e);
      try {
        window.location.href = `${window.location.origin}?forceLogout=true`;
      } catch (e2) {
        console.warn('Secondary redirect failed:', e2);
        window.location.assign(`${window.location.origin}?forceLogout=true`);
      }
    }
  } catch (error) {
    console.error('❌ Error during force logout:', error);
    // Force redirect even if there's an error
    window.location.replace(`${window.location.origin}?forceLogout=true`);
  }
  
  return true;
}