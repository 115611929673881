import { useState, useEffect } from 'react';
import { ArrowLeft, Download, RotateCw } from 'lucide-react';
import { Button } from './ui/button';
import { Progress } from './ui/progress';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import jsPDF from 'jspdf';

interface QuizResult {
  quiz_id: string;
  subject_name: string;
  course_name: string;
  score: number;
  total_questions: number;
  has_certificate: boolean;
  created_at: string;
}

interface CertificatesPageProps {
  onBack: () => void;
}

export function CertificatesPage({ onBack }: CertificatesPageProps) {
  const [quizResults, setQuizResults] = useState<QuizResult[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const { user, profile } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    loadQuizResults();
  }, []);

  const loadQuizResults = async () => {
    try {
      setIsLoading(true);

      const { data: results, error: resultsError } = await supabase
        .from('quiz_results')
        .select(`
          quiz_id,
          score,
          total_questions,
          created_at,
          quizzes (
            course_id,
            courses (
              name,
              subject_id,
              subjects (
                name
              )
            )
          )
        `)
        .eq('user_id', user?.id)
        .order('created_at', { ascending: false });

      if (resultsError) throw resultsError;

      const { data: certificates, error: certificatesError } = await supabase
        .from('certificates')
        .select('quiz_id')
        .eq('user_id', user?.id);

      if (certificatesError) throw certificatesError;

      const certificateQuizIds = new Set(certificates?.map(c => c.quiz_id) || []);

      const formattedResults = results
        .filter(r => r.quizzes?.courses?.subjects)
        .map(result => ({
          quiz_id: result.quiz_id,
          subject_name: result.quizzes.courses.subjects.name,
          course_name: result.quizzes.courses.name,
          score: result.score,
          total_questions: result.total_questions,
          has_certificate: certificateQuizIds.has(result.quiz_id),
          created_at: result.created_at,
        }))
        .reduce((acc, curr) => {
          const existing = acc.find(r => r.quiz_id === curr.quiz_id);
          if (!existing || curr.score > existing.score) {
            return [...acc.filter(r => r.quiz_id !== curr.quiz_id), curr];
          }
          return acc;
        }, [] as QuizResult[]);

      setQuizResults(formattedResults);
    } catch (error) {
      console.error('Error loading quiz results:', error);
      toast({
        title: 'Error',
        description: 'Failed to load quiz results',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveCertificateRecord = async (result: QuizResult) => {
    try {
      const { data: existingCert } = await supabase
        .from('certificates')
        .select('id')
        .eq('user_id', user?.id)
        .eq('quiz_id', result.quiz_id)
        .single();

      if (existingCert) {
        return true;
      }

      const { error: saveError } = await supabase
        .from('certificates')
        .insert([{
          user_id: user?.id,
          quiz_id: result.quiz_id,
          course_name: result.course_name,
          subject_name: result.subject_name,
          score: result.score,
          total_questions: result.total_questions,
        }]);

      if (saveError) throw saveError;
      return true;
    } catch (error) {
      console.error('Error saving certificate record:', error);
      return false;
    }
  };

  const generateCertificate = async (result: QuizResult) => {
    if (!user?.id || !profile?.full_name) {
      toast({
        title: 'Error',
        description: 'User profile information is missing',
        variant: 'destructive',
      });
      return;
    }

    try {
      setIsGenerating(true);

      // Save certificate record
      const saved = await saveCertificateRecord(result);
      if (!saved) {
        throw new Error('Failed to save certificate record');
      }

      // Create PDF document
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
      });

      // Set background color
      doc.setFillColor(252, 251, 247);
      doc.rect(0, 0, 297, 210, 'F');

      // Add decorative border
      doc.setDrawColor(29, 78, 216);
      doc.setLineWidth(1);
      doc.rect(20, 20, 257, 170);
      doc.setLineWidth(0.5);
      doc.rect(25, 25, 247, 160);

      // Add ornamental corners
      doc.setLineWidth(1);
      doc.line(20, 20, 35, 20);
      doc.line(20, 20, 20, 35);
      doc.line(277, 20, 262, 20);
      doc.line(277, 20, 277, 35);
      doc.line(20, 190, 35, 190);
      doc.line(20, 190, 20, 175);
      doc.line(277, 190, 262, 190);
      doc.line(277, 190, 277, 175);

      // Add header
      doc.setLineWidth(2);
      doc.line(74, 65, 223, 65);
      doc.setTextColor(29, 78, 216);
      doc.setFontSize(42);
      doc.setFont('helvetica', 'bold');
      doc.text('CERTIFICATE', 148.5, 45, { align: 'center' });
      doc.setFontSize(24);
      doc.setFont('helvetica', 'normal');
      doc.text('OF ACHIEVEMENT', 148.5, 60, { align: 'center' });

      // Add content
      doc.setTextColor(33, 33, 33);
      doc.setFontSize(16);
      doc.text('This is to certify that', 148.5, 90, { align: 'center' });
      doc.setFontSize(28);
      doc.setFont('helvetica', 'bold');
      doc.text(profile.full_name, 148.5, 105, { align: 'center' });
      doc.setFontSize(16);
      doc.setFont('helvetica', 'normal');
      doc.text('has successfully completed the course', 148.5, 125, { align: 'center' });
      doc.setFontSize(24);
      doc.setFont('helvetica', 'bold');
      doc.text(result.course_name, 148.5, 140, { align: 'center' });
      doc.setFontSize(16);
      doc.setFont('helvetica', 'normal');
      doc.text(`with a perfect score of ${result.score}/${result.total_questions}`, 148.5, 155, { align: 'center' });

      // Add date from quiz completion
      const dateStr = new Date(result.created_at).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      doc.setFontSize(14);
      doc.text(`Awarded on ${dateStr}`, 148.5, 170, { align: 'center' });

      // Add signature line and title
      doc.setDrawColor(100, 100, 100);
      doc.setLineWidth(0.5);
      doc.line(195, 165, 255, 165);
      doc.setFontSize(12);
      doc.setTextColor(100, 100, 100);
      doc.text('Harvey Donald', 225, 170, { align: 'center' });
      doc.setFontSize(10);
      doc.text('Director of Audium', 225, 175, { align: 'center' });

      // Add seal
      const centerX = 60;
      const centerY = 160;
      const radius = 15;
      doc.setDrawColor(29, 78, 216);
      doc.setLineWidth(0.5);
      doc.circle(centerX, centerY, radius);
      doc.circle(centerX, centerY, radius - 1);
      doc.circle(centerX, centerY, radius - 3);
      doc.setFontSize(8);
      doc.setTextColor(29, 78, 216);
      doc.text('AUDIUM', centerX, centerY - 2, { align: 'center' });
      doc.text('VERIFIED', centerX, centerY + 4, { align: 'center' });

      // Add website URL
      doc.setFontSize(10);
      doc.setTextColor(100, 100, 100);
      doc.text('https://audium.app', 148.5, 182, { align: 'center' });

      // Save PDF
      const filename = `${result.course_name.toLowerCase().replace(/\s+/g, '-')}-certificate.pdf`;
      doc.save(filename);

      // Refresh the list
      await loadQuizResults();

      toast({
        title: 'Success',
        description: 'Certificate generated successfully',
      });
    } catch (error) {
      console.error('Error generating certificate:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to generate certificate. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRetakeQuiz = (result: QuizResult) => {
    onBack();
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <button
        onClick={onBack}
        className="flex items-center gap-2 text-zinc-200 hover:text-white transition-colors mb-8 text-base"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Dashboard
      </button>

      <div className="bg-zinc-900 rounded-xl p-8 border border-zinc-800">
        <h1 className="text-2xl font-bold text-white mb-2">Your Certificates</h1>
        <p className="text-zinc-400 mb-8">
          Complete any course quiz with 100% accuracy to unlock and claim your certificate of achievement.
        </p>

        {quizResults.length === 0 ? (
          <p className="text-zinc-400">You haven't taken any quizzes yet.</p>
        ) : (
          <div className="space-y-6">
            {quizResults.map((result) => (
              <div
                key={result.quiz_id}
                className="flex flex-col p-6 bg-zinc-800/50 rounded-xl border border-zinc-700/50"
              >
                <div className="space-y-4">
                  <h3 className="text-lg font-medium text-white">
                    {result.course_name}
                  </h3>
                  
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4">
                      <div className="flex-1">
                        <div className="h-2 bg-zinc-700 rounded-full overflow-hidden">
                          <div 
                            className={`h-full transition-all duration-500 rounded-full ${
                              result.score < (result.total_questions / 2) ? 'bg-red-500' :
                              result.score < (result.total_questions * 0.8) ? 'bg-amber-500' :
                              'bg-green-500'
                            }`}
                            style={{ width: `${(result.score / result.total_questions) * 100}%` }}
                          />
                        </div>
                      </div>
                      <span className="text-sm text-zinc-400 whitespace-nowrap min-w-[80px] text-right">
                        {result.score}/{result.total_questions}
                      </span>
                    </div>

                    {result.has_certificate ? (
                      <Button
                        onClick={() => generateCertificate(result)}
                        disabled={isGenerating}
                        className="w-full bg-green-600 hover:bg-green-700 text-white rounded-xl"
                      >
                        <Download className="w-4 h-4 mr-2" />
                        {isGenerating ? 'Generating...' : 'Download Certificate'}
                      </Button>
                    ) : result.score === result.total_questions ? (
                      <Button
                        onClick={() => generateCertificate(result)}
                        disabled={isGenerating}
                        className="w-full bg-green-600 hover:bg-green-700 text-white rounded-xl"
                      >
                        <Download className="w-4 h-4 mr-2" />
                        {isGenerating ? 'Generating...' : 'Collect Certificate'}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleRetakeQuiz(result)}
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white rounded-xl"
                      >
                        <RotateCw className="w-4 h-4 mr-2" />
                        Retake Quiz
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}