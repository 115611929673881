import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Pencil, Plus, Save, GripVertical, ChevronDown, ChevronRight } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useToast } from '@/hooks/use-toast';

interface Module {
  id: string;
  title: string;
  content: string[];
  audio_urls: string[];
  display_order: number;
}

interface ModuleWithUI extends Module {
  isExpanded: boolean;
}

interface ModuleManagerProps {
  selectedSubject: string | null;
  selectedCourse: string | null;
}

export function ModuleManager({ selectedSubject, selectedCourse }: ModuleManagerProps) {
  const [modules, setModules] = useState<ModuleWithUI[]>([]);
  const [editingModule, setEditingModule] = useState<{
    id: string;
    field: 'title' | 'content' | 'audio_url';
    index?: number;
  } | null>(null);
  const [editValue, setEditValue] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showAddModuleDialog, setShowAddModuleDialog] = useState(false);
  const [showAddContentDialog, setShowAddContentDialog] = useState<string | null>(null);
  const [newModuleTitle, setNewModuleTitle] = useState('');
  const [newContent, setNewContent] = useState({
    title: '',
    audioUrl: ''
  });
  const { toast } = useToast();

  useEffect(() => {
    if (selectedSubject && selectedCourse) {
      fetchModules();
    }
  }, [selectedSubject, selectedCourse]);

  const fetchModules = async () => {
    try {
      const { data: subjectData } = await supabase
        .from('subjects')
        .select('id')
        .eq('name', selectedSubject)
        .single();

      if (!subjectData) {
        throw new Error('Subject not found');
      }

      const { data: courseData } = await supabase
        .from('courses')
        .select('id')
        .eq('name', selectedCourse)
        .eq('subject_id', subjectData.id)
        .single();

      if (!courseData) {
        throw new Error('Course not found');
      }

      const { data: modulesData, error: modulesError } = await supabase
        .from('modules')
        .select('*')
        .eq('course_id', courseData.id)
        .order('display_order');

      if (modulesError) throw modulesError;

      setModules((modulesData || []).map(m => ({ ...m, isExpanded: true })));
    } catch (error) {
      console.error('Error fetching modules:', error);
      toast({
        title: 'Error',
        description: 'Failed to load modules. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return;

    const items = Array.from(modules);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      display_order: index,
    }));

    setModules(updatedItems);
    setHasUnsavedChanges(true);
  };

  const handleLessonDragEnd = async (result: any, moduleId: string) => {
    if (!result.destination) return;

    const moduleIndex = modules.findIndex(m => m.id === moduleId);
    if (moduleIndex === -1) return;

    const newModules = [...modules];
    const module = { ...newModules[moduleIndex] };
    const content = [...module.content];
    const audioUrls = [...module.audio_urls];

    const [movedContent] = content.splice(result.source.index, 1);
    content.splice(result.destination.index, 0, movedContent);

    const [movedAudioUrl] = audioUrls.splice(result.source.index, 1);
    audioUrls.splice(result.destination.index, 0, movedAudioUrl);

    module.content = content;
    module.audio_urls = audioUrls;
    newModules[moduleIndex] = module;

    setModules(newModules);
    setHasUnsavedChanges(true);
  };

  const saveChanges = async () => {
    try {
      const updates = modules.map(({ id, display_order, content, audio_urls, title }) => ({
        id,
        display_order,
        content,
        audio_urls,
        title
      }));

      const { error } = await supabase
        .from('modules')
        .upsert(updates);

      if (error) throw error;

      setHasUnsavedChanges(false);
      toast({
        title: 'Success',
        description: 'Changes saved successfully',
        className: 'bg-zinc-900 border-zinc-800 text-white',
      });
    } catch (error) {
      console.error('Error saving changes:', error);
      toast({
        title: 'Error',
        description: 'Failed to save changes. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleAddModule = async () => {
    try {
      const { data: subjectData } = await supabase
        .from('subjects')
        .select('id')
        .eq('name', selectedSubject)
        .single();

      const { data: courseData } = await supabase
        .from('courses')
        .select('id')
        .eq('name', selectedCourse)
        .eq('subject_id', subjectData?.id)
        .single();

      const { data, error } = await supabase
        .from('modules')
        .insert([{
          course_id: courseData?.id,
          title: newModuleTitle,
          content: [],
          audio_urls: [],
          display_order: modules.length
        }])
        .select();

      if (error) throw error;

      if (data?.[0]) {
        setModules([...modules, { ...data[0], isExpanded: true }]);
        setShowAddModuleDialog(false);
        setNewModuleTitle('');

        toast({
          title: 'Success',
          description: 'Module added successfully',
          className: 'bg-zinc-900 border-zinc-800 text-white',
        });
      }
    } catch (error) {
      console.error('Error adding module:', error);
      toast({
        title: 'Error',
        description: 'Failed to add module. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleAddContent = async (moduleId: string) => {
    try {
      const moduleIndex = modules.findIndex(m => m.id === moduleId);
      if (moduleIndex === -1) return;

      const module = modules[moduleIndex];
      const updatedModule = {
        id: module.id,
        title: module.title,
        content: [...module.content, newContent.title],
        audio_urls: [...module.audio_urls, newContent.audioUrl],
        display_order: module.display_order
      };

      const { error } = await supabase
        .from('modules')
        .update(updatedModule)
        .eq('id', moduleId);

      if (error) throw error;

      const newModules = [...modules];
      newModules[moduleIndex] = { ...updatedModule, isExpanded: module.isExpanded };
      setModules(newModules);
      setShowAddContentDialog(null);
      setNewContent({ title: '', audioUrl: '' });

      toast({
        title: 'Success',
        description: 'Content added successfully',
        className: 'bg-zinc-900 border-zinc-800 text-white',
      });
    } catch (error) {
      console.error('Error adding content:', error);
      toast({
        title: 'Error',
        description: 'Failed to add content. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const toggleModuleExpansion = (moduleId: string) => {
    setModules(modules.map(m => 
      m.id === moduleId ? { ...m, isExpanded: !m.isExpanded } : m
    ));
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <Button 
          onClick={() => setShowAddModuleDialog(true)}
          className="bg-white hover:bg-zinc-100 text-zinc-900 rounded-xl"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Module
        </Button>

        {hasUnsavedChanges && (
          <Button 
            onClick={saveChanges}
            className="bg-white hover:bg-zinc-100 text-zinc-900 rounded-xl"
          >
            <Save className="w-4 h-4 mr-2" />
            Save Changes
          </Button>
        )}
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="modules">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-6">
              {modules.map((module, moduleIndex) => (
                <Draggable key={module.id} draggableId={module.id} index={moduleIndex}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="bg-zinc-900 rounded-xl overflow-hidden"
                    >
                      <div className="p-6 border-b border-zinc-800">
                        <div className="flex items-center gap-4">
                          <div {...provided.dragHandleProps}>
                            <GripVertical className="w-5 h-5 text-zinc-600" />
                          </div>
                          <button
                            onClick={() => toggleModuleExpansion(module.id)}
                            className="flex items-center gap-2 flex-1"
                          >
                            {module.isExpanded ? (
                              <ChevronDown className="w-5 h-5 text-zinc-400" />
                            ) : (
                              <ChevronRight className="w-5 h-5 text-zinc-400" />
                            )}
                            <h3 className="text-lg font-semibold text-white">{module.title}</h3>
                          </button>
                          <div className="flex items-center gap-2">
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => {
                                setEditingModule({ id: module.id, field: 'title' });
                                setEditValue(module.title);
                              }}
                              className="text-white hover:text-white hover:bg-zinc-800 rounded-xl"
                            >
                              <Pencil className="w-4 h-4 mr-2" />
                              Edit Title
                            </Button>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => setShowAddContentDialog(module.id)}
                              className="text-white hover:text-white hover:bg-zinc-800 rounded-xl"
                            >
                              <Plus className="w-4 h-4 mr-2" />
                              Add Content
                            </Button>
                          </div>
                        </div>
                      </div>

                      {module.isExpanded && (
                        <div className="p-6">
                          <DragDropContext onDragEnd={(result) => handleLessonDragEnd(result, module.id)}>
                            <Droppable droppableId={`lessons-${module.id}`}>
                              {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-3">
                                  {module.content.map((content, contentIndex) => (
                                    <Draggable
                                      key={`${module.id}-${contentIndex}`}
                                      draggableId={`${module.id}-${contentIndex}`}
                                      index={contentIndex}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className="flex items-center gap-4 bg-zinc-800 p-4 rounded-xl"
                                        >
                                          <div {...provided.dragHandleProps}>
                                            <GripVertical className="w-5 h-5 text-zinc-600" />
                                          </div>
                                          <p className="text-zinc-300 flex-1">{content}</p>
                                          <div className="flex items-center gap-2">
                                            <Button
                                              variant="ghost"
                                              size="sm"
                                              onClick={() => {
                                                setEditingModule({ id: module.id, field: 'content', index: contentIndex });
                                                setEditValue(content);
                                              }}
                                              className="text-white hover:text-white hover:bg-zinc-700 rounded-xl"
                                            >
                                              <Pencil className="w-4 h-4 mr-2" />
                                              Edit Content
                                            </Button>
                                            <Button
                                              variant="ghost"
                                              size="sm"
                                              onClick={() => {
                                                setEditingModule({ id: module.id, field: 'audio_url', index: contentIndex });
                                                setEditValue(module.audio_urls[contentIndex]);
                                              }}
                                              className="text-white hover:text-white hover:bg-zinc-700 rounded-xl"
                                            >
                                              <Pencil className="w-4 h-4 mr-2" />
                                              Edit Audio URL
                                            </Button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Add Module Dialog */}
      <Dialog 
        open={showAddModuleDialog} 
        onOpenChange={setShowAddModuleDialog}
      >
        <DialogContent 
          className="bg-zinc-900 text-white border-zinc-800"
          aria-describedby="add-module-description"
        >
          <div id="add-module-description" className="sr-only">
            Add a new module to the course
          </div>
          <DialogHeader>
            <DialogTitle>Add New Module</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 pt-4">
            <div className="space-y-2">
              <label className="text-sm font-medium text-zinc-400">Module Title</label>
              <Input
                value={newModuleTitle}
                onChange={(e) => setNewModuleTitle(e.target.value)}
                className="bg-zinc-800 border-zinc-700 text-white"
                placeholder="Enter module title"
              />
            </div>
            <div className="flex justify-end gap-2 pt-4">
              <Button
                variant="ghost"
                onClick={() => setShowAddModuleDialog(false)}
                className="text-white hover:bg-zinc-800 rounded-xl"
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddModule}
                disabled={!newModuleTitle}
                className="bg-white hover:bg-zinc-100 text-zinc-900 rounded-xl"
              >
                Add Module
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Add Content Dialog */}
      <Dialog 
        open={showAddContentDialog !== null} 
        onOpenChange={() => setShowAddContentDialog(null)}
      >
        <DialogContent 
          className="bg-zinc-900 text-white border-zinc-800"
          aria-describedby="add-content-description"
        >
          <div id="add-content-description" className="sr-only">
            Add new content to the module
          </div>
          <DialogHeader>
            <DialogTitle>Add New Content</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 pt-4">
            <div className="space-y-2">
              <label className="text-sm font-medium text-zinc-400">Content Title</label>
              <Input
                value={newContent.title}
                onChange={(e) => setNewContent({ ...newContent, title: e.target.value })}
                className="bg-zinc-800 border-zinc-700 text-white"
                placeholder="Enter content title"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-zinc-400">Audio URL</label>
              <Input
                value={newContent.audioUrl}
                onChange={(e) => setNewContent({ ...newContent, audioUrl: e.target.value })}
                className="bg-zinc-800 border-zinc-700 text-white"
                placeholder="Enter audio URL"
              />
            </div>
            <div className="flex justify-end gap-2 pt-4">
              <Button
                variant="ghost"
                onClick={() => setShowAddContentDialog(null)}
                className="text-white hover:bg-zinc-800 rounded-xl"
              >
                Cancel
              </Button>
              <Button
                onClick={() => showAddContentDialog && handleAddContent(showAddContentDialog)}
                disabled={!newContent.title || !newContent.audioUrl}
                className="bg-white hover:bg-zinc-100 text-zinc-900 rounded-xl"
              >
                Add Content
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog 
        open={editingModule !== null} 
        onOpenChange={() => setEditingModule(null)}
      >
        <DialogContent 
          className="bg-zinc-900 text-white border-zinc-800"
          aria-describedby="edit-module-description"
        >
          <div id="edit-module-description" className="sr-only">
            Edit module content or settings
          </div>
          <DialogHeader>
            <DialogTitle>
              Edit {editingModule?.field === 'title' ? 'Module Title' : 
                    editingModule?.field === 'content' ? 'Content' : 'Audio URL'}
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4 pt-4">
            <Input
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              className="bg-zinc-800 border-zinc-700 text-white"
            />
            <div className="flex justify-end gap-2">
              <Button
                variant="ghost"
                onClick={() => setEditingModule(null)}
                className="text-white hover:bg-zinc-800 rounded-xl"
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  if (!editingModule) return;

                  try {
                    const moduleToUpdate = modules.find(m => m.id === editingModule.id);
                    if (!moduleToUpdate) return;

                    const { isExpanded, ...moduleData } = moduleToUpdate;
                    let updatedModule = { ...moduleData };

                    if (editingModule.field === 'title') {
                      updatedModule.title = editValue;
                    } else if (editingModule.field === 'content' && editingModule.index !== undefined) {
                      const newContent = [...moduleToUpdate.content];
                      newContent[editingModule.index] = editValue;
                      updatedModule.content = newContent;
                    } else if (editingModule.field === 'audio_url' && editingModule.index !== undefined) {
                      const newUrls = [...moduleToUpdate.audio_urls];
                      newUrls[editingModule.index] = editValue;
                      updatedModule.audio_urls = newUrls;
                    }

                    const { error } = await supabase
                      .from('modules')
                      .update(updatedModule)
                      .eq('id', editingModule.id);

                    if (error) throw error;

                    setModules(modules.map(m => 
                      m.id === editingModule.id 
                        ? { ...updatedModule, isExpanded: m.isExpanded }
                        : m
                    ));
                    setEditingModule(null);

                    toast({
                      title: 'Success',
                      description: 'Module updated successfully',
                      className: 'bg-zinc-900 border-zinc-800 text-white',
                    });
                  } catch (error) {
                    console.error('Error updating module:', error);
                    toast({
                      title: 'Error',
                      description: 'Failed to update module. Please try again.',
                      variant: 'destructive',
                    });
                  }
                }}
                className="bg-white hover:bg-zinc-100 text-zinc-900 rounded-xl"
              >
                Save
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}