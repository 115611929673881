import { useState, useEffect, useRef } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';

type SubscriptionStatus = 'Active' | 'Overdue/Past Due' | 'Cancelled' | 'Incomplete' | 'Unknown' | 'No customer' | null;

const CACHE_KEY = 'subscription_status';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

interface CachedStatus {
  status: SubscriptionStatus;
  timestamp: number;
}

export function useSubscriptionStatus() {
  const [status, setStatus] = useState<SubscriptionStatus>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasChecked, setHasChecked] = useState(false);
  const { profile } = useAuth();
  const checkingRef = useRef(false);

  const getCachedStatus = (): CachedStatus | null => {
    try {
      const cached = localStorage.getItem(CACHE_KEY);
      if (!cached) return null;
      
      const { status, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp <= CACHE_DURATION && status === 'Active') {
        console.log('📦 Using cached subscription status:', { status, age: (Date.now() - timestamp) / 1000 });
        return { status, timestamp };
      }
      return null;
    } catch (error) {
      console.error('Error reading cached status:', error);
      return null;
    }
  };

  const setCachedStatus = (status: SubscriptionStatus) => {
    try {
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        status,
        timestamp: Date.now()
      }));
    } catch (error) {
      console.error('Error caching status:', error);
    }
  };

  const checkSubscription = async () => {
    // Prevent multiple simultaneous checks
    if (checkingRef.current) {
      console.log('🔄 Subscription check already in progress, skipping...');
      return;
    }

    if (!profile?.email) {
      console.log('❌ No email available, skipping subscription check');
      setStatus('No customer');
      setIsLoading(false);
      setHasChecked(true);
      return;
    }

    try {
      checkingRef.current = true;
      setIsLoading(true);

      // First check if user is on whitelist
      const { data: whitelistData } = await supabase
        .from('whitelist_emails')
        .select('*')
        .eq('email', profile.email.toLowerCase())
        .single();

      if (whitelistData) {
        setStatus('Active');
        setIsLoading(false);
        setHasChecked(true);
        setCachedStatus('Active');
        return;
      }

      // First check cache for active subscriptions
      const cached = getCachedStatus();
      if (cached) {
        setStatus(cached.status);
        setIsLoading(false);
        setHasChecked(true);
        return;
      }

      console.log('🔍 STRIPE: Checking subscription status for', profile.email);
      
      const response = await fetch(
        `https://audiumpay.atrus.uk/subscription-status?email=${encodeURIComponent(profile.email)}`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Origin': window.location.origin
          },
          mode: 'cors'
        }
      );

      console.log('📥 STRIPE: Received response:', {
        status: response.status,
        ok: response.ok
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('✅ STRIPE: Parsed response data:', data);

      const newStatus = data.status as SubscriptionStatus;
      setStatus(newStatus);

      // Only cache if status is Active
      if (newStatus === 'Active') {
        setCachedStatus(newStatus);
      }

      console.log('🎯 STRIPE: Final subscription status:', {
        status: newStatus,
        needsSubscription: !newStatus || ['Overdue/Past Due', 'Cancelled', 'Incomplete', 'No customer'].includes(newStatus)
      });

    } catch (error) {
      console.error('❌ STRIPE: Error checking subscription:', error);
      setStatus('Unknown');
    } finally {
      setIsLoading(false);
      setHasChecked(true);
      checkingRef.current = false;
    }
  };

  // Initial check when component mounts or profile changes
  useEffect(() => {
    // Reset states when profile changes
    setStatus(null);
    setHasChecked(false);
    setIsLoading(true);
    checkingRef.current = false;

    if (profile?.email) {
      checkSubscription();
    } else {
      setIsLoading(false);
      setHasChecked(true);
    }
  }, [profile?.email]);

  // Periodic check every 5 minutes, but only if not Active
  useEffect(() => {
    if (!profile?.email || status === 'Active') return;

    const interval = setInterval(checkSubscription, CACHE_DURATION);
    return () => clearInterval(interval);
  }, [profile?.email, status]);

  const isActive = status === 'Active';
  const isOverdue = status === 'Overdue/Past Due';
  const needsSubscription = hasChecked && !isActive && !isLoading && status !== null && status !== 'Unknown';

  return {
    status,
    isLoading,
    isActive,
    isOverdue,
    needsSubscription,
    hasChecked
  };
}