import { useState, useEffect } from 'react';
import { Plus, Pencil, Trash2, Save, ExternalLink } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';

interface NewsMessage {
  id: string;
  message: string;
  link?: string | null;
  link_text?: string | null;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

interface EditingMessageData {
  message: string;
  link?: string;
  link_text?: string;
  is_active: boolean;
}

export function NewsManager() {
  const [messages, setMessages] = useState<NewsMessage[]>([]);
  const [editingMessage, setEditingMessage] = useState<string | null>(null);
  const [editingData, setEditingData] = useState<EditingMessageData | null>(null);
  const [newMessage, setNewMessage] = useState('');
  const [newLink, setNewLink] = useState('');
  const [newLinkText, setNewLinkText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    loadMessages();
  }, []);

  const loadMessages = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('news_messages')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setMessages(data || []);
    } catch (error) {
      console.error('Error loading messages:', error);
      toast({
        title: 'Error',
        description: 'Failed to load messages',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      setIsLoading(true);
      const { error } = await supabase
        .from('news_messages')
        .insert([
          {
            message: newMessage,
            link: newLink || null,
            link_text: newLinkText || null,
            is_active: true,
          },
        ]);

      if (error) throw error;

      toast({
        title: 'Message added',
        description: 'News message has been added successfully.',
      });

      setNewMessage('');
      setNewLink('');
      setNewLinkText('');
      await loadMessages();
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to add message',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateMessage = async (message: NewsMessage) => {
    if (!editingData) return;

    try {
      setIsLoading(true);
      const { error } = await supabase
        .from('news_messages')
        .update({
          message: editingData.message,
          link: editingData.link || null,
          link_text: editingData.link_text || null,
          is_active: editingData.is_active,
          updated_at: new Date().toISOString(),
        })
        .eq('id', message.id);

      if (error) throw error;

      toast({
        title: 'Message updated',
        description: 'News message has been updated successfully.',
      });

      setEditingMessage(null);
      setEditingData(null);
      await loadMessages();
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to update message',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteMessage = async (message: NewsMessage) => {
    try {
      setIsLoading(true);
      const { error } = await supabase
        .from('news_messages')
        .delete()
        .eq('id', message.id);

      if (error) throw error;

      toast({
        title: 'Message deleted',
        description: 'News message has been deleted successfully.',
      });

      await loadMessages();
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to delete message',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <Input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Enter news message..."
          className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500"
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            placeholder="Enter link URL (optional)..."
            className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500"
          />
          <Input
            value={newLinkText}
            onChange={(e) => setNewLinkText(e.target.value)}
            placeholder="Enter link text (optional)..."
            className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500"
          />
        </div>
        <Button
          onClick={handleAddMessage}
          disabled={isLoading || !newMessage.trim()}
          className="w-full bg-white hover:bg-zinc-100 text-zinc-900"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add News Message
        </Button>
      </div>

      <div className="space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className="p-6 bg-zinc-800/50 rounded-xl border border-zinc-700/50"
          >
            {editingMessage === message.id ? (
              <div className="space-y-4">
                <Input
                  value={editingData?.message}
                  onChange={(e) => setEditingData({ ...editingData!, message: e.target.value })}
                  className="bg-zinc-700 border-zinc-600 text-white"
                  placeholder="Message"
                />
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    value={editingData?.link || ''}
                    onChange={(e) => setEditingData({ ...editingData!, link: e.target.value })}
                    className="bg-zinc-700 border-zinc-600 text-white"
                    placeholder="Link URL (optional)"
                  />
                  <Input
                    value={editingData?.link_text || ''}
                    onChange={(e) => setEditingData({ ...editingData!, link_text: e.target.value })}
                    className="bg-zinc-700 border-zinc-600 text-white"
                    placeholder="Link text (optional)"
                  />
                </div>
                <div className="flex items-center gap-4 p-4 bg-zinc-900/50 rounded-xl border border-zinc-700">
                  <div className="flex items-center gap-2 flex-1">
                    <Label className="text-white font-medium">Active</Label>
                  </div>
                  <Switch
                    checked={editingData?.is_active}
                    onCheckedChange={(checked) => 
                      setEditingData({ ...editingData!, is_active: checked })
                    }
                    className="data-[state=checked]:bg-blue-600"
                  />
                </div>
                <div className="flex gap-2 justify-end">
                  <Button
                    onClick={() => {
                      setEditingMessage(null);
                      setEditingData(null);
                    }}
                    variant="outline"
                    className="bg-zinc-700 text-white hover:bg-zinc-600"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleUpdateMessage(message)}
                    disabled={isLoading}
                    className="bg-white text-zinc-900 hover:bg-zinc-100"
                  >
                    <Save className="w-4 h-4 mr-2" />
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between">
                <div className="space-y-1 flex-1">
                  <p className="text-white font-medium">{message.message}</p>
                  {message.link && (
                    <div className="flex items-center gap-2 text-sm text-blue-400">
                      <span>Link:</span>
                      <a
                        href={message.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-300 flex items-center gap-1"
                      >
                        {message.link}
                        <ExternalLink className="w-3 h-3" />
                      </a>
                      {message.link_text && (
                        <>
                          <span className="text-zinc-500">•</span>
                          <span className="text-zinc-400">
                            Linked text: "{message.link_text}"
                          </span>
                        </>
                      )}
                    </div>
                  )}
                  <div className="flex items-center gap-2">
                    <span className={`text-sm ${message.is_active ? 'text-green-400' : 'text-zinc-500'}`}>
                      {message.is_active ? 'Active' : 'Inactive'}
                    </span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <Button
                    onClick={() => {
                      setEditingMessage(message.id);
                      setEditingData({
                        message: message.message,
                        link: message.link || '',
                        link_text: message.link_text || '',
                        is_active: message.is_active,
                      });
                    }}
                    className="bg-white text-zinc-900 hover:bg-zinc-100"
                  >
                    <Pencil className="w-4 h-4" />
                  </Button>
                  <Button
                    onClick={() => handleDeleteMessage(message)}
                    className="bg-white text-zinc-900 hover:bg-zinc-100"
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}