import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useToast } from '@/hooks/use-toast';
import { ArrowLeft, AlertTriangle, Calendar, ExternalLink } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

const profileSchema = z.object({
  full_name: z.string().min(2, 'Full name must be at least 2 characters'),
  email: z.string().email('Invalid email address'),
});

const passwordSchema = z.object({
  current_password: z.string().min(1, 'Current password is required'),
  new_password: z.string().min(6, 'Password must be at least 6 characters'),
  confirm_password: z.string(),
}).refine((data) => data.new_password === data.confirm_password, {
  message: "Passwords don't match",
  path: ["confirm_password"],
});

type ProfileFormData = z.infer<typeof profileSchema>;
type PasswordFormData = z.infer<typeof passwordSchema>;

interface SettingsPageProps {
  onBack: () => void;
}

const CANCELLATION_REASONS = [
  "Too expensive",
  "Not using it enough",
  "Found a better alternative",
  "Technical issues",
  "Content quality not meeting expectations",
  "Other"
];

const USAGE_FREQUENCY = [
  "Never used it",
  "Used it once or twice",
  "Used it weekly",
  "Used it daily",
  "Used it but stopped recently"
];

const IMPROVEMENT_OPTIONS = [
  "More course variety",
  "Better audio quality",
  "More interactive features",
  "Lower price",
  "Better mobile experience",
  "Different teaching style"
];

export function SettingsPage({ onBack }: SettingsPageProps) {
  const { profile, user } = useAuth();
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showSurveyDialog, setShowSurveyDialog] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [surveyResponses, setSurveyResponses] = useState({
    primary_reason: '',
    usage_frequency: '',
    improvement_feedback: ''
  });
  const { toast } = useToast();

  const {
    register: registerProfile,
    handleSubmit: handleProfileSubmit,
    formState: { errors: profileErrors },
  } = useForm<ProfileFormData>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      full_name: profile?.full_name || '',
      email: profile?.email || '',
    },
  });

  const {
    register: registerPassword,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors },
    reset: resetPassword,
  } = useForm<PasswordFormData>({
    resolver: zodResolver(passwordSchema),
  });

  const handleProfileUpdate = async (data: ProfileFormData) => {
    try {
      setIsProfileLoading(true);

      const { error: profileError } = await supabase
        .from('profiles')
        .update({
          full_name: data.full_name,
          updated_at: new Date().toISOString(),
        })
        .eq('id', user?.id);

      if (profileError) throw profileError;

      if (data.email !== profile?.email) {
        const { error: emailError } = await supabase.auth.updateUser({
          email: data.email,
        });
        if (emailError) throw emailError;
      }

      toast({
        title: "Profile updated",
        description: "Your profile has been successfully updated.",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    } finally {
      setIsProfileLoading(false);
    }
  };

  const handlePasswordUpdate = async (data: PasswordFormData) => {
    try {
      setIsPasswordLoading(true);

      const { error: passwordError } = await supabase.auth.updateUser({
        password: data.new_password,
      });
      
      if (passwordError) throw passwordError;

      toast({
        title: "Password updated",
        description: "Your password has been successfully updated.",
      });

      resetPassword();
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    } finally {
      setIsPasswordLoading(false);
    }
  };

  const handleSurveySubmit = async () => {
    try {
      const { error } = await supabase
        .from('cancellation_surveys')
        .insert([{
          user_id: user?.id,
          ...surveyResponses,
          created_at: new Date().toISOString(),
        }]);

      if (error) throw error;

      setShowSurveyDialog(false);
      window.open('https://billing.stripe.com/p/login/fZe8xa6EYbIEbEQ6oo', '_blank');
    } catch (error) {
      console.error('Error saving survey:', error);
      toast({
        title: "Error",
        description: "Failed to save survey responses",
        variant: "destructive",
      });
    }
  };

  const handleNextStep = () => {
    setCurrentStep(prev => prev + 1);
  };

  const renderSurveyStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <p className="text-zinc-400 mb-4">
              We're sorry to see you go. What's your main reason for cancelling?
            </p>
            <RadioGroup
              value={surveyResponses.primary_reason}
              onValueChange={(value) => setSurveyResponses(prev => ({ ...prev, primary_reason: value }))}
              className="space-y-3"
            >
              {CANCELLATION_REASONS.map((reason) => (
                <div key={reason} className="flex items-center space-x-2">
                  <RadioGroupItem value={reason} id={reason} className="border-zinc-600" />
                  <Label htmlFor={reason} className="text-white cursor-pointer">
                    {reason}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </>
        );
      case 2:
        return (
          <>
            <p className="text-zinc-400 mb-4">
              How often did you use Audium?
            </p>
            <RadioGroup
              value={surveyResponses.usage_frequency}
              onValueChange={(value) => setSurveyResponses(prev => ({ ...prev, usage_frequency: value }))}
              className="space-y-3"
            >
              {USAGE_FREQUENCY.map((frequency) => (
                <div key={frequency} className="flex items-center space-x-2">
                  <RadioGroupItem value={frequency} id={frequency} className="border-zinc-600" />
                  <Label htmlFor={frequency} className="text-white cursor-pointer">
                    {frequency}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </>
        );
      case 3:
        return (
          <>
            <p className="text-zinc-400 mb-4">
              What would have made you stay?
            </p>
            <RadioGroup
              value={surveyResponses.improvement_feedback}
              onValueChange={(value) => setSurveyResponses(prev => ({ ...prev, improvement_feedback: value }))}
              className="space-y-3"
            >
              {IMPROVEMENT_OPTIONS.map((option) => (
                <div key={option} className="flex items-center space-x-2">
                  <RadioGroupItem value={option} id={option} className="border-zinc-600" />
                  <Label htmlFor={option} className="text-white cursor-pointer">
                    {option}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </>
        );
      default:
        return null;
    }
  };

  const canProceed = () => {
    switch (currentStep) {
      case 1:
        return !!surveyResponses.primary_reason;
      case 2:
        return !!surveyResponses.usage_frequency;
      case 3:
        return !!surveyResponses.improvement_feedback;
      default:
        return false;
    }
  };

  return (
    <div className="max-w-2xl mx-auto py-8 px-4">
      <button
        onClick={onBack}
        className="flex items-center gap-2 text-zinc-200 hover:text-white transition-colors mb-8 text-base"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Dashboard
      </button>

      <div className="bg-zinc-900 rounded-xl p-8 border border-zinc-800">
        <h1 className="text-3xl font-bold text-white mb-8">Account Settings</h1>

        <div className="space-y-8">
          {/* Profile Form */}
          <form onSubmit={handleProfileSubmit(handleProfileUpdate)} className="space-y-6">
            <div className="space-y-2">
              <Label htmlFor="full_name" className="text-zinc-200 text-base">Full Name</Label>
              <Input
                {...registerProfile('full_name')}
                id="full_name"
                placeholder="John Doe"
                className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500"
              />
              {profileErrors.full_name && (
                <p className="text-sm text-red-400">{profileErrors.full_name.message}</p>
              )}
            </div>

            <div className="space-y-2">
              <Label htmlFor="email" className="text-zinc-200 text-base">Email</Label>
              <Input
                {...registerProfile('email')}
                id="email"
                type="email"
                placeholder="you@example.com"
                className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500"
              />
              {profileErrors.email && (
                <p className="text-sm text-red-400">{profileErrors.email.message}</p>
              )}
            </div>

            <Button
              type="submit"
              disabled={isProfileLoading}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 text-base"
            >
              {isProfileLoading ? 'Saving Profile...' : 'Save Profile'}
            </Button>
          </form>

          {/* Password Form */}
          <form onSubmit={handlePasswordSubmit(handlePasswordUpdate)} className="border-t border-zinc-800 pt-8">
            <h2 className="text-xl font-semibold text-white mb-6">Change Password</h2>
            
            <div className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="current_password" className="text-zinc-200 text-base">Current Password</Label>
                <Input
                  {...registerPassword('current_password')}
                  id="current_password"
                  type="password"
                  placeholder="••••••••"
                  className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500"
                />
                {passwordErrors.current_password && (
                  <p className="text-sm text-red-400">{passwordErrors.current_password.message}</p>
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="new_password" className="text-zinc-200 text-base">New Password</Label>
                <Input
                  {...registerPassword('new_password')}
                  id="new_password"
                  type="password"
                  placeholder="••••••••"
                  className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500"
                />
                {passwordErrors.new_password && (
                  <p className="text-sm text-red-400">{passwordErrors.new_password.message}</p>
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="confirm_password" className="text-zinc-200 text-base">Confirm New Password</Label>
                <Input
                  {...registerPassword('confirm_password')}
                  id="confirm_password"
                  type="password"
                  placeholder="••••••••"
                  className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500"
                />
                {passwordErrors.confirm_password && (
                  <p className="text-sm text-red-400">{passwordErrors.confirm_password.message}</p>
                )}
              </div>

              <Button
                type="submit"
                disabled={isPasswordLoading}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 text-base"
              >
                {isPasswordLoading ? 'Updating Password...' : 'Update Password'}
              </Button>
            </div>
          </form>

          {/* Billing Section */}
          <div className="border-t border-zinc-800 pt-8">
            <h2 className="text-xl font-semibold text-white mb-6">Billing</h2>
            <Button
              onClick={() => setShowCancelDialog(true)}
              className="w-full bg-red-600 hover:bg-red-700 text-white py-2 text-base"
            >
              <AlertTriangle className="w-4 h-4 mr-2" />
              Cancel Subscription
            </Button>
          </div>
        </div>
      </div>

      {/* Cancellation Warning Dialog */}
      <Dialog open={showCancelDialog} onOpenChange={setShowCancelDialog}>
        <DialogContent className="bg-zinc-900 border-zinc-800 text-white max-w-xl">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold mb-4">
              Are you sure you want to cancel?
            </DialogTitle>
          </DialogHeader>

          <div className="space-y-6">
            <div className="bg-red-950/30 border border-red-900/50 rounded-lg p-4">
              <h3 className="font-medium text-red-400 mb-2">You'll lose access to:</h3>
              <ul className="space-y-2 text-red-300">
                <li className="flex items-center gap-2">
                  • Access to all courses and content.
                </li>
                <li className="flex items-center gap-2">
                  • All your earned certificates.
                </li>
                <li className="flex items-center gap-2">
                  • Your quiz results and progress.
                </li>
                <li className="flex items-center gap-2">
                  • Your current rank and benefits.
                </li>
              </ul>
            </div>

            <div className="bg-zinc-800 rounded-lg p-4">
              <h3 className="font-medium text-white mb-2">Need help getting more value?</h3>
              <p className="text-zinc-400 mb-4">
                Book a free call with our student success team to help you get the most out of your membership.
              </p>
              <Button
                onClick={() => window.open('https://calendly.com/abundantagency/audium-student-success-call', '_blank')}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white"
              >
                <Calendar className="w-4 h-4 mr-2" />
                Book Student Success Call
              </Button>
            </div>

            <div className="flex justify-end gap-3">
              <Button
                onClick={() => setShowCancelDialog(false)}
                variant="outline"
                className="bg-zinc-800 hover:bg-zinc-700 text-white border-zinc-700"
              >
                Keep My Subscription
              </Button>
              <Button
                onClick={() => {
                  setShowCancelDialog(false);
                  setShowSurveyDialog(true);
                }}
                className="bg-red-600 hover:bg-red-700 text-white"
              >
                Continue to Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Cancellation Survey Dialog */}
      <Dialog open={showSurveyDialog} onOpenChange={setShowSurveyDialog}>
        <DialogContent className="bg-zinc-900 border-zinc-800 text-white max-w-xl">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold mb-4">
              Help us improve
            </DialogTitle>
          </DialogHeader>

          <div className="space-y-6">
            {renderSurveyStep()}

            <div className="space-y-4">
              {currentStep === 3 ? (
                <Button
                  onClick={handleSurveySubmit}
                  disabled={!canProceed()}
                  className="w-full bg-red-600 hover:bg-red-700 text-white"
                >
                  <ExternalLink className="w-4 h-4 mr-2" />
                  Continue to Billing Portal
                </Button>
              ) : (
                <Button
                  onClick={handleNextStep}
                  disabled={!canProceed()}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                >
                  Next
                </Button>
              )}
              {currentStep === 3 && (
                <p className="text-sm text-zinc-400 text-center">
                  Note: You'll need to confirm cancellation in the billing portal
                </p>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}