const RANK_CACHE_KEY = 'rank_status';

export function clearRankCache() {
  try {
    localStorage.removeItem(RANK_CACHE_KEY);
    console.log('🧹 Rank cache cleared');
  } catch (error) {
    console.warn('⚠️ Error clearing rank cache:', error);
  }
}

export function getRankCache() {
  try {
    const cached = localStorage.getItem(RANK_CACHE_KEY);
    return cached ? JSON.parse(cached) : null;
  } catch (error) {
    console.warn('⚠️ Error reading rank cache:', error);
    return null;
  }
}

export function setRankCache(data: any) {
  try {
    localStorage.setItem(RANK_CACHE_KEY, JSON.stringify(data));
  } catch (error) {
    console.warn('⚠️ Error setting rank cache:', error);
  }
}