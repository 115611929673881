import { useState, useRef, useEffect } from 'react';
import { Upload, Download, Save, Plus, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { saveQuiz, fetchQuiz } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import Papa from 'papaparse';
import { supabase } from '@/lib/supabase';

interface QuizManagerProps {
  selectedSubject: string | null;
  selectedCourse: string | null;
}

interface QuizQuestion {
  question: string;
  correctAnswer: string;
  wrongAnswer1: string;
  wrongAnswer2: string;
  wrongAnswer3: string;
}

export function QuizManager({ selectedSubject, selectedCourse }: QuizManagerProps) {
  const [questions, setQuestions] = useState<QuizQuestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();

  useEffect(() => {
    if (selectedSubject && selectedCourse) {
      loadExistingQuiz();
    }
  }, [selectedSubject, selectedCourse]);

  const loadExistingQuiz = async () => {
    try {
      setIsLoading(true);
      const quiz = await fetchQuiz(selectedSubject!, selectedCourse!);
      
      if (quiz?.quiz_questions) {
        const loadedQuestions = quiz.quiz_questions.map(q => ({
          question: q.question,
          correctAnswer: q.correct_answer,
          wrongAnswer1: q.wrong_answer_1,
          wrongAnswer2: q.wrong_answer_2,
          wrongAnswer3: q.wrong_answer_3,
        })).sort((a, b) => a.question.localeCompare(b.question));

        setQuestions(loadedQuestions);
      }
    } catch (error) {
      console.error('Error loading quiz:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteQuiz = async () => {
    if (!selectedSubject || !selectedCourse) return;

    try {
      setIsLoading(true);

      // Get subject ID
      const { data: subject } = await supabase
        .from('subjects')
        .select('id')
        .eq('name', selectedSubject)
        .single();

      if (!subject) throw new Error('Subject not found');

      // Get course ID
      const { data: course } = await supabase
        .from('courses')
        .select('id')
        .eq('name', selectedCourse)
        .eq('subject_id', subject.id)
        .single();

      if (!course) throw new Error('Course not found');

      // Delete quiz
      const { error } = await supabase
        .from('quizzes')
        .delete()
        .eq('course_id', course.id);

      if (error) throw error;

      setQuestions([]);
      toast({
        title: 'Success',
        description: 'Quiz deleted successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to delete quiz',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadTemplate = () => {
    const headers = ['question', 'correctAnswer', 'wrongAnswer1', 'wrongAnswer2', 'wrongAnswer3'];
    const csvContent = [
      headers.join(','),
      'What is the capital of France?,Paris,London,Berlin,Madrid',
      'Who painted the Mona Lisa?,Leonardo da Vinci,Pablo Picasso,Vincent van Gogh,Claude Monet'
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'quiz_template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        try {
          if (results.errors.length > 0) {
            throw new Error('Error parsing CSV file');
          }

          const headers = Object.keys(results.data[0]);
          if (!validateHeaders(headers)) {
            throw new Error('Invalid CSV format. Please use the template.');
          }

          const parsedQuestions = results.data.map((row: any) => ({
            question: row.question?.trim() || '',
            correctAnswer: row.correctAnswer?.trim() || '',
            wrongAnswer1: row.wrongAnswer1?.trim() || '',
            wrongAnswer2: row.wrongAnswer2?.trim() || '',
            wrongAnswer3: row.wrongAnswer3?.trim() || '',
          })).filter(q => 
            q.question && 
            q.correctAnswer && 
            q.wrongAnswer1 && 
            q.wrongAnswer2 && 
            q.wrongAnswer3
          );

          if (parsedQuestions.length === 0) {
            throw new Error('No valid questions found in CSV');
          }

          setQuestions(parsedQuestions);
          toast({
            title: 'Success',
            description: `${parsedQuestions.length} questions loaded from CSV`,
          });
        } catch (error) {
          toast({
            title: 'Error',
            description: error instanceof Error ? error.message : 'Failed to parse CSV',
            variant: 'destructive',
          });
        }
      },
      error: (error) => {
        toast({
          title: 'Error',
          description: 'Failed to read CSV file',
          variant: 'destructive',
        });
      }
    });
  };

  const validateHeaders = (headers: string[]) => {
    const requiredHeaders = ['question', 'correctAnswer', 'wrongAnswer1', 'wrongAnswer2', 'wrongAnswer3'];
    return requiredHeaders.every(header => 
      headers.map(h => h.trim().toLowerCase()).includes(header.toLowerCase())
    );
  };

  const handleSaveQuiz = async () => {
    if (!selectedSubject || !selectedCourse) {
      toast({
        title: 'Error',
        description: 'Please select a subject and course first',
        variant: 'destructive',
      });
      return;
    }

    if (questions.length === 0) {
      toast({
        title: 'Error',
        description: 'Please add some questions first',
        variant: 'destructive',
      });
      return;
    }

    try {
      setIsLoading(true);
      await saveQuiz(selectedSubject, selectedCourse, questions);
      toast({
        title: 'Success',
        description: 'Quiz saved successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to save quiz',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        question: '',
        correctAnswer: '',
        wrongAnswer1: '',
        wrongAnswer2: '',
        wrongAnswer3: '',
      }
    ]);
  };

  const removeQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const updateQuestion = (index: number, field: keyof QuizQuestion, value: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      [field]: value,
    };
    setQuestions(updatedQuestions);
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-wrap gap-4">
        <Button
          onClick={downloadTemplate}
          className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
        >
          <Download className="w-4 h-4 mr-2" />
          Download Template
        </Button>

        <Button
          onClick={() => fileInputRef.current?.click()}
          className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
        >
          <Upload className="w-4 h-4 mr-2" />
          Upload CSV
        </Button>

        <input
          ref={fileInputRef}
          type="file"
          accept=".csv"
          className="hidden"
          onChange={handleFileUpload}
        />

        <Button
          onClick={addQuestion}
          className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Question
        </Button>

        {questions.length > 0 && (
          <Button
            onClick={handleSaveQuiz}
            disabled={isLoading}
            className="bg-white text-zinc-900 hover:bg-zinc-100 rounded-xl"
          >
            <Save className="w-4 h-4 mr-2" />
            Save Quiz
          </Button>
        )}

        <Button
          onClick={deleteQuiz}
          disabled={isLoading}
          className="bg-red-600 hover:bg-red-700 text-white rounded-xl ml-auto"
        >
          <Trash2 className="w-4 h-4 mr-2" />
          Delete Quiz
        </Button>
      </div>

      <div className="space-y-6">
        {questions.map((question, index) => (
          <div 
            key={index}
            className="p-6 bg-zinc-800/50 rounded-xl border border-zinc-700/50"
          >
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-white">Question {index + 1}</h3>
              <Button
                onClick={() => removeQuestion(index)}
                variant="ghost"
                size="sm"
                className="text-red-400 hover:text-red-300 hover:bg-red-950/30"
              >
                <Trash2 className="w-4 h-4" />
              </Button>
            </div>

            <div className="space-y-4">
              <div className="space-y-2">
                <Label className="text-white">Question</Label>
                <Input
                  value={question.question}
                  onChange={(e) => updateQuestion(index, 'question', e.target.value)}
                  className="bg-zinc-700 border-zinc-600 text-white"
                  placeholder="Enter question"
                />
              </div>

              <div className="space-y-2">
                <Label className="text-green-400">Correct Answer</Label>
                <Input
                  value={question.correctAnswer}
                  onChange={(e) => updateQuestion(index, 'correctAnswer', e.target.value)}
                  className="bg-zinc-700 border-zinc-600 text-white"
                  placeholder="Enter correct answer"
                />
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div className="space-y-2">
                  <Label className="text-red-400">Wrong Answer 1</Label>
                  <Input
                    value={question.wrongAnswer1}
                    onChange={(e) => updateQuestion(index, 'wrongAnswer1', e.target.value)}
                    className="bg-zinc-700 border-zinc-600 text-white"
                    placeholder="Enter wrong answer"
                  />
                </div>

                <div className="space-y-2">
                  <Label className="text-red-400">Wrong Answer 2</Label>
                  <Input
                    value={question.wrongAnswer2}
                    onChange={(e) => updateQuestion(index, 'wrongAnswer2', e.target.value)}
                    className="bg-zinc-700 border-zinc-600 text-white"
                    placeholder="Enter wrong answer"
                  />
                </div>

                <div className="space-y-2">
                  <Label className="text-red-400">Wrong Answer 3</Label>
                  <Input
                    value={question.wrongAnswer3}
                    onChange={(e) => updateQuestion(index, 'wrongAnswer3', e.target.value)}
                    className="bg-zinc-700 border-zinc-600 text-white"
                    placeholder="Enter wrong answer"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}