import { useAuth } from '@/contexts/AuthContext';
import { Dashboard } from './Dashboard';
import { AuthPage } from './auth/AuthPage';
import { useEffect, useState, useRef } from 'react';
import { forceLogout } from '@/lib/auth';

export function AppContent() {
  const { user, isLoading: authLoading } = useAuth();
  const [showForceLogout, setShowForceLogout] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const logoutTimerRef = useRef<NodeJS.Timeout>();
  const redirectTimerRef = useRef<NodeJS.Timeout>();
  const finalRedirectRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // Clear any existing timers when component mounts or auth state changes
    return () => {
      [logoutTimerRef, redirectTimerRef, finalRedirectRef].forEach(ref => {
        if (ref.current) {
          clearTimeout(ref.current);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (authLoading) {
      // Set timer for auto-logout after 8 seconds
      logoutTimerRef.current = setTimeout(async () => {
        setShowForceLogout(true);
        await handleForceLogout();
      }, 8000);

      return () => {
        if (logoutTimerRef.current) {
          clearTimeout(logoutTimerRef.current);
        }
      };
    }
  }, [authLoading]);

  const handleForceLogout = async () => {
    if (isLoggingOut) return; // Prevent multiple logout attempts

    try {
      setIsLoggingOut(true);
      
      // Set multiple backup redirect timers
      redirectTimerRef.current = setTimeout(() => {
        window.location.replace(`${window.location.origin}?forceLogout=true`);
      }, 1000);

      finalRedirectRef.current = setTimeout(() => {
        window.location.href = `${window.location.origin}?forceLogout=true`;
      }, 2000);
      
      // Clear all caches and force logout
      await forceLogout();
    } catch (error) {
      console.error('Force logout failed:', error);
      // Force redirect even if logout fails
      window.location.replace(`${window.location.origin}?forceLogout=true`);
    }
  };

  if (authLoading) {
    return (
      <div className="min-h-screen bg-zinc-950 flex flex-col items-center justify-center gap-4">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        {showForceLogout && !isLoggingOut && (
          <button
            onClick={handleForceLogout}
            className="text-red-400 hover:text-red-300 text-sm transition-colors underline"
          >
            Force logout and clear data
          </button>
        )}
        {isLoggingOut && (
          <div className="flex flex-col items-center gap-2">
            <p className="text-zinc-400 text-sm">Logging out...</p>
            <div className="text-zinc-500 text-xs animate-pulse">
              Redirecting to login page...
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-zinc-950">
      {user ? <Dashboard /> : <AuthPage />}
    </div>
  );
}