import { useState, useEffect } from 'react';
import { Lock, Rocket } from 'lucide-react';
import { fetchSubjects } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import { getCachedSubjects, setCachedSubjects } from '@/lib/cache';
import type { Subject } from '@/data/types';
import { icons } from '@/components/admin/icons';
import { formatDistanceToNow } from 'date-fns';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';
import { useRankPermissions } from '@/hooks/useRankPermissions';
import { Button } from './ui/button';

interface SubjectGridProps {
  onSelect: (name: string) => void;
  onRanksClick?: () => void;
}

export function SubjectGrid({ onSelect, onRanksClick }: SubjectGridProps) {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadAttempts, setLoadAttempts] = useState(0);
  const [activeCategory, setActiveCategory] = useState<string>('Academic');
  const { toast } = useToast();
  const { canAccessCategory } = useRankPermissions();
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000;

  useEffect(() => {
    loadSubjects();
  }, []);

  const loadSubjects = async () => {
    try {
      setIsLoading(true);
      const cached = getCachedSubjects();
      if (cached) {
        setSubjects(cached);
        setIsLoading(false);
        if (cached.length > 0) {
          setActiveCategory('Academic');
        }
        fetchAndCacheSubjects().catch(console.error);
        return;
      }

      await fetchAndCacheSubjects();
    } catch (error) {
      console.error('Error loading subjects:', error);
      
      if (loadAttempts < MAX_RETRIES) {
        setTimeout(() => {
          setLoadAttempts(prev => prev + 1);
          loadSubjects();
        }, RETRY_DELAY * (loadAttempts + 1));
      } else {
        toast({
          title: 'Error',
          description: 'Failed to load subjects. Please try refreshing the page.',
          variant: 'destructive',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAndCacheSubjects = async () => {
    const data = await fetchSubjects();
    setSubjects(data);
    setCachedSubjects(data);
    setActiveCategory('Academic');
    return data;
  };

  const getSubjectStatus = (subject: Subject) => {
    if (!subject.is_locked) return 'available';
    if (!subject.release_date) return 'locked';
    
    const releaseDate = new Date(subject.release_date);
    if (releaseDate <= new Date()) return 'available';
    return 'countdown';
  };

  const getCountdown = (releaseDate: string) => {
    return formatDistanceToNow(new Date(releaseDate), { addSuffix: true });
  };

  const getRankRequiredMessage = (category: string) => {
    switch (category) {
      case 'Lifestyle':
        return 'Rank up to Learner to unlock Lifestyle programs!';
      case 'Entertainment':
        return 'Rank up to Explorer to unlock Entertainment programs!';
      default:
        return 'Rank up to unlock access to this category!';
    }
  };

  const handleSubjectClick = (subject: Subject) => {
    if (!canAccessCategory(subject.category)) {
      toast({
        title: 'Rank Required',
        description: getRankRequiredMessage(subject.category),
        action: (
          <Button 
            onClick={onRanksClick} 
            variant="outline" 
            className="bg-white text-zinc-900 hover:bg-zinc-100"
          >
            View Ranks
          </Button>
        ),
      });
      return;
    }
    onSelect(subject.name);
  };

  if (isLoading) {
    return (
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
        {[...Array(4)].map((_, i) => (
          <div 
            key={i}
            className="relative aspect-[2/3] rounded-2xl overflow-hidden bg-zinc-800/50 animate-pulse"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-white/[0.075] to-transparent" />
            <div className="relative h-full flex flex-col items-center justify-center gap-4">
              <div className="w-12 h-12 rounded-xl bg-zinc-700/50" />
              <div className="w-32 h-5 rounded-md bg-zinc-700/50" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <Tabs value={activeCategory} className="w-full relative" onValueChange={setActiveCategory}>
        <TabsList className="bg-zinc-900 border border-zinc-800 w-full flex rounded-full p-1 h-auto min-h-[40px] sm:min-h-12 relative">
          <div
            className={cn(
              "absolute h-[calc(100%-8px)] top-1 rounded-full bg-blue-600 transition-all duration-300 ease-out",
              activeCategory === 'Academic' && "left-1 w-[calc(25%-3px)]",
              activeCategory === 'Specialised' && "left-[calc(25%+1px)] w-[calc(25%-2px)]",
              activeCategory === 'Lifestyle' && "left-[calc(50%+1px)] w-[calc(25%-2px)]",
              activeCategory === 'Entertainment' && "left-[calc(75%+1px)] w-[calc(25%-2px)]"
            )}
          />
          <TabsTrigger 
            value="Academic"
            className="flex-1 rounded-full px-2 sm:px-8 py-1 sm:py-2 text-xs sm:text-base text-white relative z-10 transition-colors data-[state=active]:text-white data-[state=active]:shadow-none data-[state=inactive]:text-zinc-400"
          >
            Academic
          </TabsTrigger>
          <TabsTrigger 
            value="Specialised"
            className="flex-1 rounded-full px-2 sm:px-8 py-1 sm:py-2 text-xs sm:text-base text-white relative z-10 transition-colors data-[state=active]:text-white data-[state=active]:shadow-none data-[state=inactive]:text-zinc-400"
          >
            Specialised
          </TabsTrigger>
          <TabsTrigger 
            value="Lifestyle"
            className="flex-1 rounded-full px-2 sm:px-8 py-1 sm:py-2 text-xs sm:text-base text-white relative z-10 transition-colors data-[state=active]:text-white data-[state=active]:shadow-none data-[state=inactive]:text-zinc-400"
          >
            Lifestyle
          </TabsTrigger>
          <TabsTrigger 
            value="Entertainment"
            className="flex-1 rounded-full px-2 sm:px-8 py-1 sm:py-2 text-xs sm:text-base text-white relative z-10 transition-colors data-[state=active]:text-white data-[state=active]:shadow-none data-[state=inactive]:text-zinc-400"
          >
            Entertainment
          </TabsTrigger>
        </TabsList>

        <TabsContent value={activeCategory} className="mt-6">
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
            {subjects
              .filter(subject => subject.category === activeCategory)
              .map((subject) => {
                const status = getSubjectStatus(subject);
                const hexColor = subject.color;
                const canAccess = canAccessCategory(subject.category);

                return (
                  <div
                    key={subject.id}
                    className={`
                      relative aspect-[2/3] rounded-2xl overflow-hidden transform transition-all duration-300 
                      ${status === 'available' && canAccess ? 'cursor-pointer hover:scale-[1.02] hover:shadow-xl hover:shadow-black/20' : 'cursor-not-allowed'}
                    `}
                    onClick={() => handleSubjectClick(subject)}
                  >
                    {/* Background Image */}
                    <div 
                      className="absolute inset-0 bg-cover bg-center"
                      style={{ backgroundImage: `url(${subject.bg_image})` }}
                    />

                    {/* Color Overlay */}
                    <div 
                      className="absolute inset-0"
                      style={{
                        background: `linear-gradient(to top, ${hexColor}dd, ${hexColor}aa, ${hexColor}22)`
                      }}
                    />

                    {/* Content */}
                    <div className="relative z-10 h-full flex flex-col">
                      <div className="absolute inset-0 flex flex-col items-center justify-center p-4 sm:p-6">
                        <div className="bg-black/20 backdrop-blur-md rounded-2xl p-3 sm:p-5">
                          {(() => {
                            const IconComponent = icons.find(i => i.name === subject.icon)?.icon;
                            return IconComponent ? (
                              <IconComponent className="w-7 h-7 sm:w-10 sm:h-10 text-white" />
                            ) : null;
                          })()}
                        </div>
                        <div className="mt-3 sm:mt-4 bg-black/30 backdrop-blur-sm rounded-xl px-3 sm:px-4 py-1.5 sm:py-2">
                          <h3 className="text-sm sm:text-base font-medium text-white tracking-wide text-center">
                            {subject.name}
                          </h3>
                        </div>
                      </div>

                      {(!canAccess || status === 'locked' || status === 'countdown') && (
                        <div className="absolute bottom-4 sm:bottom-6 left-0 right-0 flex justify-center px-4 sm:px-6">
                          <div className="bg-black/40 backdrop-blur-sm rounded-xl px-3 sm:px-4 py-1.5 sm:py-2">
                            {!canAccess ? (
                              <div className="flex items-center gap-1.5 sm:gap-2">
                                <Lock className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-white" />
                                <span className="text-xs sm:text-sm text-white font-medium">Rank Required</span>
                              </div>
                            ) : status === 'locked' ? (
                              <div className="flex items-center gap-1.5 sm:gap-2">
                                <Lock className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-white" />
                                <span className="text-xs sm:text-sm text-white font-medium">Coming Soon</span>
                              </div>
                            ) : (
                              <div className="flex items-center gap-1.5 sm:gap-2">
                                <Rocket className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-white" />
                                <span className="text-xs sm:text-sm text-white font-medium">
                                  Launching {getCountdown(subject.release_date!)}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}