import { useState, useEffect } from 'react';
import { Brain, Volume2, PenSquare } from 'lucide-react';
import { fetchModules as fetchModulesApi } from '@/lib/supabase';
import { Button } from './ui/button';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';

interface Module {
  id: string;
  title: string;
  content: string[];
  audio_urls: string[];
  display_order: number;
}

interface ModuleListProps {
  subject: string;
  course: string;
  currentModuleIndex: number;
  currentAudioIndex: number;
  onPlay: (moduleIndex: number, audioIndex: number) => void;
  onAITutor: (topic: string) => void;
  onQuiz: () => void;
  courseName: string;
}

export function ModuleList({
  subject,
  course,
  currentModuleIndex,
  currentAudioIndex,
  onPlay,
  onAITutor,
  onQuiz,
  courseName,
}: ModuleListProps) {
  const [modules, setModules] = useState<Module[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [previousScore, setPreviousScore] = useState<{ score: number; total: number } | null>(null);
  const [hasQuiz, setHasQuiz] = useState(false);
  const [isEntertainment, setIsEntertainment] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (subject && course) {
      fetchModules();
      checkCategory();
    }
  }, [subject, course]);

  const checkCategory = async () => {
    try {
      const { data: subjectData } = await supabase
        .from('subjects')
        .select('category')
        .eq('name', subject)
        .single();

      setIsEntertainment(subjectData?.category === 'Entertainment');
    } catch (error) {
      console.error('Error checking subject category:', error);
    }
  };

  const fetchModules = async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch modules
      const moduleData = await fetchModulesApi(subject, course);
      if (!moduleData) throw new Error('No modules found');
      
      setModules(moduleData);

      // Check if quiz exists and fetch previous score if user is logged in
      if (user) {
        const { data: quizData, error: quizError } = await supabase.rpc('get_quiz_with_previous_score', {
          p_subject_name: subject,
          p_course_name: course,
          p_user_id: user.id
        });

        // Set hasQuiz based on whether there are any questions
        setHasQuiz(!!quizData?.questions?.length);

        if (!quizError && quizData?.previous_score !== null && quizData?.questions) {
          setPreviousScore({
            score: quizData.previous_score,
            total: quizData.questions.length
          });
        }
      }
    } catch (error) {
      console.error('Error loading modules:', error);
      setError('Failed to load modules. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePlayClick = (moduleIndex: number, audioIndex: number) => {
    console.log('Listen button clicked:', {
      subject,
      course,
      moduleTitle: modules[moduleIndex]?.title,
      contentTitle: modules[moduleIndex]?.content[audioIndex],
      moduleIndex,
      audioIndex,
      timestamp: new Date().toISOString()
    });
    
    onPlay(moduleIndex, audioIndex);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 bg-red-100/10 rounded-xl p-4">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-8 pb-32">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
        <h2 className="text-2xl font-bold text-white">{courseName}</h2>
        <div className="flex items-center gap-4">
          {previousScore && (
            <div className="text-sm text-zinc-400">
              Previous Quiz Score: <span className="text-white font-medium">{previousScore.score}/{previousScore.total}</span>
            </div>
          )}
          {hasQuiz && (
            <Button
              onClick={onQuiz}
              className="bg-purple-600 hover:bg-purple-700 text-white rounded-xl flex items-center gap-2"
            >
              <PenSquare className="w-4 h-4" />
              {previousScore ? 'Retake Quiz' : 'Take Quiz'}
            </Button>
          )}
        </div>
      </div>

      {modules.map((module, moduleIndex) => (
        <div key={module.id} className="text-left">
          <h3 className="text-xl font-semibold text-white mb-4">{module.title}</h3>
          {module.content.map((item, itemIndex) => {
            const isCurrentlyPlaying = 
              moduleIndex === currentModuleIndex && 
              itemIndex === currentAudioIndex;
            
            return (
              <div 
                key={`${moduleIndex}-${itemIndex}`}
                className="flex justify-between items-center gap-4 text-zinc-300 bg-zinc-900/70 rounded-xl p-4 mb-3 border border-zinc-800"
              >
                <span className="flex-1 text-sm sm:text-base line-clamp-2 sm:line-clamp-none">{item}</span>
                <div className="flex gap-2 shrink-0">
                  {!isEntertainment && (
                    <button
                      onClick={() => onAITutor(item)}
                      className="sm:px-4 sm:py-2 p-2 text-sm rounded-xl transition-colors whitespace-nowrap bg-purple-600/20 text-purple-400 hover:bg-purple-600/30 border border-purple-500/30 flex items-center gap-2"
                      title="AI Tutor"
                    >
                      <Brain className="w-4 h-4" />
                      <span className="hidden sm:inline">AI Tutor</span>
                    </button>
                  )}
                  {module.audio_urls && module.audio_urls[itemIndex] && (
                    <button
                      onClick={() => handlePlayClick(moduleIndex, itemIndex)}
                      className={`sm:px-4 sm:py-2 p-2 text-sm rounded-xl transition-colors whitespace-nowrap flex items-center gap-2 ${
                        isCurrentlyPlaying
                          ? 'bg-blue-600/20 text-blue-400 hover:bg-blue-600/30 border border-blue-500/30'
                          : 'text-white bg-blue-600 hover:bg-blue-700'
                      }`}
                      title={isCurrentlyPlaying ? 'Playing' : 'Listen'}
                    >
                      <Volume2 className="w-4 h-4" />
                      <span className="hidden sm:inline">
                        {isCurrentlyPlaying ? 'Playing' : 'Listen'}
                      </span>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}