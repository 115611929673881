import { Lock, GraduationCap, Rocket } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import type { Course } from '@/data/types';

interface CourseGridProps {
  courses: Course[];
  color: string;
  onSelect: (course: string) => void;
  subject: string;
  isLoading?: boolean;
}

export function CourseGrid({ courses, color, onSelect, subject, isLoading = false }: CourseGridProps) {
  const getCourseStatus = (course: Course) => {
    if (!course.is_locked) return 'available';
    if (!course.release_date) return 'locked';
    
    const releaseDate = new Date(course.release_date);
    if (releaseDate <= new Date()) return 'available';
    return 'countdown';
  };

  const getCountdown = (releaseDate: string) => {
    return formatDistanceToNow(new Date(releaseDate), { addSuffix: true });
  };

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {[...Array(4)].map((_, i) => (
          <div 
            key={i}
            className="relative aspect-[3/2] rounded-xl overflow-hidden bg-zinc-800/50 animate-pulse"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-white/[0.075] to-transparent" />
            <div className="relative h-full flex flex-col items-center justify-center gap-4">
              <div className="w-8 h-8 rounded-full bg-zinc-700/50" />
              <div className="w-40 h-6 rounded-md bg-zinc-700/50" />
              <div className="w-32 h-4 rounded-md bg-zinc-700/50" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      {courses.map((course) => {
        const status = getCourseStatus(course);

        return (
          <div
            key={course.id}
            className={`relative aspect-[3/2] rounded-xl overflow-hidden transform transition-all duration-300 ${
              status === 'available' ? 'cursor-pointer hover:scale-[1.02] hover:shadow-xl hover:shadow-black/20' : 'cursor-not-allowed'
            }`}
            onClick={() => status === 'available' && onSelect(course.name)}
          >
            {/* Background */}
            <div 
              className="absolute inset-0 bg-zinc-800/90"
              style={{
                background: `linear-gradient(to right, ${color}33, ${color}22)`
              }}
            />

            {/* Content */}
            <div className="relative z-10 h-full flex flex-col items-center justify-center py-10 px-6">
              {/* Icon */}
              <div className="bg-black/20 backdrop-blur-md rounded-xl p-4 mb-4">
                {status === 'available' && (
                  <GraduationCap className="w-5 h-5 text-white" />
                )}
                {status === 'locked' && (
                  <Lock className="w-5 h-5 text-white/80" />
                )}
                {status === 'countdown' && (
                  <Rocket className="w-5 h-5 text-white/80" />
                )}
              </div>

              {/* Title */}
              <h3 className="text-base font-medium text-white text-center mb-3">
                {course.name}
              </h3>

              {/* Stats */}
              <div className="flex items-center gap-2 text-sm text-zinc-300">
                <span>{course.moduleCount} Modules</span>
                <span className="text-zinc-500">|</span>
                <span>{course.sectionCount} Lessons</span>
              </div>

              {/* Status Badge */}
              {(status === 'locked' || status === 'countdown') && (
                <div className="absolute top-4 right-4">
                  <div className="bg-black/40 backdrop-blur-sm rounded-xl px-3 py-1.5">
                    {status === 'locked' ? (
                      <div className="flex items-center gap-1.5">
                        <Lock className="w-3.5 h-3.5 text-white/80" />
                        <span className="text-xs text-white/80 font-medium">Coming Soon</span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-1.5">
                        <Rocket className="w-3.5 h-3.5 text-white/80" />
                        <span className="text-xs text-white/80 font-medium">
                          Launching {getCountdown(course.release_date!)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}