import { ArrowLeft, ExternalLink } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';

interface SupportPageProps {
  onBack: () => void;
}

export function SupportPage({ onBack }: SupportPageProps) {
  const { profile } = useAuth();

  const handleSupportClick = () => {
    const supportUrl = `https://audium.app/support-ticket.html?user_email=${encodeURIComponent(profile?.email || '')}`;
    window.open(supportUrl, '_blank');
  };

  return (
    <div className="max-w-2xl mx-auto py-8 px-4">
      <button
        onClick={onBack}
        className="flex items-center gap-2 text-zinc-200 hover:text-white transition-colors mb-8 text-base"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Dashboard
      </button>

      <div className="bg-zinc-900 rounded-xl p-8 border border-zinc-800">
        <h1 className="text-3xl font-bold text-white mb-4">Support</h1>
        <p className="text-zinc-400 mb-8">
          Need help? Our support team is ready to assist you with any questions or issues you might have.
        </p>

        <Button
          onClick={handleSupportClick}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white py-6 text-lg"
        >
          <ExternalLink className="w-5 h-5 mr-2" />
          Open Support Portal
        </Button>
      </div>
    </div>
  );
}