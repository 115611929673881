import { useState, useEffect } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';

interface WhitelistedEmail {
  id: string;
  email: string;
  created_at: string;
}

export function WhitelistManager() {
  const [emails, setEmails] = useState<WhitelistedEmail[]>([]);
  const [newEmail, setNewEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    loadEmails();
  }, []);

  const loadEmails = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('whitelist_emails')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setEmails(data || []);
    } catch (error) {
      console.error('Error loading whitelisted emails:', error);
      toast({
        title: 'Error',
        description: 'Failed to load whitelisted emails',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddEmail = async () => {
    if (!newEmail.trim()) return;

    try {
      setIsLoading(true);
      const { error } = await supabase
        .from('whitelist_emails')
        .insert([{ email: newEmail.toLowerCase() }]);

      if (error) throw error;

      toast({
        title: 'Email added',
        description: `${newEmail} has been added to the whitelist.`,
      });

      setNewEmail('');
      await loadEmails();
    } catch (error) {
      console.error('Error adding email:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to add email',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveEmail = async (emailId: string) => {
    try {
      setIsLoading(true);
      const { error } = await supabase
        .from('whitelist_emails')
        .delete()
        .eq('id', emailId);

      if (error) throw error;

      toast({
        title: 'Email removed',
        description: 'Email has been removed from the whitelist.',
      });

      await loadEmails();
    } catch (error) {
      console.error('Error removing email:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to remove email',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex gap-4">
        <Input
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          placeholder="Enter email address..."
          type="email"
          className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500"
        />
        <Button
          onClick={handleAddEmail}
          disabled={isLoading || !newEmail.trim()}
          className="bg-white hover:bg-zinc-100 text-zinc-900"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Email
        </Button>
      </div>

      <div className="space-y-4">
        {emails.map((email) => (
          <div
            key={email.id}
            className="flex items-center justify-between p-4 bg-zinc-800/50 rounded-xl border border-zinc-700/50"
          >
            <div className="space-y-1">
              <p className="text-white font-medium">{email.email}</p>
              <p className="text-sm text-zinc-400">
                Added {new Date(email.created_at).toLocaleDateString()}
              </p>
            </div>
            <Button
              onClick={() => handleRemoveEmail(email.id)}
              variant="ghost"
              size="sm"
              className="text-red-400 hover:text-red-300 hover:bg-red-950/30"
            >
              <Trash2 className="w-4 h-4" />
            </Button>
          </div>
        ))}

        {emails.length === 0 && (
          <p className="text-zinc-400 text-center py-8">
            No whitelisted emails yet
          </p>
        )}
      </div>
    </div>
  );
}