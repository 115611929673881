import { createContext, useContext, useEffect, useState } from 'react';
import type { User } from '@supabase/supabase-js';
import { supabase } from '@/lib/supabase';
import type { Profile } from '@/lib/types';
import { useToast } from '@/hooks/use-toast';
import { clearSubscriptionCache } from '@/lib/subscription-cache';
import { clearRankCache } from '@/lib/rank-cache';

interface AuthContextType {
  user: User | null;
  profile: Profile | null;
  isLoading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, fullName: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  const createDefaultProfile = (user: User): Profile => ({
    id: user.id,
    email: user.email || '',
    full_name: user.user_metadata?.full_name || '',
    role: 'user',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  });

  const loadProfile = async (user: User) => {
    if (!user) {
      console.log('No user, clearing profile');
      setProfile(null);
      return;
    }

    try {
      console.log('📥 Loading profile for user:', user.id);

      // First try to get existing profile
      const { data: existingProfile, error: fetchError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (fetchError) {
        // If profile doesn't exist, create one
        if (fetchError.code === 'PGRST116') {
          const defaultProfile = createDefaultProfile(user);
          
          const { data: newProfile, error: createError } = await supabase
            .from('profiles')
            .insert([defaultProfile])
            .select()
            .single();

          if (createError) {
            console.warn('Failed to create profile, using default:', createError);
            setProfile(defaultProfile);
            return;
          }

          console.log('✅ New profile created:', {
            id: newProfile.id,
            email: newProfile.email,
            role: newProfile.role,
          });
          
          setProfile(newProfile);
          return;
        }

        // For other errors, use default profile
        console.warn('Error fetching profile, using default:', fetchError);
        setProfile(createDefaultProfile(user));
        return;
      }

      if (!existingProfile) {
        const defaultProfile = createDefaultProfile(user);
        setProfile(defaultProfile);
        return;
      }

      console.log('✅ Existing profile loaded:', {
        id: existingProfile.id,
        email: existingProfile.email,
        role: existingProfile.role,
      });

      setProfile(existingProfile);
    } catch (error) {
      console.error('❌ Error in loadProfile:', error);
      
      // Set default profile as fallback
      const defaultProfile = createDefaultProfile(user);
      setProfile(defaultProfile);

      toast({
        title: 'Warning',
        description: 'Some profile information may be incomplete. Please refresh the page.',
        variant: 'destructive',
      });
    }
  };

  // Initialize auth state
  useEffect(() => {
    console.log('Initializing auth state...');
    
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user || null);
      if (session?.user) {
        console.log('Initial session:', session.user.id);
        loadProfile(session.user);
      }
      setIsLoading(false);
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_, session) => {
      const newUser = session?.user || null;
      setUser(newUser);
      if (newUser) {
        console.log('Auth state changed:', newUser.id);
        loadProfile(newUser);
      } else {
        setProfile(null);
        clearSubscriptionCache();
        clearRankCache();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const signUp = async (email: string, password: string, fullName: string) => {
    try {
      setIsLoading(true);
      const { data: { user }, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            full_name: fullName,
          },
        },
      });

      if (error) throw error;
      if (!user) throw new Error('User creation failed');

      toast({
        title: 'Account created',
        description: 'Welcome to Audium! Your account has been created successfully.',
      });
    } catch (error) {
      console.error('Sign up error:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    try {
      await supabase.auth.signOut();
      clearSubscriptionCache();
      clearRankCache();
    } catch (error) {
      console.error('Sign out error:', error);
      toast({
        title: 'Error signing out',
        description: 'Please try again',
        variant: 'destructive',
      });
    }
  };

  const value = {
    user,
    profile,
    isLoading,
    signIn,
    signUp,
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}