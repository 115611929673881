import type { Module } from '@/data/types';

interface AudioState {
  subject: string;
  course: string;
  moduleIndex: number;
  audioIndex: number;
  currentTime: number;
  timestamp: number;
}

const AUDIO_STATE_KEY = 'audio_state';
const MAX_AGE = 7 * 24 * 60 * 60 * 1000; // 7 days

export function saveAudioState(
  subject: string,
  course: string,
  moduleIndex: number,
  audioIndex: number,
  currentTime: number
) {
  const state: AudioState = {
    subject,
    course,
    moduleIndex,
    audioIndex,
    currentTime,
    timestamp: Date.now(),
  };
  localStorage.setItem(AUDIO_STATE_KEY, JSON.stringify(state));
}

export function getAudioState(): AudioState | null {
  try {
    const state = localStorage.getItem(AUDIO_STATE_KEY);
    if (!state) return null;

    const parsedState = JSON.parse(state) as AudioState;
    
    // Check if state is too old
    if (Date.now() - parsedState.timestamp > MAX_AGE) {
      localStorage.removeItem(AUDIO_STATE_KEY);
      return null;
    }

    return parsedState;
  } catch (error) {
    console.error('Error reading audio state:', error);
    return null;
  }
}

export function clearAudioState() {
  localStorage.removeItem(AUDIO_STATE_KEY);
}