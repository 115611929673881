import { useState, useRef, useEffect } from 'react';
import { Sidebar } from './Sidebar';
import { SubjectGrid } from './SubjectGrid';
import { CourseGrid } from './CourseGrid';
import { ModuleList } from './ModuleList';
import { AudioControls } from './AudioControls';
import { SettingsPage } from './settings/SettingsPage';
import { RequestCoursePage } from './RequestCoursePage';
import { SupportPage } from './SupportPage';
import { AITutorPage } from './AITutorPage';
import { AdminPanel } from './admin/AdminPanel';
import { CertificatesPage } from './CertificatesPage';
import { RanksPage } from './RanksPage';
import { QuizPage } from './QuizPage';
import { Breadcrumb } from './Breadcrumb';
import { ResumeBanner } from './ResumeBanner';
import { SubscriptionDialog } from './SubscriptionDialog';
import { NewsBanner } from './NewsBanner';
import { saveAudioState, getAudioState, clearAudioState } from '@/lib/audio-persistence';
import { useAuth } from '@/contexts/AuthContext';
import { useSubscriptionStatus } from '@/hooks/useSubscriptionStatus';
import { fetchCourses, fetchModules, fetchSubjects } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import type { Course, Subject, Module } from '@/data/types';

interface AudioState {
  contentTitle: string;
}

export function Dashboard() {
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
  const [currentModuleIndex, setCurrentModuleIndex] = useState(-1);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showRequestCourse, setShowRequestCourse] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [showAITutor, setShowAITutor] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showCertificates, setShowCertificates] = useState(false);
  const [showRanks, setShowRanks] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [currentTutorTopic, setCurrentTutorTopic] = useState('');
  const [showResumeBanner, setShowResumeBanner] = useState(false);
  const [courses, setCourses] = useState<Course[]>([]);
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [isLoadingCourses, setIsLoadingCourses] = useState(false);
  const [currentTitle, setCurrentTitle] = useState<AudioState>({ contentTitle: '' });
  const [hasNext, setHasNext] = useState(false);
  const [currentModules, setCurrentModules] = useState<Module[]>([]);

  const { profile } = useAuth();
  const { needsSubscription, isOverdue } = useSubscriptionStatus();
  const { toast } = useToast();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const mainContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Check for resume state
    const state = getAudioState();
    if (state) {
      setShowResumeBanner(true);
    }

    // Load initial subjects
    loadSubjects();

    // Initialize audio element
    if (!audioRef.current) {
      audioRef.current = new Audio();
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
    };
  }, []);

  useEffect(() => {
    if (selectedSubject && selectedCourse) {
      loadModules();
    }
  }, [selectedSubject, selectedCourse]);

  const loadSubjects = async () => {
    try {
      const data = await fetchSubjects();
      setSubjects(data);
    } catch (error) {
      console.error('Error loading subjects:', error);
      toast({
        title: 'Error',
        description: 'Failed to load subjects. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const loadModules = async () => {
    if (!selectedSubject || !selectedCourse) return;
    
    try {
      const modules = await fetchModules(selectedSubject, selectedCourse);
      setCurrentModules(modules);
    } catch (error) {
      console.error('Error loading modules:', error);
      toast({
        title: 'Error',
        description: 'Failed to load modules. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const loadCourses = async () => {
    if (!selectedSubject) return;
    
    try {
      setIsLoadingCourses(true);
      const data = await fetchCourses(selectedSubject);

      // Fetch module counts for each course
      const coursesWithCounts = await Promise.all(data.map(async (course) => {
        const modules = await fetchModules(selectedSubject, course.name);
        const moduleCount = modules.length;
        const sectionCount = modules.reduce((total, module) => total + module.content.length, 0);
        return { ...course, moduleCount, sectionCount };
      }));

      setCourses(coursesWithCounts);
    } catch (error) {
      console.error('Error loading courses:', error);
      toast({
        title: 'Error',
        description: 'Failed to load courses. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsLoadingCourses(false);
    }
  };

  useEffect(() => {
    if (selectedSubject) {
      loadCourses();
    }
  }, [selectedSubject]);

  const handleNavigateHome = () => {
    setSelectedSubject(null);
    setSelectedCourse(null);
    setCurrentModuleIndex(-1);
    setCurrentAudioIndex(-1);
    setIsPlaying(false);
    setShowControls(false);
    setShowSettings(false);
    setShowRequestCourse(false);
    setShowSupport(false);
    setShowAITutor(false);
    setShowAdmin(false);
    setShowCertificates(false);
    setShowRanks(false);
    setShowQuiz(false);
  };

  const handleCourseSelect = (subject: string | null = null, course: string) => {
    if (subject) {
      setSelectedSubject(subject);
    }
    setSelectedCourse(course);
    setCurrentModuleIndex(-1);
    setCurrentAudioIndex(-1);
    setIsPlaying(false);
    setShowControls(false);
  };

  const handleModuleSelect = async (moduleIndex: number, audioIndex: number, startTime?: number) => {
    if (!selectedSubject || !selectedCourse || !audioRef.current) return;

    try {
      const modules = await fetchModules(selectedSubject, selectedCourse);
      if (!modules || !modules[moduleIndex]?.audio_urls?.[audioIndex]) {
        throw new Error('Audio not found');
      }

      const audioUrl = modules[moduleIndex].audio_urls[audioIndex];

      // Only load new audio if URL has changed
      if (audioRef.current.src !== audioUrl) {
        audioRef.current.src = audioUrl;
        await audioRef.current.load();
      }

      setCurrentModuleIndex(moduleIndex);
      setCurrentAudioIndex(audioIndex);
      setShowControls(true);
      setCurrentModules(modules);

      setCurrentTitle({
        contentTitle: modules[moduleIndex].content[audioIndex]
      });

      if (typeof startTime === 'number') {
        audioRef.current.currentTime = startTime;
      }

      try {
        await audioRef.current.play();
        setIsPlaying(true);
        setShowResumeBanner(false);
      } catch (playError) {
        console.error('Error playing audio:', playError);
        toast({
          title: 'Error',
          description: 'Failed to play audio. Please try again.',
          variant: 'destructive',
        });
      }

      // Save audio state
      saveAudioState(
        selectedSubject,
        selectedCourse,
        moduleIndex,
        audioIndex,
        audioRef.current.currentTime
      );

      checkHasNextTrack();
    } catch (error) {
      console.error('Error handling module selection:', error);
      toast({
        title: 'Error',
        description: 'Failed to load audio. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handlePlayPause = async () => {
    if (!audioRef.current) return;

    try {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        await audioRef.current.play();
      }
      setIsPlaying(!isPlaying);

      // Save current state
      if (selectedSubject && selectedCourse && currentModuleIndex !== -1 && currentAudioIndex !== -1) {
        saveAudioState(
          selectedSubject,
          selectedCourse,
          currentModuleIndex,
          currentAudioIndex,
          audioRef.current.currentTime
        );
      }
    } catch (error) {
      console.error('Error toggling playback:', error);
      toast({
        title: 'Error',
        description: 'Failed to control playback. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleSeek = (time: number) => {
    if (!audioRef.current) return;
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleNext = async () => {
    if (!selectedSubject || !selectedCourse) return;

    try {
      const currentModule = currentModules[currentModuleIndex];

      if (!currentModule?.audio_urls) return;

      if (currentAudioIndex < currentModule.audio_urls.length - 1) {
        handleModuleSelect(currentModuleIndex, currentAudioIndex + 1);
      } else if (currentModuleIndex < currentModules.length - 1 && currentModules[currentModuleIndex + 1].audio_urls?.[0]) {
        handleModuleSelect(currentModuleIndex + 1, 0);
      }
    } catch (error) {
      console.error('Error handling next track:', error);
      toast({
        title: 'Error',
        description: 'Failed to play next track. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const checkHasNextTrack = () => {
    if (!selectedSubject || !selectedCourse || currentModuleIndex === -1) {
      setHasNext(false);
      return;
    }

    const currentModule = currentModules[currentModuleIndex];

    if (!currentModule?.audio_urls) {
      setHasNext(false);
      return;
    }

    const nextAvailable = currentAudioIndex < currentModule.audio_urls.length - 1 ||
      (currentModuleIndex < currentModules.length - 1 && currentModules[currentModuleIndex + 1]?.audio_urls?.[0]);
    
    setHasNext(nextAvailable);
  };

  useEffect(() => {
    checkHasNextTrack();
  }, [currentModuleIndex, currentAudioIndex, currentModules.length]);

  useEffect(() => {
    if (!audioRef.current) return;

    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleDurationChange = () => {
      setDuration(audio.duration);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      if (hasNext) {
        handleNext();
      }
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('durationchange', handleDurationChange);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('durationchange', handleDurationChange);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [currentModuleIndex, currentAudioIndex, hasNext]);

  useEffect(() => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = 0;
    }
  }, [selectedSubject, selectedCourse, showSettings, showRequestCourse, showSupport, showAITutor, showAdmin, showCertificates]);

  const getBreadcrumbItems = () => {
    const items = [
      {
        label: 'Programs',
        onClick: handleNavigateHome,
      },
    ];

    if (selectedSubject) {
      items.push({
        label: selectedSubject,
        onClick: () => {
          setSelectedCourse(null);
          setCurrentModuleIndex(-1);
          setCurrentAudioIndex(-1);
          setIsPlaying(false);
          setShowControls(false);
        },
      });
    }

    if (selectedCourse) {
      items.push({
        label: selectedCourse,
        onClick: () => {},
      });
    }

    return items;
  };

  return (
    <div className="min-h-screen bg-zinc-950 flex">
      <Sidebar 
        onNavigateHome={handleNavigateHome}
        onSettingsClick={() => setShowSettings(true)}
        onRequestCourseClick={() => setShowRequestCourse(true)}
        onSupportClick={() => setShowSupport(true)}
        onAITutorClick={() => setShowAITutor(true)}
        onAdminClick={() => setShowAdmin(true)}
        onCertificatesClick={() => setShowCertificates(true)}
        onRanksClick={() => setShowRanks(true)}
        onCourseSelect={handleCourseSelect}
        onModuleSelect={(subject, course, moduleIndex, audioIndex) => {
          setSelectedSubject(subject);
          setSelectedCourse(course);
          handleModuleSelect(moduleIndex, audioIndex);
        }}
        isAdmin={profile?.role === 'admin'}
      />
      
      <main ref={mainContentRef} className="flex-1 overflow-y-auto pb-32">
        <NewsBanner />
        
        <div className="p-4 sm:p-8 lg:pt-8 pt-16">
          {showSettings ? (
            <SettingsPage onBack={handleNavigateHome} />
          ) : showRequestCourse ? (
            <RequestCoursePage onBack={handleNavigateHome} />
          ) : showSupport ? (
            <SupportPage onBack={handleNavigateHome} />
          ) : showAITutor ? (
            <AITutorPage 
              onBack={handleNavigateHome} 
              topic={currentTutorTopic}
              courseName={selectedCourse || ''}
            />
          ) : showAdmin ? (
            <AdminPanel onBack={handleNavigateHome} />
          ) : showCertificates ? (
            <CertificatesPage onBack={handleNavigateHome} />
          ) : showRanks ? (
            <RanksPage onBack={handleNavigateHome} />
          ) : showQuiz ? (
            <QuizPage 
              subject={selectedSubject!}
              course={selectedCourse!}
              onBack={() => setShowQuiz(false)}
            />
          ) : (
            <>
              <Breadcrumb items={getBreadcrumbItems()} />
              {!selectedSubject && showResumeBanner && (
                <ResumeBanner
                  subject={getAudioState()?.subject || ''}
                  course={getAudioState()?.course || ''}
                  moduleIndex={getAudioState()?.moduleIndex || 0}
                  audioIndex={getAudioState()?.audioIndex || 0}
                  onResume={() => {
                    const state = getAudioState();
                    if (state) {
                      setSelectedSubject(state.subject);
                      setSelectedCourse(state.course);
                      handleModuleSelect(state.moduleIndex, state.audioIndex, state.currentTime);
                    }
                  }}
                  onDismiss={() => {
                    setShowResumeBanner(false);
                    clearAudioState();
                  }}
                />
              )}
              {!selectedSubject ? (
                <SubjectGrid 
                  onSelect={setSelectedSubject} 
                  onRanksClick={() => setShowRanks(true)}
                />
              ) : !selectedCourse ? (
                <CourseGrid
                  courses={courses}
                  color={subjects.find(s => s.name === selectedSubject)?.color || ''}
                  onSelect={(course) => handleCourseSelect(null, course)}
                  subject={selectedSubject}
                  isLoading={isLoadingCourses}
                />
              ) : (
                <ModuleList
                  subject={selectedSubject}
                  course={selectedCourse}
                  currentModuleIndex={currentModuleIndex}
                  currentAudioIndex={currentAudioIndex}
                  onPlay={handleModuleSelect}
                  onAITutor={(topic) => {
                    setCurrentTutorTopic(topic);
                    setShowAITutor(true);
                  }}
                  courseName={selectedCourse}
                  onQuiz={() => setShowQuiz(true)}
                />
              )}
            </>
          )}
        </div>
      </main>

      {showControls && (
        <AudioControls
          isPlaying={isPlaying}
          currentTime={currentTime}
          duration={duration}
          onPlayPause={handlePlayPause}
          onSeek={handleSeek}
          onNext={handleNext}
          title={currentTitle.contentTitle}
          hasNextTrack={hasNext}
        />
      )}

      <SubscriptionDialog 
        isOpen={needsSubscription} 
        type={isOverdue ? 'overdue' : 'signup'} 
      />
    </div>
  );
}