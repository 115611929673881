import { useRank } from './useRank';

export function useRankPermissions() {
  const { rank } = useRank();

  const canAccessCategory = (category: string) => {
    // Scholarship rank has access to everything
    if (rank === 'Scholarship') return true;

    switch (category) {
      case 'Academic':
      case 'Specialised':
        return true; // Available to all ranks (Listener+)
      case 'Lifestyle':
        return ['Learner', 'Explorer', 'Master'].includes(rank);
      case 'Entertainment':
        return ['Explorer', 'Master'].includes(rank);
      default:
        return false;
    }
  };

  const canRequestCourse = () => {
    // Scholarship rank can request courses
    if (rank === 'Scholarship') return true;
    return ['Explorer', 'Master'].includes(rank);
  };

  const getAITutorCredits = () => {
    // Scholarship rank gets maximum credits
    if (rank === 'Scholarship') return 2;
    
    switch (rank) {
      case 'Learner':
        return 2; // Double credits
      case 'Explorer':
      case 'Master':
        return 2; // Keep double credits
      default:
        return 1; // Basic credits for Listener
    }
  };

  return {
    canAccessCategory,
    canRequestCourse,
    getAITutorCredits,
  };
}