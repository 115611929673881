import { 
  Activity, AlertCircle, Archive, ArrowDown, ArrowUp, Award, Atom,
  BarChart2, Battery, Bell, Book, BookOpen, BookOpenCheck, Bookmark, Brain, Box, Briefcase, Building2, 
  Calculator, Calendar, Camera, Check, CheckCircle, ChevronDown, ChevronUp, CircleDollarSign,
  Clipboard, ClipboardCheck, ClipboardList, Clock, Cloud, Code, Compass, Cog,
  CreditCard, Crop, Database, Disc, Download, Droplet, DollarSign,
  Edit, Eye, Facebook, File, FileText, Film, Filter, Flag, FlaskConical,
  Folder, Gift, Github, Globe, Globe2, GraduationCap, Grid, 
  Hash, Headphones, HeartPulse, History, Home, HelpCircle,
  Image, Inbox, Info, Instagram, Key, Keyboard,
  Layers, Layout, Library, LifeBuoy, Lightbulb, Link, List, Lock, 
  Mail, Map, MapPin, Maximize, Menu, MessageCircle, Microscope, Mic, Monitor, Moon,
  Music, Newspaper, Package, Paperclip, PartyPopper, Pause, Pencil, PenTool, Phone, Pi, Play, Plus,
  Printer, Puzzle, Quote, Radio, RefreshCw, Ruler, Save, Scale, School, Search, Send, Server,
  Settings, Share, Shield, ShoppingBag, ShoppingCart, Shuffle, Sigma, Skull,
  Sidebar, Slack, Sliders, Smartphone, Speaker, SpellCheck, Star, Stethoscope, Sun,
  Sword, // This is the knife/sword icon
  Table, Tablet, Tag, Target, Terminal, TestTube2, TestTubes,
  Thermometer, ThumbsUp, Timer, Trash2, TrendingUp, Triangle,
  Trophy, Truck, Tv, Twitter, Type, Umbrella, Unlock, Upload, User,
  UserCheck, UserMinus, UserPlus, Users, Variable, Video, Volume,
  Volume1, Volume2, VolumeX, Wallet, Watch, Wifi, Wind, Wrench, X, Youtube,
  Zap, ZoomIn
} from 'lucide-react';

export const icons = [
  // Education & Learning
  { name: 'Book', icon: Book },
  { name: 'BookOpen', icon: BookOpen },
  { name: 'BookOpenCheck', icon: BookOpenCheck },
  { name: 'Brain', icon: Brain },
  { name: 'GraduationCap', icon: GraduationCap },
  { name: 'School', icon: School },
  { name: 'Library', icon: Library },
  { name: 'Pencil', icon: Pencil },
  { name: 'SpellCheck', icon: SpellCheck },
  
  // Science & Math
  { name: 'Atom', icon: Atom },
  { name: 'Calculator', icon: Calculator },
  { name: 'FlaskConical', icon: FlaskConical },
  { name: 'Microscope', icon: Microscope },
  { name: 'Pi', icon: Pi },
  { name: 'TestTube2', icon: TestTube2 },
  { name: 'TestTubes', icon: TestTubes },
  { name: 'Variable', icon: Variable },
  
  // Business & Finance
  { name: 'CircleDollarSign', icon: CircleDollarSign },
  { name: 'DollarSign', icon: DollarSign },
  { name: 'Briefcase', icon: Briefcase },
  { name: 'Building2', icon: Building2 },
  { name: 'Scale', icon: Scale },
  { name: 'Wallet', icon: Wallet },
  
  // Geography & History
  { name: 'Globe', icon: Globe },
  { name: 'Globe2', icon: Globe2 },
  { name: 'Map', icon: Map },
  { name: 'MapPin', icon: MapPin },
  { name: 'History', icon: History },
  
  // Health & Medicine
  { name: 'HeartPulse', icon: HeartPulse },
  { name: 'Stethoscope', icon: Stethoscope },
  
  // Combat & Action
  { name: 'Sword', icon: Sword },
  { name: 'Skull', icon: Skull },
  
  // Technology & Computing
  { name: 'Code', icon: Code },
  { name: 'Database', icon: Database },
  { name: 'Cog', icon: Cog },
  { name: 'Keyboard', icon: Keyboard },
  { name: 'Monitor', icon: Monitor },
  { name: 'Server', icon: Server },
  
  // Communication & Media
  { name: 'MessageCircle', icon: MessageCircle },
  { name: 'Quote', icon: Quote },
  { name: 'Newspaper', icon: Newspaper },
  { name: 'FileText', icon: FileText },
  { name: 'Film', icon: Film },
  { name: 'Image', icon: Image },
  { name: 'Music', icon: Music },
  { name: 'Video', icon: Video },
  
  // Tools & Equipment
  { name: 'Ruler', icon: Ruler },
  { name: 'PenTool', icon: PenTool },
  { name: 'Wrench', icon: Wrench },
  
  // Organization & Progress
  { name: 'ClipboardCheck', icon: ClipboardCheck },
  { name: 'ClipboardList', icon: ClipboardList },
  { name: 'Timer', icon: Timer },
  { name: 'Trophy', icon: Trophy },
  { name: 'PartyPopper', icon: PartyPopper },
  { name: 'Puzzle', icon: Puzzle },
  
  // Interface & Actions
  { name: 'Activity', icon: Activity },
  { name: 'Archive', icon: Archive },
  { name: 'Award', icon: Award },
  { name: 'Bell', icon: Bell },
  { name: 'Bookmark', icon: Bookmark },
  { name: 'Calendar', icon: Calendar },
  { name: 'Camera', icon: Camera },
  { name: 'Check', icon: Check },
  { name: 'Clock', icon: Clock },
  { name: 'Edit', icon: Edit },
  { name: 'Eye', icon: Eye },
  { name: 'Flag', icon: Flag },
  { name: 'HelpCircle', icon: HelpCircle },
  { name: 'Home', icon: Home },
  { name: 'Info', icon: Info },
  { name: 'Lightbulb', icon: Lightbulb },
  { name: 'Link', icon: Link },
  { name: 'Lock', icon: Lock },
  { name: 'Plus', icon: Plus },
  { name: 'Save', icon: Save },
  { name: 'Search', icon: Search },
  { name: 'Settings', icon: Settings },
  { name: 'Share', icon: Share },
  { name: 'Star', icon: Star },
  { name: 'Trash2', icon: Trash2 },
  { name: 'Upload', icon: Upload },
  { name: 'User', icon: User },
  { name: 'Users', icon: Users },
  { name: 'ZoomIn', icon: ZoomIn }
];