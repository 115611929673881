import { useState, useEffect } from 'react';
import { Brain, Send, ArrowLeft } from 'lucide-react';
import { Button } from './ui/button';
import { Textarea } from './ui/textarea';
import { sendMessage, getTokenUsage } from '@/lib/openai';
import type { Message } from '@/types/chat';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/contexts/AuthContext';

interface AITutorPageProps {
  onBack: () => void;
  topic: string;
  courseName: string;
}

const WEEKLY_LIMIT = 9000;

// Function to safely parse and render HTML content
const renderMessageContent = (content: string) => {
  // Create a temporary div to parse HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = content;

  // Convert HTML to styled text
  const text = tempDiv.textContent || tempDiv.innerText || '';

  // Apply styling based on HTML tags
  const styledText = content
    .replace(/<b>(.*?)<\/b>/g, (_, p1) => `<span class="font-bold">${p1}</span>`)
    .replace(/<strong>(.*?)<\/strong>/g, (_, p1) => `<span class="font-bold">${p1}</span>`)
    .replace(/<i>(.*?)<\/i>/g, (_, p1) => `<span class="italic">${p1}</span>`)
    .replace(/<em>(.*?)<\/em>/g, (_, p1) => `<span class="italic">${p1}</span>`)
    .replace(/<u>(.*?)<\/u>/g, (_, p1) => `<span class="underline">${p1}</span>`);

  return <div dangerouslySetInnerHTML={{ __html: styledText }} />;
};

export function AITutorPage({ onBack, topic, courseName }: AITutorPageProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentInput, setCurrentInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tokenUsage, setTokenUsage] = useState(0);
  const { toast } = useToast();
  const { user, profile } = useAuth();

  useEffect(() => {
    if (user?.id) {
      loadTokenUsage();
    }
  }, [user]);

  useEffect(() => {
    if (!topic) {
      setMessages([{
        role: 'assistant',
        content: "Hey " + (profile?.full_name?.split(' ')[0] || '') + ", Welcome! - To get started, please go to a course and click the AI Tutor button next to any module you'd like to study."
      }]);
    } else {
      const initialMessage = async () => {
        try {
          const response = await sendMessage(
            [], 
            topic, 
            courseName,
            user?.id,
            profile?.full_name
          );
          setMessages([{ role: 'assistant', content: response }]);
        } catch (error) {
          console.error('Error getting initial message:', error);
        }
      };
      initialMessage();
    }
  }, [topic, profile?.full_name]);

  const loadTokenUsage = async () => {
    if (!user?.id) return;
    try {
      const usage = await getTokenUsage(user.id);
      setTokenUsage(usage);
    } catch (error) {
      console.error('Error loading token usage:', error);
    }
  };

  const handleSubmit = async () => {
    if (!currentInput.trim() || isLoading || !topic) return;

    try {
      setIsLoading(true);
      const userMessage: Message = { role: 'user', content: currentInput };
      setMessages(prev => [...prev, userMessage]);
      setCurrentInput('');

      const response = await sendMessage(
        [...messages, userMessage], 
        topic,
        courseName,
        user?.id,
        profile?.full_name
      );
      
      setMessages(prev => [...prev, { role: 'assistant', content: response }]);
      
      // Refresh token usage after each message
      if (user?.id) {
        await loadTokenUsage();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to get AI response",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const remainingPercentage = Math.max(0, Math.min(100, ((WEEKLY_LIMIT - tokenUsage) / WEEKLY_LIMIT) * 100));

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <button
        onClick={onBack}
        className="flex items-center gap-2 text-zinc-200 hover:text-white transition-colors mb-8 text-base"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Dashboard
      </button>

      <div className="bg-zinc-900 rounded-xl border border-zinc-800 min-h-[600px] flex flex-col">
        <div className="p-6 border-b border-zinc-800">
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-xl font-semibold text-white flex items-center gap-2">
              <Brain className="w-6 h-6" />
              AI Tutor
            </h1>
            <div className="flex items-center gap-3">
              <div className="relative w-14 h-14">
                <svg className="w-full h-full -rotate-90">
                  <circle
                    cx="28"
                    cy="28"
                    r="24"
                    fill="none"
                    strokeWidth="6"
                    className="stroke-zinc-700"
                  />
                  <circle
                    cx="28"
                    cy="28"
                    r="24"
                    fill="none"
                    strokeWidth="6"
                    strokeDasharray={`${(remainingPercentage / 100) * 150.8} 150.8`}
                    className="stroke-[url(#orangeGradient)]"
                    style={{
                      transition: 'stroke-dasharray 0.5s ease'
                    }}
                  />
                  <defs>
                    <linearGradient id="orangeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stopColor="rgb(249, 115, 22)" />
                      <stop offset="100%" stopColor="rgb(245, 158, 11)" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="text-sm font-medium text-zinc-200">
                    {Math.round(remainingPercentage)}%
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm text-zinc-200 font-medium">Credits</span>
                <span className="text-xs text-zinc-400">remaining</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-zinc-400 text-sm">Interactive learning through conversation</p>
            <p className="text-xs text-zinc-500">Your AI Tutor credits will refresh every week</p>
          </div>
        </div>

        <div className="flex-1 p-6 overflow-y-auto space-y-6">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.role === 'assistant' ? 'justify-start' : 'justify-end'}`}
            >
              <div
                className={`max-w-[80%] rounded-xl p-4 whitespace-pre-wrap ${
                  message.role === 'assistant'
                    ? 'bg-zinc-800 text-white'
                    : 'bg-blue-600 text-white'
                }`}
              >
                {renderMessageContent(message.content)}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="flex justify-start">
              <div className="max-w-[80%] rounded-xl p-4 bg-zinc-800 text-white">
                <div className="flex items-center gap-2">
                  <div className="flex gap-1">
                    <div className="w-2 h-2 bg-zinc-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="w-2 h-2 bg-zinc-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="w-2 h-2 bg-zinc-400 rounded-full animate-bounce"></div>
                  </div>
                  <span className="text-zinc-400 text-sm">AI is thinking...</span>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="p-4 border-t border-zinc-800">
          <div className="flex flex-col gap-2">
            <Textarea
              value={currentInput}
              onChange={(e) => setCurrentInput(e.target.value)}
              placeholder={topic ? "Type your message..." : "Select a topic to start..."}
              disabled={!topic || isLoading}
              className="flex-1 bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500 min-h-[80px] disabled:opacity-50 disabled:cursor-not-allowed"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              style={{ fontSize: '16px' }}
            />
            <Button
              onClick={handleSubmit}
              disabled={isLoading || !currentInput.trim() || !topic}
              className="bg-blue-600 hover:bg-blue-700 text-white w-full sm:w-auto sm:self-end disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Send className="w-4 h-4 sm:mr-2" />
              <span className="hidden sm:inline">Send</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}