import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/AuthContext';
import { ExternalLink, LogOut } from 'lucide-react';
import { forceLogout } from '@/lib/auth';

interface SubscriptionDialogProps {
  isOpen: boolean;
  type: 'signup' | 'overdue';
}

export function SubscriptionDialog({ isOpen, type }: SubscriptionDialogProps) {
  const { profile } = useAuth();

  const handleAction = () => {
    const url = type === 'signup' 
      ? `https://buy.stripe.com/6oEaHh22b4GdbRudQQ?prefilled_email=${encodeURIComponent(profile?.email || '')}&prefilled_promo_code=TRIAL`
      : 'https://billing.stripe.com/p/login/fZe8xa6EYbIEbEQ6oo';
    window.open(url, '_blank');
  };

  const handleLogout = async () => {
    try {
      // Use forceLogout to ensure all caches are cleared and user is fully logged out
      await forceLogout();
    } catch (error) {
      console.error('Error during logout:', error);
      // Force reload as fallback
      window.location.reload();
    }
  };

  return (
    <Dialog 
      open={isOpen}
      onOpenChange={() => {}}
      modal={true}
    >
      <DialogContent 
        className="sm:max-w-md bg-zinc-900 border-zinc-800 text-white rounded-2xl"
        onPointerDownOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
        hideCloseButton={true}
      >
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            {type === 'signup' ? 'Try Audium for 7 Days' : 'Uh-Oh! A Little Bump'}
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-4 py-4">
          {type === 'signup' ? (
            <>
              <p className="text-zinc-300">
                Get instant access to our platform and content:
              </p>
              
              <div className="bg-zinc-800/50 rounded-xl p-4 space-y-2">
                <ul className="space-y-2 text-zinc-300">
                  <li className="flex items-center gap-2">
                    ✓ Listen to all core programs and modules.
                  </li>
                  <li className="flex items-center gap-2">
                    ✓ Get personalized help with AI Tutor.
                  </li>
                  <li className="flex items-center gap-2">
                    ✓ Take quizzes and earn certificates.
                  </li>
                  <li className="flex items-center gap-2">
                    ✓ Track your learning progress.
                  </li>
                </ul>
              </div>

              <Button
                onClick={handleAction}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white py-6 text-lg rounded-xl"
              >
                <ExternalLink className="w-5 h-5 mr-2" />
                Try for 7 Days for just $0.99
              </Button>

              <p className="text-sm text-zinc-400 text-center">
                Cancel Anytime | Renews at $2.49/wk
              </p>
            </>
          ) : (
            <>
              <div className="bg-red-950/30 border border-red-900/50 rounded-xl p-4">
                <h3 className="font-medium text-red-400 mb-2">Your access is at risk!</h3>
                <p className="text-red-300 mb-4">
                  We couldn't process your latest payment. Update your billing information to avoid losing access to:
                </p>
                <ul className="space-y-2 text-red-300">
                  <li className="flex items-center gap-2">
                    • Your entire course library and progress.
                  </li>
                  <li className="flex items-center gap-2">
                    • Your current rank and all its benefits.
                  </li>
                  <li className="flex items-center gap-2">
                    • AI Tutor access and remaining credits.
                  </li>
                  <li className="flex items-center gap-2">
                    • All earned certificates and quiz results.
                  </li>
                </ul>
              </div>

              <Button
                onClick={handleAction}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white py-6 text-lg rounded-xl"
              >
                <ExternalLink className="w-5 h-5 mr-2" />
                Update Billing Settings
              </Button>

              <p className="text-sm text-zinc-400 text-center">
                Your subscription will be reactivated immediately after updating - just refresh the page
              </p>
            </>
          )}

          <div className="pt-4 border-t border-zinc-800">
            <Button
              onClick={handleLogout}
              variant="ghost"
              className="w-full text-zinc-400 hover:text-white hover:bg-zinc-800"
            >
              <LogOut className="w-4 h-4 mr-2" />
              Log Out
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}