import { useState } from 'react';
import { LoginForm } from './LoginForm';
import { SignupForm } from './SignupForm';

export function AuthPage() {
  const [isLogin, setIsLogin] = useState(true);

  const toggleForm = () => setIsLogin(!isLogin);

  return isLogin ? (
    <LoginForm onToggleForm={toggleForm} />
  ) : (
    <SignupForm onToggleForm={toggleForm} />
  );
}