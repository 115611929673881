import { useState, useEffect } from 'react';
import { Plus, Save } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/hooks/use-toast';
import { format } from 'date-fns';
import { SubjectItem } from './SubjectItem';
import type { Subject } from '@/data/types';

interface EditingSubjectData {
  name: string;
  bgImage: string;
  color: string;
  isLocked: boolean;
  releaseDate: string;
  icon: string;
  category: string;
}

interface SubjectManagerProps {
  onSubjectSelect: (subject: string) => void;
}

export function SubjectManager({ onSubjectSelect }: SubjectManagerProps) {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [editingSubject, setEditingSubject] = useState<string | null>(null);
  const [editingData, setEditingData] = useState<EditingSubjectData | null>(null);
  const [newSubject, setNewSubject] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    loadSubjects();
  }, []);

  const loadSubjects = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('subjects')
        .select('*')
        .order('name');

      if (error) throw error;
      setSubjects(data || []);
    } catch (error) {
      console.error('Error loading subjects:', error);
      toast({
        title: 'Error',
        description: 'Failed to load subjects',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSubject = async () => {
    if (!newSubject.trim()) return;

    try {
      setIsLoading(true);

      const { error } = await supabase
        .from('subjects')
        .insert([
          {
            name: newSubject,
            color: '#3B82F6',
            icon: 'Book',
            bg_image: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&q=80',
            is_locked: false,
            category: 'Academic',
          },
        ]);

      if (error) throw error;

      toast({
        title: 'Subject added',
        description: `${newSubject} has been added successfully.`,
      });

      setNewSubject('');
      loadSubjects();
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to add subject',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateSubject = async (subject: Subject) => {
    if (!editingData) return;

    try {
      setIsLoading(true);

      const updateData: any = {
        name: editingData.name,
        bg_image: editingData.bgImage,
        color: editingData.color,
        is_locked: editingData.isLocked,
        icon: editingData.icon,
        category: editingData.category,
      };

      if (editingData.isLocked && editingData.releaseDate) {
        updateData.release_date = new Date(editingData.releaseDate).toISOString();
      } else {
        updateData.release_date = null;
      }

      const { error } = await supabase
        .from('subjects')
        .update(updateData)
        .eq('id', subject.id);

      if (error) throw error;

      toast({
        title: 'Subject updated',
        description: `${subject.name} has been updated successfully.`,
      });

      setEditingSubject(null);
      setEditingData(null);
      loadSubjects();
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to update subject',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSubject = async (subject: Subject) => {
    try {
      setIsLoading(true);

      const { error } = await supabase
        .from('subjects')
        .delete()
        .eq('id', subject.id);

      if (error) throw error;

      toast({
        title: 'Subject deleted',
        description: `${subject.name} has been deleted.`,
      });

      loadSubjects();
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to delete subject',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const startEditing = (subject: Subject) => {
    setEditingSubject(subject.name);
    setEditingData({
      name: subject.name,
      bgImage: subject.bg_image,
      color: subject.color,
      isLocked: subject.is_locked || false,
      releaseDate: subject.release_date ? format(new Date(subject.release_date), "yyyy-MM-dd'T'HH:mm") : '',
      icon: subject.icon,
      category: subject.category || 'Academic',
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex gap-4">
        <Input
          value={newSubject}
          onChange={(e) => setNewSubject(e.target.value)}
          placeholder="New subject name..."
          className="bg-zinc-800 border-zinc-700 text-white placeholder:text-zinc-500 rounded-xl"
        />
        <Button
          onClick={handleAddSubject}
          disabled={isLoading || !newSubject.trim()}
          className="bg-white hover:bg-zinc-100 text-zinc-900 rounded-xl px-6"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Subject
        </Button>
      </div>

      <div className="space-y-4">
        {subjects.map((subject) => (
          <div
            key={subject.id}
            className="flex flex-col p-6 bg-zinc-800/50 rounded-xl border border-zinc-700/50 hover:border-zinc-600/50 transition-colors cursor-pointer group"
          >
            <SubjectItem
              subject={subject}
              editingSubject={editingSubject}
              editingData={editingData}
              isLoading={isLoading}
              onSelect={onSubjectSelect}
              onStartEditing={startEditing}
              onDelete={handleDeleteSubject}
              onUpdate={handleUpdateSubject}
              onCancelEdit={() => {
                setEditingSubject(null);
                setEditingData(null);
              }}
              onEditingDataChange={setEditingData}
            />
          </div>
        ))}
      </div>
    </div>
  );
}