import { useState } from 'react';
import { ChevronsUpDown, Search } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { icons } from './icons';
import { cn } from '@/lib/utils';

interface IconPickerProps {
  value: string;
  onChange: (value: string) => void;
}

export function IconPicker({ value, onChange }: IconPickerProps) {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const IconComponent = icons.find(i => i.name === value)?.icon || icons[0].icon;

  const filteredIcons = icons.filter(icon => 
    icon.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Button
        variant="outline"
        role="combobox"
        aria-expanded={open}
        aria-label="Select icon"
        className="w-full justify-between bg-zinc-700 border-zinc-600 text-white hover:bg-zinc-600"
        onClick={() => setOpen(true)}
      >
        <div className="flex items-center gap-2">
          <IconComponent className="h-4 w-4" />
          <span>{value}</span>
        </div>
        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
      <DialogContent 
        className="p-0 bg-zinc-900 border-zinc-800 max-w-[600px]"
        aria-describedby="icon-picker-description"
      >
        <div id="icon-picker-description" className="sr-only">
          Select an icon from the available options
        </div>
        <DialogHeader className="px-4 pt-4">
          <DialogTitle className="text-lg font-semibold text-white">Select an Icon</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col h-[400px]">
          <div className="flex items-center border-b border-zinc-800 px-3">
            <Search className="mr-2 h-4 w-4 shrink-0 text-zinc-400" />
            <input
              type="text"
              placeholder="Search icons..."
              className="flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-zinc-500 text-white disabled:cursor-not-allowed disabled:opacity-50"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="overflow-y-auto flex-1 p-2">
            {filteredIcons.length === 0 ? (
              <p className="py-6 text-center text-sm text-zinc-400">
                No icons found.
              </p>
            ) : (
              <div className="grid grid-cols-6 gap-2">
                {filteredIcons.map((icon) => (
                  <button
                    key={icon.name}
                    onClick={() => {
                      onChange(icon.name);
                      setOpen(false);
                      setSearchQuery('');
                    }}
                    className={cn(
                      "flex flex-col items-center gap-2 p-4 rounded-lg cursor-pointer hover:bg-zinc-800 transition-colors",
                      value === icon.name && "bg-zinc-800"
                    )}
                  >
                    <icon.icon className="h-8 w-8 text-zinc-300" />
                    <span className="text-xs text-zinc-400 text-center truncate w-full">
                      {icon.name}
                    </span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}