import { Shield } from 'lucide-react';
import type { Rank } from '@/hooks/useRank';

interface RankBadgeProps {
  rank: Rank;
  onClick?: () => void;
}

const rankColors = {
  Listener: 'from-blue-400/80 via-blue-500/70 to-blue-600/80',
  Learner: 'from-green-400/80 via-green-500/70 to-green-600/80',
  Explorer: 'from-purple-400/80 via-purple-500/70 to-purple-600/80',
  Master: 'from-amber-400/80 via-amber-500/70 to-amber-600/80',
  Scholarship: 'from-pink-400/80 via-pink-500/70 to-pink-600/80',
};

const rankGlow = {
  Listener: 'shadow-[0_4px_20px_-1px_rgba(59,130,246,0.9)]',
  Learner: 'shadow-[0_4px_20px_-1px_rgba(34,197,94,0.9)]',
  Explorer: 'shadow-[0_4px_20px_-1px_rgba(168,85,247,0.9)]',
  Master: 'shadow-[0_4px_20px_-1px_rgba(245,158,11,0.9)]',
  Scholarship: 'shadow-[0_4px_20px_-1px_rgba(236,72,153,0.9)]',
};

export function RankBadge({ rank, onClick }: RankBadgeProps) {
  return (
    <button
      onClick={onClick}
      className={`
        relative px-3 py-1 text-xs font-semibold rounded-full text-white
        overflow-hidden flex items-center gap-1.5
        transition-all duration-300
        hover:scale-105
        ${rankGlow[rank]}
        before:absolute before:inset-0 
        before:bg-[length:200%_200%]
        before:bg-gradient-to-r ${rankColors[rank]}
        before:rounded-full 
        before:animate-[wave_3s_ease-in-out_infinite]
        before:z-[-1]
        after:absolute after:inset-0 
        after:bg-[length:200%_200%]
        after:bg-gradient-to-r ${rankColors[rank]}
        after:rounded-full 
        after:z-[-2]
        after:animate-[wave_3s_ease-in-out_infinite]
        after:[animation-delay:-1.5s]
      `}
      style={{
        '--wave-color': rankColors[rank]
      } as React.CSSProperties}
    >
      <Shield className="w-3 h-3" />
      {rank}
    </button>
  );
}