import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function hexToRGBA(hex: string, alpha: number) {
  if (!hex?.startsWith('#')) return '';
  
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function createGradientOverlay(color: string) {
  if (!color?.startsWith('#')) {
    return 'bg-gradient-to-br from-blue-500/80 via-blue-600/60 to-transparent';
  }

  const start = hexToRGBA(color, 0.85);
  const middle = hexToRGBA(color, 0.65);
  const end = hexToRGBA(color, 0.1);

  return `before:absolute before:inset-0 before:z-10 before:bg-gradient-to-br` + 
         ` before:from-[${start}] before:via-[${middle}] before:to-[${end}]`;
}