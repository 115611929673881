import { AuthProvider } from '@/contexts/AuthContext';
import { AppContent } from '@/components/AppContent';
import { Toaster } from '@/components/ui/toaster';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ResetPasswordPage } from '@/components/auth/ResetPasswordPage';
import { FacebookPixel } from '@/components/FacebookPixel';

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <FacebookPixel />
        <Routes>
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/*" element={<AppContent />} />
        </Routes>
        <Toaster />
      </AuthProvider>
    </Router>
  );
}