import { useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { SubjectManager } from './SubjectManager';
import { CourseManager } from './CourseManager';
import { ModuleManager } from './ModuleManager';
import { QuizManager } from './QuizManager';
import { NewsManager } from './NewsManager';
import { WhitelistManager } from './WhitelistManager';

interface AdminPanelProps {
  onBack: () => void;
}

export function AdminPanel({ onBack }: AdminPanelProps) {
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'content' | 'quiz' | 'news' | 'whitelist'>('content');

  const handleBack = () => {
    if (selectedCourse) {
      setSelectedCourse(null);
    } else if (selectedSubject) {
      setSelectedSubject(null);
    } else {
      onBack();
    }
  };

  return (
    <div className="max-w-7xl mx-auto py-8 px-4">
      <button
        onClick={handleBack}
        className="flex items-center gap-2 text-zinc-200 hover:text-white transition-colors mb-8 text-base"
      >
        <ArrowLeft className="w-5 h-5" />
        {selectedCourse ? 'Back to Courses' : selectedSubject ? 'Back to Subjects' : 'Back to Dashboard'}
      </button>

      <div className="bg-zinc-900 rounded-xl p-8 border border-zinc-800">
        <h1 className="text-3xl font-bold text-white mb-8">
          {selectedCourse ? selectedCourse : 
           selectedSubject ? `${selectedSubject} Courses` : 
           'Admin Panel'}
        </h1>

        {selectedCourse ? (
          <div className="space-y-8">
            <div className="flex gap-4 border-b border-zinc-800">
              <button
                onClick={() => setActiveTab('content')}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  activeTab === 'content'
                    ? 'text-white border-b-2 border-blue-500'
                    : 'text-zinc-400 hover:text-white'
                }`}
              >
                Content
              </button>
              <button
                onClick={() => setActiveTab('quiz')}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  activeTab === 'quiz'
                    ? 'text-white border-b-2 border-blue-500'
                    : 'text-zinc-400 hover:text-white'
                }`}
              >
                Quiz
              </button>
            </div>

            {activeTab === 'content' ? (
              <ModuleManager 
                selectedSubject={selectedSubject}
                selectedCourse={selectedCourse}
              />
            ) : (
              <QuizManager
                selectedSubject={selectedSubject}
                selectedCourse={selectedCourse}
              />
            )}
          </div>
        ) : selectedSubject ? (
          <CourseManager 
            selectedSubject={selectedSubject}
            onCourseSelect={setSelectedCourse}
          />
        ) : (
          <div className="space-y-8">
            <div className="flex gap-4 border-b border-zinc-800 mb-8">
              <button
                onClick={() => setActiveTab('content')}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  activeTab === 'content'
                    ? 'text-white border-b-2 border-blue-500'
                    : 'text-zinc-400 hover:text-white'
                }`}
              >
                Programs
              </button>
              <button
                onClick={() => setActiveTab('news')}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  activeTab === 'news'
                    ? 'text-white border-b-2 border-blue-500'
                    : 'text-zinc-400 hover:text-white'
                }`}
              >
                News Banner
              </button>
              <button
                onClick={() => setActiveTab('whitelist')}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  activeTab === 'whitelist'
                    ? 'text-white border-b-2 border-blue-500'
                    : 'text-zinc-400 hover:text-white'
                }`}
              >
                Whitelist
              </button>
            </div>

            {activeTab === 'content' ? (
              <SubjectManager 
                onSubjectSelect={setSelectedSubject}
              />
            ) : activeTab === 'news' ? (
              <NewsManager />
            ) : (
              <WhitelistManager />
            )}
          </div>
        )}
      </div>
    </div>
  );
}