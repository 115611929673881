import { ChevronRight } from 'lucide-react';

interface BreadcrumbItem {
  label: string;
  onClick: () => void;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

export function Breadcrumb({ items }: BreadcrumbProps) {
  return (
    <nav className="flex items-center space-x-2 text-sm text-zinc-400 mb-8 mt-16 lg:mt-0">
      {items.map((item, index) => (
        <div key={item.label} className="flex items-center">
          <button
            onClick={item.onClick}
            className="hover:text-white transition-colors"
          >
            {item.label}
          </button>
          {index < items.length - 1 && (
            <ChevronRight className="w-4 h-4 mx-2" />
          )}
        </div>
      ))}
    </nav>
  );
}